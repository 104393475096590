.bot-nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.bot-nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.bot-nav-link:hover, .bot-nav-link:focus {
    text-decoration: none;
}

.bot-nav-tabs {
    /*position: sticky;*/
    /*top: 6rem;*/
    /*z-index: 2;*/
    /*background-color: #e4e5e6;*/
    /*border-bottom: 1px solid var(--secondary);*/
}

.bot-nav-tabs .bot-nav-item {
    margin-bottom: -1px;
}

.bot-nav-tabs .bot-nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.bot-nav-tabs .bot-nav-link:hover, .bot-nav-tabs .bot-nav-link:focus {
    border-color: var(--gray-200) var(--gray-200) var(--secondary);
}

.bot-nav-tabs .bot-nav-link.disabled {
    color: var(--gray-600);
    background-color: transparent;
    border-color: transparent;
}

.bot-nav-tabs .bot-nav-link.active,
.bot-nav-tabs .bot-nav-item.show .bot-nav-link {
    color: var(--gray-700);
    background-color: #e4e5e6;
    border-color: var(--secondary) var(--secondary) #e4e5e6;
}

.bot-nav-tabs .bot-dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bot-nav-pills .bot-nav-link {
    border-radius: 0.25rem;
}

.bot-nav-pills .bot-nav-link.active,
.bot-nav-pills .show > .bot-nav-link {
    color: var(--white);
    background-color: var(--primary);
}

.bot-nav-fill .bot-nav-item {
    flex: 1 1 auto;
    text-align: center;
}

.bot-nav-justified .bot-nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.bot-navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

}

.bot-navbar > .bot-container,
.bot-navbar > .bot-container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.bot-navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

.bot-navbar-brand:hover, .bot-navbar-brand:focus {
    text-decoration: none;
}

.bot-navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.bot-navbar-nav .bot-nav-link {
    padding-right: 0;
    padding-left: 0;
}

.bot-navbar-nav .bot-dropdown-menu {
    position: static;
    float: none;
}

.bot-navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.bot-navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.bot-navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.bot-navbar-toggler:hover, .bot-navbar-toggler:focus {
    text-decoration: none;
}

.bot-navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.bot-navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

/*noinspection CssFloatPxLength*/
@media (max-width: 575.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .bot-navbar-expand-sm > .bot-container,
    .bot-navbar-expand-sm > .bot-container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-sm .bot-navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-sm .bot-navbar-nav .bot-dropdown-menu {
        position: absolute;
    }

    .bot-navbar-expand-sm .bot-navbar-nav .bot-nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .bot-navbar-expand-sm > .bot-container,
    .bot-navbar-expand-sm > .bot-container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-sm .bot-navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-sm .bot-navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 767.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .bot-navbar-expand-md > .bot-container,
    .bot-navbar-expand-md > .bot-container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-md .bot-navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-md .bot-navbar-nav .bot-dropdown-menu {
        position: absolute;
    }

    .bot-navbar-expand-md .bot-navbar-nav .bot-nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .bot-navbar-expand-md > .bot-container,
    .bot-navbar-expand-md > .bot-container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-md .bot-navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-md .bot-navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 991.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .bot-navbar-expand-lg > .bot-container,
    .bot-navbar-expand-lg > .bot-container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg .bot-navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg .bot-navbar-nav .bot-dropdown-menu {
        position: absolute;
    }

    .bot-navbar-expand-lg .bot-navbar-nav .bot-nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg > .bot-container,
    .bot-navbar-expand-lg > .bot-container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg .bot-navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-lg .bot-navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 1199.98px) {
    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl > .bot-container,
    .bot-navbar-expand-xl > .bot-container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl .bot-navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl .bot-navbar-nav .bot-dropdown-menu {
        position: absolute;
    }

    .bot-navbar-expand-xl .bot-navbar-nav .bot-nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl > .bot-container,
    .bot-navbar-expand-xl > .bot-container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl .bot-navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .bot-navbar-expand-xl .bot-navbar-toggler {
        display: none;
    }
}

.bot-navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.bot-navbar-expand > .bot-container,
.bot-navbar-expand > .bot-container-fluid {
    padding-right: 0;
    padding-left: 0;
}

.bot-navbar-expand .bot-navbar-nav {
    flex-direction: row;
}

.bot-navbar-expand .bot-navbar-nav .bot-dropdown-menu {
    position: absolute;
}

.bot-navbar-expand .bot-navbar-nav .bot-nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.bot-navbar-expand > .bot-container,
.bot-navbar-expand > .bot-container-fluid {
    flex-wrap: nowrap;
}

.bot-navbar-expand .bot-navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

.bot-navbar-expand .bot-navbar-toggler {
    display: none;
}

.bot-navbar-light .bot-navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

.bot-navbar-light .bot-navbar-brand:hover, .bot-navbar-light .bot-navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.bot-navbar-light .bot-navbar-nav .bot-nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.bot-navbar-light .bot-navbar-nav .bot-nav-link:hover, .bot-navbar-light .bot-navbar-nav .bot-nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.bot-navbar-light .bot-navbar-nav .bot-nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.bot-navbar-light .bot-navbar-nav .show > .bot-nav-link,
.bot-navbar-light .bot-navbar-nav .active > .bot-nav-link,
.bot-navbar-light .bot-navbar-nav .bot-nav-link.show,
.bot-navbar-light .bot-navbar-nav .bot-nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.bot-navbar-light .bot-navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.bot-navbar-light .bot-navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.bot-navbar-light .bot-navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.bot-navbar-light .bot-navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.bot-navbar-light .bot-navbar-text a:hover, .bot-navbar-light .bot-navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.bot-navbar-dark .bot-navbar-brand {
    color: var(--white);
}

.bot-navbar-dark .bot-navbar-brand:hover, .bot-navbar-dark .bot-navbar-brand:focus {
    color: var(--white);
}

.bot-navbar-dark .bot-navbar-nav .bot-nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.bot-navbar-dark .bot-navbar-nav .bot-nav-link:hover, .bot-navbar-dark .bot-navbar-nav .bot-nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.bot-navbar-dark .bot-navbar-nav .bot-nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.bot-navbar-dark .bot-navbar-nav .show > .bot-nav-link,
.bot-navbar-dark .bot-navbar-nav .active > .bot-nav-link,
.bot-navbar-dark .bot-navbar-nav .bot-nav-link.show,
.bot-navbar-dark .bot-navbar-nav .bot-nav-link.active {
    color: var(--white);
}

.bot-navbar-dark .bot-navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.bot-navbar-dark .bot-navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.bot-navbar-dark .bot-navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.bot-navbar-dark .bot-navbar-text a {
    color: var(--white);
}

.bot-navbar-dark .bot-navbar-text a:hover, .bot-navbar-dark .bot-navbar-text a:focus {
    color: var(--white);
}

.bot-pagination-group {
    width: 100% !important;
    padding-left: 20px !important;
    padding-bottom: 1rem;
}

.bot-pagination-group.bottom {
    padding-bottom: 10px;
    border: 0;
}

.bot-pagination-group > .row {
    margin-left: -11px;
}

.bot-pagination {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
    margin-bottom: 0 !important;
    /*margin-top: 1rem;*/
}

.bot-page-item {
    display: block;
    flex-direction: row;
    width: max-content;
}

.btn-outline-texts.bot-pagination-dropdown-item {
    font-size: 11pt;
    line-height: 1;
    border-radius: 0;
    border: 0 !important;
    border-bottom: 1px solid var(--texts) !important;
}

.bot-pagination-dropdown-item.active {
    background-color: var(--texts) !important;
}

/*.bot-size-per-page .dropdown-menu .dropdown-item.active {*/
/*    background-color: var(--texts) !important;*/
/*}*/

/*.bot-size-per-page .dropdown-item.visited {*/
/*    background-color: var(--texts) !important;*/
/*}*/

/*.bot-size-per-page .dropdown-item.focus {*/
/*    background-color: var(--texts) !important;*/
/*}*/

.bot-page-item.active .bot-page-link {
    background-color: var(--texts) !important;
    border: 1px solid var(--texts) !important;
    color: white !important;
}

.bot-pag-records-label {
    font-size: 12pt;
    font-weight: bold;
    padding-top: 3px;
    margin-bottom: 0;
    /*padding-bottom: 3px;*/
}

.bot-page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--texts);
    background-color: var(--white);
    border: 1px solid var(--texts);
}

.bot-size-per-page {
    height: max-content;
    /*margin-top: 1rem;*/
    padding-right: .5rem;
}

.bot-divider {
    display: inline;
    height: 20px;
    width: 1px;
    border: 1px solid var(--texts);
    margin-right: .2rem;
    margin-left: .1rem;
    padding-top: .2rem;
    padding-bottom: .3rem;
}

.bot-table-nav-row.bottom {
    padding-top: .5rem;
}

@import "bot-nav.css";
@import 'bot-tabs.css';
@import 'bot-pagination.css';
@import 'bot-button.css';

@import "~jquery-ui-pack/jquery-ui.css";
@import "~jquery-ui-pack/jquery-ui.structure.css";
@import "~jquery-ui-pack/jquery-ui.theme.css";
/*@import '~pqgrid/pqgrid.min.css';*/
/*@import '~pqgrid/pqgrid.ui.min.css';*/
/*@import '~pqgrid/themes/steelblue/pqgrid.css';*/
@keyframes color {
    0% {
        border: 1px solid var(--white);
    }
    50% {
        border: 1px solid var(--light-red);
    }
    100% {
        border: 1px solid var(--red);
    }
}

.contact-search-input {
    width: 90%;
    text-align: center;
    text-decoration: underline;
    margin-top: .5rem;
    margin-bottom: 1rem;
    margin-left: 0.3rem;
    border-bottom: 2px solid var(--texts);
}
.contact-card {
    display: flex !important;
    margin-bottom: 0.25rem !important;
}
.contact-card-avatar {
    color: white !important;
    text-align: center !important;
    justify-content: center !important;
    justify-items: center !important;
    min-height: unset !important;
    padding: 0 !important;
    width: 36px;
    height: 36px;
    line-height: 36px;
    /*padding: 0.25rem .75rem !important;*/
    border-radius: 18px !important;
}
.contact-card-text {
    line-height: 36px;
}

.available-list>.header>button {
    justify-content: center !important;
    background-image: unset;
    color: var(--texts);
    background-color: white;
    border: 1px solid var(--texts);
}

.available-list>.header>button:hover {
    background-image: unset;
    background-color: var(--texts);
    color: white;
    border: 1px solid var(--texts);
}

.selected-list>.header>button {
    background-image: unset;
    color: var(--texts);
    background-color: white;
    border: 1px solid var(--texts);
}

.selected-list>.header>button:hover {
    background-image: unset;
    background-color: var(--texts);
    color: white;
    border: 1px solid var(--texts);
}

.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    border-color: #007bff;
    background-color: #007bff;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.custom-switch {
    padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
    left: -2.25rem;
    width: 1.75rem;
    pointer-events: all;
    border-radius: 0.5rem;
}

/*noinspection CssOverwrittenProperties*/
.custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
        transition: none;
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(0.75rem);
    transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(0, 123, 255, 0.5);
}

.table-select-btn {
    margin-left: -0.5rem;
    margin-top: 0.8rem;
    position: relative !important;
}

a:hover {
    color: var(--texts);
}

.bot-page-item.active a {
    cursor: unset;
    text-decoration: none;
}

.bot-table-header .form-control {
    border-color: black;
}

.bot-attachment-body {
    display: flex;
    flex-wrap: wrap;
    margin: .5rem;
}

.bot-attachment-comment-input {
    border: 1px solid !important;
}

.bot-attachment-comment-save-btn {
    margin: .5rem 0 1rem 0;
}

.bot-attachment-comment-list {
    max-height: 32rem;
    overflow: scroll;
}

.bot-attachment-comment-card-body {
    text-align: end;
}

.bot-attachment-comment-card-footer {
    text-align: end;
}

.bot-dropzone {
    display: flex;
    flex-grow: 1;
    flex-direction: row;
    align-items: flex-start;
    width: max-content;
    /*height: 86px;*/
    border: 3px solid var(--primary);
}

.react-select {
    padding: 0 !important;
}

.input-group {
    padding-top: 0.5rem;
}

.bot-draggable {
    cursor: grab;
    transition: transform 500ms;
    z-index: 1;
    position: absolute;
    user-select: auto;
    /*background: var(--primary) !important;*/
}

.bot-draggable.dragging {
    cursor: grabbing;
    transition: none;
    z-index: 2;
    position: absolute;
    user-select: auto;
    background: var(--primary) !important;
}

.bot-resizable {

}

.bot-resizable-data {
    width: calc(100% - 3px);
    height: 100%;
    background: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}

.resizable .resizers {
    width: 100%;
    height: 100%;
    /*border: 3px solid #4286f4;*/
    box-sizing: border-box;
}

.resizable .resizers .resizer{
    /*width: 10px;*/
    /*height: 10px;*/
    /*border-radius: 50%;*/
    /*background: white;*/
    position: absolute;
}
.resizable .resizers .resizer.top-left {
    left: -5px;
    top: -5px;
    cursor: nwse-resize; /*resizer cursor*/
}
.resizable .resizers .resizer.top-right {
    right: -5px;
    top: -5px;
    cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-left {
    left: -5px;
    bottom: -5px;
    cursor: nesw-resize;
}
.resizable .resizers .resizer.bottom-right {
    right: -5px;
    bottom: 0;
    cursor: nwse-resize;
}
.resizable .resizers .resizer.right {
    right: -2px;
    /*border: 3px solid #4286f4;*/
    /*padding-left: -5px;*/
    width: 5px;
    /*background-color: var(--gray-500);*/
    /*bottom: 0;*/
    /*top: 0;*/
    cursor: ew-resize;
}

.resizable .resizers .resizer.right p {
    background-color: var(--gray-400);
    color: black;
    overflow-wrap: break-word;
    font-size: 9pt;
    height: 100%;
    /*padding-bottom: .4rem;*/
}

.dashboard-report-lst-run-lbl-container {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    align-self: stretch;
    padding-right: 1rem;
    padding-top: .3rem;
    /*height: 2rem;*/
}

.dashboard-report-lst-run-lbl1 {
    white-space: nowrap;
    vertical-align: middle;
    margin-right: -1px;
    display: flex;
}

.dashboard-report-lst-run-lbl1 > .text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--gray-700);
    text-align: center;
    white-space: nowrap;
    background-color: var(--gray-100);
    border: 1px solid var(--gray-200);
    border-radius: 0.25rem;
}

/*.dashboard-report-lst-run-lbl1 {*/
/*    white-space: nowrap;*/
/*    vertical-align: middle;*/
/*    margin-right: -1px;*/
/*    display: flex;*/
/*    align-items: center;*/
/*    padding: 0.3rem 0.75rem;*/
/*    font-size: 0.875rem;*/
/*    font-weight: 400;*/
/*    color: var(--gray-700);*/
/*    text-align: center;*/
/*    background-color: var(--gray-100);*/
/*    border: 1px solid var(--gray-200);*/
/*    border-radius: 0.25rem 0 0 0.25rem;*/
/*}*/

.dashboard-report-lst-run-lbl2 {
    white-space: nowrap;
    vertical-align: middle;
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    border-radius: 0 0.25rem 0.25rem 0;
    display: flex;
    align-items: center;
    padding: 0.3rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    color: var(--gray-700);
    background-clip: padding-box;
    border: 1px solid var(--gray-200);
    opacity: 1;
    min-width: 10rem;
}

.bot-all-csv-btn {
    background-color: var(--success) !important;
    color: var(--white) !important;
    font-weight: 400 !important;
    font-size: 0.875rem !important;
}

.bot-3d-user-import-button {
    border-radius: .35rem !important;
    box-shadow: 0 4px #999 !important;
    background-color: #4d79ff !important;
    color: white !important;
    height: 2.25rem;
    font-size: 1rem !important;
    font-weight: bold !important;
    overflow: hidden;
}

.bot-3d-user-import-button:active {
    box-shadow: 0 1px #666 !important;
    transform: translateY(3px) !important;
}

.bot-3d-user-cancel-button {
    border-radius: .35rem !important;
    box-shadow: 0 4px #999 !important;
    background-color: var(--gray-700) !important;
    color: white !important;
    height: 2.25rem;
    font-size: 1rem !important;
    font-weight: bold !important;
    overflow: hidden;
}

.bot-3d-user-cancel-button:active {
    box-shadow: 0 1px #666 !important;
    transform: translateY(3px) !important;
}

.bot-3d-user-complete-button {
    border-radius: .35rem !important;
    box-shadow: 0 4px #999 !important;
    background-color: #4d79ff !important;
    color: white !important;
    height: 2.25rem;
    font-size: 1rem !important;
    font-weight: bold !important;
    overflow: hidden;
}

.bot-3d-user-complete-button:active {
    box-shadow: 0 1px #666 !important;
    transform: translateY(3px) !important;
}

.bot-3d-user-history-button {
    float: right;
    border: 1px solid black !important;
    border-radius: .35rem !important;
    box-shadow: 0 4px #999 !important;
    background-color: var(--gray-200) !important;
    color: black !important;
    height: 2.25rem;
    font-size: 1rem !important;
    font-weight: bold !important;
    overflow: hidden;
}

.bot-3d-user-history-button:active {
    box-shadow: 0 1px #666 !important;
    transform: translateY(3px) !important;
}

.uil-dark-left-lbl {
    float: right;
    font-weight: bolder;
    font-size: 1.5rem;
    color: #002080;
}

.uil-dark-right-lbl {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bolder;
    font-size: 1.5rem;
    color: #002080;
}

.uil-light-little-left-lbl {
    float: right;
    font-size: 1rem;
    color: #002080;
}

.uil-dark-right-date-lbl {
    font-weight: bolder;
    font-size: 1.5rem;
    color: #002080;
}

.blocking-loading-lbl {
    font-weight: bolder;
    font-size: 2.5rem;
    color: var(--primary);
}

.uil-light-left-lbl {
    float: right;
    font-size: 1.5rem;
    color: #002080;
}

.uil-light-right-lbl {
    text-decoration: underline;
    cursor: pointer;
    font-size: 1.5rem;
    color: #002080;
}

bot-table-button-group .btn {
    /*background-color: var(--light) !important;*/
    /*color: var(--texts) !important;*/
    /*border: 1px solid var(--texts) !important;*/
    font-weight: 400 !important;
    font-size: 0.875rem !important;
}

.bot-new-pm-card {
    overflow: visible !important;
    min-height: 5rem;
}

.bot-new-pm-card-header {

}

.bot-new-pm-card-body {
    overflow: visible !important;
    min-height: 8rem;
}

.bot-logout {
    z-index: 1019 !important;
}

.sr-only {
    width: max-content !important;
    height: 100% !important;
}

.bot-table-title-pane {
    display: flex;
    flex-wrap: wrap;
    font-size: 24pt;
    color: var(--texts);
    background-color: var(--light);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    line-height: 80px;
    margin-bottom: .5rem;
}

bot-table-button-group {
    display: table;
    margin-top: .3rem;
    height: max-content;
    width: 100%;
}

bot-table-button-group.left {
    align-items: start;
    padding-left: 5px;
}

bot-table-button-group.left bot-table-button-group {
    padding-left: 0 !important;
}

bot-table-button-group.right {
    align-items: end;
    padding-right: 5px;
}

bot-table-button-group.right bot-table-button-group {
    padding-right: 0 !important;
}

bot-table-button-group button {
    /*height: 100%;*/
}

bot-table-button-group i {
    padding-top: .5rem;
    margin-right: .5rem;
    float: unset !important;
    cursor: pointer;
}

button i {
    padding-top: unset;
    margin-right: unset;
}

.bot-table-column-menu-control {
    width: max-content !important;
}
.bot-table-column-menu-controls {
    width: max-content !important;
    margin-top: .75rem !important;
    margin-left: 4px;
    height: max-content;
    line-height: 20px;
}

.btn-group > .btn-secondary.active {
    background-color: var(--primary) !important;
    border: 1px solid var(--primary) !important;
    color: white !important;
}

.btn-group > .focus {
    border: 2px solid var(--primary) !important;
    font-weight: bold !important;
}

.bot-table-column-menu-row {
    min-width: max-content !important;
}

.bot-table-column-menu-column {
    min-width: max-content !important;
}

.bot-table-column-menu-controls::-webkit-scrollbar {
    display: none;
}

.kpi-quad::-webkit-scrollbar {
    display: none;
}

.bot-table-column-menu-header {
    text-align: center;
    font-size: 12pt !important;
    font-weight: bold;
    line-height: 2.5rem;
    border-bottom: .1rem solid var(--texts);
    color: var(--texts);
    margin-bottom: 1rem;
}

.filter-list-icon {
    content: url("../fonts/material-icons/filter_list-24px.svg");
}

.sort-null {
    min-width: 1rem;
    max-width: 1rem;
    width: 10%;
    float: left;
    content: url("../fonts/material-icons/import_export-black-18dp.svg");
}

.sort-asc {
    min-width: 1rem;
    max-width: 1rem;
    width: 10%;
    float: left;
    content: url("../fonts/material-icons/arrow_right_alt-black-18dp.svg");
    transform: rotate(90deg);
}

.sort-desc {
    min-width: 1rem;
    max-width: 1rem;
    width: 10%;
    float: left;
    content: url("../fonts/material-icons/arrow_right_alt-black-18dp.svg");
    transform: rotate(270deg);
}

.bot-table-nav-row {
    display: flex;
    flex-direction: row;
}

.bot-table-nav-row::-webkit-scrollbar {
    display: none;
}

.btn-toolbar {
    /*flex-wrap: unset;*/
}

.show-less-icon {
    content: url("../fonts/material-icons/unfold_less-24px.svg");
    height: max-content;
    margin-top: .6rem;
}

.show-more-icon {
    content: url("../fonts/material-icons/unfold_more-24px.svg");
    height: max-content;
    margin-top: .6rem;
}

.clear-dot-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-def-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-pre-post-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-complete-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-priority-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-certified-icon {
    display: inline;
    margin-right: .5rem;
}

.clear-icon {
    content: url("../fonts/material-icons/clear-24px.svg");
    width: 1rem;
}

.bot-table-column-menu-label {
    text-align: left;
    padding-left: 4px;
    height: max-content !important;
    line-height: 20px;
    width: max-content;
    padding-right: 5px;
    font-size: 12pt;
}

.bot-table-title {
    width: 100%;
    text-align: center;
}

.bot-table-column-menu-button {
    float: right;
    margin-top: 5px;
    color: var(--texts) !important;
    border: 0 !important;
    background-color: transparent !important;
}

.bot-table {
    display: flex;
    flex-direction: column;
    background-color: white;
    border: 1px solid var(--gray);
    border-radius: 5px;
    /*height: max-content;*/
    /*overflow: scroll !important;*/
    /*min-width: 100%;*/
    max-width: calc(100vw - 45px);
    min-height: 500px !important;
    position: sticky;
    top: 7rem;
    /*max-height: calc(100vh - 1000px);*/
}

bot-tbody {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--texts);
    background-color: white;
}

.tbody-container {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    overflow: scroll;
    min-height: max-content;
}

.bot-table-headers {
    display: inline-block;
    position: sticky;
    flex-wrap: nowrap;
    margin-left: 4px;
    margin-right: 0;
    height: 5rem !important;
    padding-top: 3px;
    margin-bottom: 1px;
    top: 0;
    width: 100%;
}

.bot-table-headers::-webkit-scrollbar {
    display: none;
}

.bot-table-header-col {
    /*overflow-x: scroll;*/
    /*overflow: hidden;*/
}

.bot-table-header {
    float: left;
    border: 1px solid var(--texts);
    height: 4.5rem !important;
    padding-top: 6px;
    text-align: center;
}

.bot-table-header-row {
    display: table;
    clear: both;
    width: 100%;
    /*overflow: hidden;*/
    /*text-overflow: ellipsis;*/
    /*text-overflow-ellipsis: '...';*/
    /*white-space: nowrap;*/
}

.bot-table-header-space {
    width: 10%;
    float: left;
}

.bot-table-header-label {
    width: 100%;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-overflow-ellipsis: '...';
    white-space: nowrap;
    margin-bottom: 0;
}

.bot-table-header-clear {
    width: 10%;
    float: left;
}

.bot-table-header-clear-icon {
    min-width: 1rem;
    max-width: 1rem;
    width: 10%;
    float: left;
    content: url("../fonts/material-icons/filter-remove.svg");
    color: var(--texts);
}

.bot-table-select-filter {
    height: 25px;
    margin-bottom: 4px;
    width: 100%;
    border-width: 2px;
}

.bot-table-text-filter {
    margin-bottom: 4px;
    border-radius: 5px;
    width: 100%;
}

.bot-table-data {
    flex-wrap: nowrap;
    /*border-top: 1px solid var(--texts);*/
    padding-bottom: 15px;
    padding-top: 0;
    overflow-x: hidden;
    min-width: max-content;
    /*max-height: calc(100vh - 350px);*/
}

.bot-table-data::-webkit-scrollbar {
    display: none;
}

.bot-table-row:first-child {
    border-top: 1px solid var(--texts) !important;
}

.bot-table-expanded-row-field {
    border-right: 1px solid var(--texts);
    border-bottom: 1px solid var(--texts);
    height: 2.5rem;
    text-align: left !important;
    margin-bottom: 0 !important;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2.5rem;
    padding-left: 4px;
}

.bot-table-row-field {
    /*border: 1px solid var(--texts);*/
    display: inline-block;
    white-space: nowrap;
    border-right: 1px solid var(--texts);
    border-bottom: 1px solid var(--texts);
    min-height: 2.5rem;
    text-align: center;
    margin-bottom: 0 !important;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2.5rem;
    margin-left: 0 !important;
}

.bot-table-row-field:first-child {
    border-left: 1px solid var(--texts);
}

#bot-table-editable-div .bot-table-row:first-child {
    border: 0 !important;
}

.bot-table-row-field.minimized {
    content: url("../fonts/material-icons/add-24px.svg");
}

.bot-table-row-field.expanded {
    content: url("../fonts/material-icons/remove-24px.svg");
}

@media (max-width: 1400px) {
    .kpi-quad {
        /*height: 7rem !important;*/
    }
    /*.kpi-quad h3 {*/
    /*    font-size: 1.3rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
}

@media (max-width: 1200px) {
    /*.kpi-quad h3 {*/
    /*    font-size: 1rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .7rem !important;*/
    /*}*/

    .kpi-quad {
        /*height: 8rem !important;*/
    }

    .ticker-wrapper .ticker {
        /*padding-right: 100%;*/
        /*padding-left: 50%;*/
        /**/
        /*-webkit-animation-iteration-count: infinite;*/
        /*animation-iteration-count: infinite;*/
        /*-webkit-animation-timing-function: linear;*/
        /*animation-timing-function: linear;*/
        /*-webkit-animation-name: ticker;*/
        /*animation-name: ticker;*/
        /*-webkit-animation-duration: var(--duration);*/
        /*animation-duration: var(--duration);*/
    }

    /*.ticker-wrapper .ticker {*/
    /*    overflow: scroll;*/
    /*}*/

    /*.ticker-wrapper .ticker::-webkit-scrollbar {*/
    /*    display: none;*/
    /*}*/
}

@media (max-width: 1100px) {

    /*.kpi-quad {*/
    /*    height: 9rem !important;*/
    /*}*/

}

@media (max-width: 1000px) {
    /*.kpi-quad h3 {*/
    /*    font-size: 1rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
}

@media (max-width: 900px) {
    /*.kpi-quad h3 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
}

@media (max-width: 800px) {
    /*.kpi-quad h3 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .7rem !important;*/
    /*}*/
}

@media (max-width: 700px) {
    /*.kpi-quad h3 {*/
    /*    font-size: .9rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: .7rem !important;*/
    /*}*/
}

@media (max-width: 576px) {
    /*.kpi-quad h3 {*/
    /*    font-size: 1.53125rem !important;*/
    /*}*/
    /*.kpi-quad h5 {*/
    /*    font-size: 1.09375rem !important;*/
    /*}*/
}

.kpi-quad {
    border-radius: 1rem !important;
    height: 6rem;
    color: var(--texts);
    /*overflow: scroll !important;*/
    width: 100%;
}

.kpi-quad.good {
    background-color: #98D086;
    color: var(--texts);
}

.kpi-quad.warn {
    background-color: #DCCC77;
    color: var(--texts);
}

.kpi-quad.danger {
    background-color: #FE9E9C;
    color: var(--texts);
}

.kpi-quad.dot-1 {
    background-color: #0000BC;
    color: var(--white);
}

.kpi-quad.dot-2 {
    background-color: #5E72D4;
    color: var(--white);
}

.kpi-quad.dot-3 {
    background-color: #14284F;
    color: var(--white);
}

.kpi-quad.ins-1 {
    background-color: #374457;
    color: var(--white);
}

.kpi-quad.ins-2 {
    background-color: #B4C7E7;
    color: var(--texts);
}

.kpi-quad.ins-3 {
    background-color: #2F5597;
    color: var(--white);
}

.kpi-quad.ins-4 {
    background-color: #0070C0;
    color: var(--white);
}

.kpi-quad.ins-5 {
    background-color: #5E72D4;
    color: var(--white);
}

.kpi-quad.ins-6 {
    background-color: #567880;
    color: var(--white);
}

.kpi-main-card {
    overflow: visible !important;
}

.wo-dashboard-dropdown {
    overflow-y: visible;
    margin-bottom: 0;
    margin-left: -1px;
}

.wo-dashboard-dropdown .btn {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.dashboard-btn-group {
    overflow: visible !important;
    max-height: 2.5rem;
}

/*.btn-group > .btn:not(:first-child), .wo-dashboard-dropdown > .btn-group:not(:first-child) {*/
/*    margin-left: -1px;*/
/*}*/

/*.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) {*/
/*    margin-left: -1px;*/
/*}*/

/*.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {*/
/*    border-top-left-radius: 0 !important;*/
/*    border-bottom-left-radius: 0 !important;*/
/*}*/

/*.btn-group > .btn-group:not(:first-child), .wo-dashboard-dropdown > .btn-group:not(:first-child) > .btn {*/
/*    border-top-left-radius: 0 !important;*/
/*    border-bottom-left-radius: 0 !important;*/
/*}*/

/*.btn-group > .wo-dashboard-dropdown:not(:first-child), .btn-group:not(:first-child) {*/
/*    margin-left: -1px;*/
/*    border-top-left-radius: 0 !important;*/
/*    border-bottom-left-radius: 0 !important;*/
/*}*/

.wo-dashboard-clr {
    /*float: right;*/
}

.link-label {
    text-decoration: none;
    color: var(--secondary);
    cursor: pointer;
    bottom: 0;
}

.link-label-1 {
    text-decoration: underline;
    color: red;
    padding-left: 5px;
    font-size: 14pt;
    cursor: pointer;
    bottom: 0;
    opacity: 0.6;
}

.link-label-1:hover {
    color: red;
    opacity: 1;
}

.link-label-2 {
    text-decoration: underline;
    cursor: pointer;
    bottom: 0;
}

.more-button {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-right: 2rem;
    padding-bottom: 1rem;
}

.link-label:hover {
    text-decoration: underline;
    color: red;
}

.overlay-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.95);
    height: 100vh;
    width: 100vw;
    z-index: 999;
}

.overlay-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.overlay-inner .closebtn {
    position: absolute;
    top: 20px;
    color: white;
    background: none;
    border: 0;
    right: 45px;
    font-size: 60px;
}

.overlay-inner a {
    padding: 8px;
    text-decoration: none;
    font-size: 36px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

.overlay-close {
    position: absolute;
    top: 20px;
    right: 20px;
    color: white;
    background: none;
    border: 0;
}
:root {
    --duration: 30s
}
@-webkit-keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

@keyframes ticker {
    0% {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
        visibility: visible;
    }

    100% {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
    }
}

.ticker-wrapper {

    position: relative;
    bottom: 0;
    /*width: 100%;*/
    overflow: hidden;
    height: 3rem;
    /*background-color: RGBA(0,130,255,1);*/
    background-color: #14284F;
    color: white;
    /*padding-left: 100%;*/
    box-sizing: content-box;
    border-radius: .5rem;
}

.wo-dashboard-selection.selected {
    background-color: #374457;
    color: white;
}

.ins-dashboard-selection.selected {
    background-color: #14284F;
    color: white;
}

.ticker-wrapper.wo {

    position: relative;
    bottom: 0;
    /*width: 100%;*/
    overflow: hidden;
    height: 3rem;
    /*background-color: RGBA(0,130,255,1);*/
    background-color: #374457;
    color: white;
    /*padding-left: 100%;*/
    box-sizing: content-box;
    border-radius: .5rem;
}

.ticker-wrapper.dot {
    background-color: #14284F;
}
.ticker-wrapper .ticker {

    /*display: inline-flex;*/
    flex: 1 1 auto;
    height: 3rem;
    line-height: 3rem;
    white-space: nowrap;
    /*padding-right: 100%;*/
    box-sizing: content-box;
    align-items: center;
    text-align: center;

    /*-webkit-animation-iteration-count: infinite;*/
    /*animation-iteration-count: infinite;*/
    /*-webkit-animation-timing-function: linear;*/
    /*animation-timing-function: linear;*/
    /*-webkit-animation-name: ticker;*/
    /*animation-name: ticker;*/
    /*-webkit-animation-duration: var(--duration);*/
    /*animation-duration: var(--duration);*/
}

.ticker .ticker-item {

     display: inline-block;
     padding: 0 2rem;
     font-size: 1rem;
     color: white;

 }


.bot-table-editable-div {
    display: inline-block;
    /*border-right: 1px solid var(--texts);*/
    /*border-bottom: 1px solid var(--texts);*/
    /*border-left: 1px solid var(--texts);*/
    min-height: 2.5rem;
    text-align: center;
    margin-bottom: 0 !important;
    overflow: hidden;
    vertical-align: middle;
    line-height: 2.5rem;
    height: 100%;
}

#editable-general_status[hidden] div {
    display: none !important;
}

#editable-general_status {
    border-right: 1px solid var(--texts);
    border-bottom: 1px solid var(--texts);
}

#editable-job {
    border-right: 1px solid var(--texts);
    border-bottom: 1px solid var(--texts);
    display: inline-block;
}

#editable-yard {
    border-right: 1px solid var(--texts);
    border-bottom: 1px solid var(--texts);
    display: inline-block;
}

.bot-table-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
    clear: both;
    margin-left: 4px;
    margin-bottom: 0;
    width: max-content;
    height: max-content;
}

.bot-table-row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.15);
}

.bot-table-expanded-row {
    display: table;
    min-width: 100%;
}

#bot-table-expanded-row {
    border: 0;
    padding: 0 !important;
}

#bot-table-expanded-row .bot-table-row {
    background-color: white;
    border-bottom: 1px solid var(--texts);
}

#bot-table-expanded-row .bot-table-row-field {
    border-bottom: 0;
}

#bot-table-expanded-row:nth-of-type(odd) .bot-table-row {
    background-color: rgba(0, 0, 0, 0.15);
}

#bot-table-expanded-row .bot-table {
    padding-left: 4px;
}

#bot-table-expanded-row .bot-table-data {
    overflow-x: hidden;
}

#bot-expanded-table {
    border: 0;
    border-bottom: 1px solid var(--texts);
    border-bottom-left-radius: 8px;
}

#bot-expanded-table .bot-table-data {
    background-color: var(--white) !important;
}

#bot-expanded-table .bot-table-expanded-row {
    background-color: transparent;
    margin-left: 4px;
}

#bot-expanded-table .bot-table-row {
    background-color: transparent !important;
}

#bot-expanded-table .bot-table-row {
    background-color: white !important;
    width: 100%;
}

#bot-expanded-table .bot-table-row:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.bot-table-reset-btn {
    border: 1px solid var(--texts) !important;
    color: var(--texts) !important;
    width: 100%;
}

.bot-table-reset-btn:active {
    background-color: var(--light-gray);
    color: white !important;
}

.bot-table-column-show-all-btn {
    border: 1px solid var(--texts) !important;
    color: var(--texts) !important;
    width: 100%;
}

.bot-table-column-show-all-btn:active {
    background-color: var(--light-gray);
    color: white !important;
}

.bot-card {
    display: block;
    word-wrap: break-word;
    background-color: var(--white);
    border: 1px solid var(--gray);
    border-radius: 0.25rem;
    margin-bottom: 1rem;
    /*overflow: hidden;*/
}

.card-group > .bot-card {
    flex: 1 0 0;
    margin-bottom: 0;
}

.bot-card-header {
    color: var(--texts);
    background-color: var(--light);
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    min-height: 3rem;
    height: 100%;
    display: inline-table;
    width: 99.89%;
}

.bot-card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px);
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
}

.bot-card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    /*overflow: hidden;*/
}

.bot-card-footer {
    padding: .75rem 1.25rem;
    background-color: var(--light);
    margin-bottom: 1px;
}

.bot-card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px);
}

button:disabled {
    cursor: unset;
}

.btn-texts {
    color: var(--white);
    background: var(--texts);
    border-color: var(--texts);
    overflow: hidden;
}

.btn-texts:hover {
    color: var(--white);
    background-color: var(--texts);
    border-color: var(--texts);
}

.btn-texts:focus, .btn-texts.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 69, 63, 0.5);
}

.btn-texts.disabled, .btn-texts:disabled {
    color: var(--white);
    background-color: var(--texts);
    border-color: var(--texts);
}

/*noinspection CssUnusedSymbol*/
.btn-texts:not(:disabled):not(.disabled):active, .btn-texts:not(:disabled):not(.disabled).active,
.show > .btn-texts.dropdown-toggle {
    color: var(--white);
    background-color: var(--texts);
    border-color: var(--texts);
}

/*noinspection CssUnusedSymbol*/
.btn-texts:not(:disabled):not(.disabled):active:focus, .btn-texts:not(:disabled):not(.disabled).active:focus,
.show > .btn-texts.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

input[type='file'] + label[class="btn-outline-texts"] {
    color: var(--texts);
}

input[type='file']:hover + label[class="btn-outline-texts"] {
    color: white;
    background-color: var(--texts);
    opacity: 0.8;
}

input[type='file']:active + label[class="btn-outline-texts"] {
    color: white;
    background-color: var(--texts);
    opacity: 1;
}

.btn-outline-texts {
    color: var(--texts);
    background-color: transparent;
    background-image: none;
    border: 1px solid var(--texts) !important;
}

.btn-outline-texts:hover {
    color: var(--white);
    background-color: var(--texts);
    border-color: var(--texts);
}

.btn-outline-texts:active, .btn-outline-texts.active {
    color: white;
    background-color: var(--texts);
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

bot-table-button-group .btn-group .btn-outline-texts.active {
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

bot-table-button-group .btn-group > .focus {
    border: 0 !important;
}

.btn-outline-texts.disabled, .btn-outline-texts:disabled {
    color: var(--texts);
    background-color: transparent;
}

.btn-outline-texts:not(:disabled):not(.disabled):active, .btn-outline-texts:not(:disabled):not(.disabled).active,
.show > .btn-outline-texts {
    color: var(--white);
    background-color: var(--texts);
    border-color: var(--texts);
    box-shadow: 0 0 0 0 transparent !important;
}

.btn-outline-texts:not(:disabled):not(.disabled):active:focus, .btn-outline-texts:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-texts.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

.dropdown-item.btn-outline-texts {
    color: var(--texts);
    background-color: white;
    border: 0 !important;
    border-bottom: 1px solid var(--texts) !important;
}

.dropdown-item.btn-outline-texts i {
    color: var(--texts);
}

.dropdown-item.btn-outline-texts:hover i {
    color: white;
}

.dropdown-item.btn-outline-texts:hover {
    background-color: var(--texts);
    color: white;
    border: 0 !important;
    border-bottom: 1px solid var(--texts) !important;
}

.dropdown-item.btn-outline-texts.active {
    cursor: unset;
    box-shadow: 0 0 0 0 transparent !important;
}

.dropdown-item.btn-outline-texts a {
    text-decoration: none;
    color: inherit;
}

.btn-outline-texts.dropdown-toggle.active {
    background-color: white !important;
    color: var(--texts) !important;
    font-weight: 900;
    font-size: 1rem;
    box-shadow: 0 0 0 0 transparent !important;
}

.user-custom-checkbox .custom-control-label {
    margin-bottom: 0.6rem;
    margin-left: 0.5rem;
}
/*.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {*/
/*    background-color: var(--texts);*/
/*}*/


.bot-table-function-menu-button {
    float: left;
    margin-top: 5px;
    color: var(--texts) !important;
    border: 0 !important;
    background-color: transparent !important;
}
.bot-table-function-menu-button .dropdown-menu .dropdown-item .btn button i {
    background-color: var(--white) !important;
    color: var(--texts) !important;
}

.bot-table-function-menu-button .dropdown-item .btn-outline-texts:hover i {
    color: var(--texts) !important;
}

.bot-table-function-menu-button.dropdown-item, .btn-outline-texts.active {
    background-color: var(--texts) !important;
    color: var(--white) !important;
}


.bot-tab-content > .bot-tab-pane {
    display: none;
}

.bot-tab-content > .active {
    display: block;
}

.bot-tab-content:first-child > .active .bot-table {
    border-top-left-radius: unset;
}

.bot-tab-content:first-child > .active .bot-card {
    border-top-left-radius: unset;
}

.bot-tab-content:first-child > .active .bot-table-title-pane {
    border-top-left-radius: unset;
}

.bot-tab-content:first-child > .active .bot-card-header {
    border-top-left-radius: unset;
}

.bot-nav-tabs .bot-nav-link {
    color: var(--gray-600);
}

.bot-nav-tabs .bot-nav-link:hover {
    cursor: pointer;
}

.bot-nav-tabs .bot-nav-link.active {
    color: var(--texts);
    background: var(--light);
    border-color: var(--gray);
    border-bottom-color: var(--light);
}

.bot-nav-tabs .bot-nav-link.active:focus {
    background: var(--white);
    border-color: var(--secondary);
    border-bottom-color: var(--white);
}

.bot-tab-content {
    margin-top: -1px;
    background: transparent;
}

.bot-tab-content .bot-tab-pane {
    padding: 0;
}

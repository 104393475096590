@charset "UTF-8";
@import "~react-datepicker/dist/react-datepicker.css";
@import "~react-multiselect-box/build/css/index.css";
@import '../../../node_modules/react-block-ui/style.css';

body {
    font-family: 'Roboto', sans-serif;
}

.bot-table-expanded-row .bot-table {
    min-height: 20px !important;
}

.inspections-today-chart {
    min-width: 300px;
}

.inspections-this-month-chart {
    min-width: 300px;
}

.inspections-per-day-chart {
    min-width: 450px;
}

.dropZone {
    flex: 0 0 auto;
    height: 40px;
    transition: 200ms all;
}

.dropZone:nth-of-type(2n) {
    display: none;
}

.dropZone.horizontalDrag {
    width: 40px;
    height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
    flex: 1 1 auto;
    width: 100%;
    height: 2rem;
    border: 1px solid grey;
    text-align: center;
}

.dropZone.active,
.trashDropZone.active {
    background: var(--light);
    transition: 100ms all;
}

.remove-check {
    color: var(--gray-500);
    padding-right: 5px;
    cursor: pointer;
}

.unit_edit-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
    padding-right: 0 !important;
    text-align: end;
}

.unit_edit-col {
    padding-left: .25rem !important;
}

.unit_edit-form-group {
    margin-bottom: .15rem !important;
}

.unit_custom-form-group {
    margin-bottom: .15rem !important;
    margin-top: .5rem;
}

.unit_active-switch {
    color: var(--texts);
}

.unit_dot-switch {

}

.unit-create-dot-switch {
    padding-top: .8rem;
}

.unit_active-label {
    color: var(--texts);
    margin-right: .55rem;
}

.inspections-by-yard-chart {
    min-width: 300px;
}

.inspections-by-crew-chart {
    min-width: 300px;
}

.user-template-link {
    margin-right: auto !important;
}

.select-date-label {
    float: left;
}

.show-all-btn {
    float: left;
    margin-top: .75rem !important;
}

.react-bs-table-container {
    display: inline-block;
}

/*noinspection ALL*/
.react-bootstrap-table-page-btns-ul {
    max-width: 100%;
    overflow-y: scroll;
    height: 3rem;
}

.report-export-button {
    border: 2px solid var(--primary);
    color: var(--primary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.attachment-badge {
    cursor: pointer !important;
    color: var(--white) !important;
    /*background-color: var(--primary) !important;*/
    font-size: 12px !important;
    margin-left: 4px !important;
    vertical-align: middle !important;
}

/*noinspection CssUnusedSymbol*/
.drop-up .Select-menu-outer {
    top: auto;
    bottom: 100%;
}

/*noinspection CssUnusedSymbol*/
.modal-dialog {
    padding-top: 40px;
}

/*noinspection CssUnusedSymbol*/
.back-btn {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.comp-logo {
    height: 2.5rem;
    width: 8rem;
    /*max-width: 50%;*/
    /*max-height: 100%;*/
}

.back-btn:disabled {
    display: none;
}

.table-view-btn {
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1.25rem !important;
}

.table-edit-btn {
    cursor: pointer;
    margin-left: .75rem;
    margin-right: 1rem;
    font-size: 1.25rem !important;
}

.table-popout-btn {
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1.25rem !important;
}

.table-alert-btn {
    cursor: pointer;
    margin-right: 1rem;
    font-size: 1.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.skip-btn {
    color: black !important;
    background-color: transparent !important;
    border-color: transparent !important;
    float: right !important;
}

.skip-btn:disabled {
    display: none;
}

.table-title {
    text-align: center;
    min-width: 100%;
}

.btn-primary {
    background: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.inspection-image {
    border: 2px solid black;
    height: 300px;
    overflow: hidden;
    margin-bottom: 10px;
    margin-left: 10px;
}

/*noinspection CssUnusedSymbol*/
.attachment-img {
    border: 2px solid black;
    /*height: 300px;*/
    overflow: hidden;
    margin-bottom: 10px;
    margin-left: 10px;
    width: 100%;
}

.unit_status-label {
    max-width: 100%;
    font-weight: bold;
    font-size: 1rem;
}

.unit_detail-row {
    padding-left: .5rem;
    flex-wrap: nowrap !important;
}

.unit_detail-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.unit_detail-field {
    font-size: 1rem;
    float: left;
    padding-left: .25rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.navigation-bar {
    padding-top: .1rem;
    background-color: var(--white);
    width: 100%;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
    top: 3.4rem;
    font-size: 1rem;
}

.navigation-bar .dropdown-menu .dropdown {
    border-bottom: 1px solid var(--texts);
}

.navigation-bar .bot-nav-link {
    /*padding-left: .25rem;*/
    border: 0 !important;
    text-decoration: underline;
    padding-top: .75rem;
    color: black;
    background-color: transparent;
    /*display: inline-flex;*/
}

.navigation-bar .bot-nav-link.active {
    /*color: var(--primary);*/
    font-weight: 900;
    cursor: unset;
}

.navigation-bar .dropdown .btn {
    border: 0 !important;
    font-size: 1rem;
    text-decoration: underline;
    padding-top: .75rem;
}

.navigation-bar .dropdown .btn:hover {
    border: 0 !important;
    text-decoration: underline;
    padding-top: .75rem;
    background-color: transparent;
    color: black;
}

.navigation-bar .dropdown .dropdown-item.active {
    background-color: var(--white) !important;
    color: black !important;
    cursor: unset;
    /*color: var(--primary) !important;*/
    font-weight: 900;
}

.navigation-bar .dropdown.active > .btn {
    color: black !important;
    /*width: max-content;*/
    font-weight: 900;
}

/*noinspection CssUnusedSymbol*/
.attachment-row {
    padding-left: .5rem;
}

/*noinspection CssUnusedSymbol*/
.attachment-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
    padding-right: .25rem;
}

/*noinspection CssUnusedSymbol*/
.attachment-field {
    font-size: 1rem;
    float: left;
}

/*noinspection CssUnusedSymbol*/
.message-row {
    padding-left: .5rem;
}

/*noinspection CssUnusedSymbol*/
.message-label {
    font-weight: bold;
    font-size: 1rem;
}

/*noinspection CssUnusedSymbol*/
.message-field {
    font-size: 1rem;
    padding-left: .25rem;
}

.message-user-field {
    font-size: 1rem;
    padding-left: .25rem;
    cursor: pointer;
    text-decoration: underline;
}

/*noinspection CssUnusedSymbol*/
.attachment-link {
    cursor: pointer;
    color: var(--primary);
}

.attachment-link:hover {
    text-decoration: underline;
}

.user-row {
    padding-left: .5rem;
}

.user-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.user-field {
    font-size: 1rem;
    float: left;
    padding-left: .25rem;
}

/*noinspection CssUnusedSymbol*/
.task_detail-row {
    padding-left: .5rem;
}

/*noinspection CssUnusedSymbol*/
.task_detail-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.task_detail-field {
    font-size: 1rem;
    float: left;
    padding-left: .25rem;
}

/*noinspection CssUnusedSymbol*/
.log_detail-row {
    padding-left: .5rem;
}

/*noinspection CssUnusedSymbol*/
.log_detail-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.log_detail-field {
    font-size: 1rem;
    float: left;
    padding-left: .25rem;
}

/*noinspection CssUnusedSymbol*/
.workorder_detail-row {
    padding-left: .5rem;
}

/*noinspection CssUnusedSymbol*/
.workorder_detail-label {
    font-weight: bold;
    font-size: 1rem;
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.workorder_detail-field {
    font-size: 1rem;
    float: left;
    padding-left: .25rem;
}

.btn-create {
    background: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.modal-header {
    background: var(--primary);
}

.status {
    line-height: 52px;
}

.dotDropdown {
    padding-top: 0 !important;
}

.weekly-user-card {
    min-width: 70rem !important;
}

.weekly-user-header {
    text-decoration: underline;
    display: block !important;
}

.weekly-user-body {
    overflow: scroll !important;
    flex: none !important;
    flex-wrap: nowrap !important;
    min-height: 20rem;
}

.weekly-user-row {
    flex-wrap: nowrap !important;
}

.weekly-user-rm-btn {
    padding-top: 0 !important;
}

.weekly-user-input-foreman {
    border: solid 1px black;
    width: 100px !important;
    margin-left: 40px !important;
}

.weekly-user-input-code {
    border: solid 1px black;
    width: 100px !important;
    margin-left: 10px;
}

.weekly-user-input-sunday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 40px !important;
}

.weekly-user-input-monday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-input-tuesday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-input-wednesday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-input-thursday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-input-friday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-input-saturday {
    border: solid 1px black;
    width: 100px !important;
    text-align: center;
    margin-left: 50px !important;
}

.weekly-user-remove-field {
    margin-right: 40px;
    cursor: pointer;
}

.weekly-user-name {
    width: 180px !important;
    padding-left: 20px;
    text-align: right;
}

.weekly-user-emp {
    width: 70px !important;
    text-align: right;
    margin-left: 10px !important;
}

.weekly-user-terminate {
    margin-left: 10px !important;
    cursor: pointer;
}

.weekly-user-foreman {
    width: 200px !important;
    text-align: right;
    margin-left: 10px !important;
}

.weekly-user-code {
    width: 70px !important;
    text-align: right;
    margin-left: 10px !important;
}

.weekly-user-crew {
    width: 180px !important;
    text-align: right;
    margin-left: 10px !important;
}

.weekly-user-sunday {
    width: 100px;
    text-align: right;
    margin-left: 10px;
}

.weekly-user-monday {
    width: 100px;
    text-align: right;
}

.weekly-user-tuesday {
    width: 100px;
    text-align: right;
}

.weekly-user-wednesday {
    width: 100px;
    text-align: right;
}

.weekly-user-thursday {
    width: 100px;
    text-align: right;
}

.weekly-user-friday {
    width: 100px;
    text-align: right;
}

.weekly-user-saturday {
    width: 100px;
    text-align: right;
}

.wo-status-card {
    overflow: scroll;
}

.wo-status-header {
    overflow: scroll;
}

.wo-status-header-row {
    min-width: 350px;
}

.wo-status-report-container {
    overflow: scroll;
}

.wo-status-report {
    min-width: 1024px;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__container {
    position: relative;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__input--focused {
    outline: none;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__suggestions-container {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

/*noinspection CssUnusedSymbol*/
.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.big-hr {
    border: solid 5px;
}

.fixed-table-pagination li.active a {
    background: var(--primary);
    color: #0c0e10;
}

.my-edit-btn {
    color: var(--primary);
    background: var(--primary);
    border-color: var(--primary);
    border-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.user-edit-btn {
    color: var(--texts);
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}

input[type="file"] + button {
    /*display:none;*/
    margin: 10px;
    color: var(--primary)
}

input[type="file"]:hover + label {
    background-color: var(--primary);
    color: var(--white)
}

input[type="file"] + label {
    display: inline-block;
    /*margin:20px;*/
    padding: 4px 32px;
    background-color: var(--white);
    border: solid 2px var(--primary);
    border-radius: 6px;
    color: var(--primary);
}

input[type="file"]:active + label {
    background-color: var(--primary);
    color: var(--white)
}

*,
*::before,
*::after {
    box-sizing: border-box;
}

html {
    font-family: arial-rounded-mt-bold, 'Arial Rounded MT Bold', serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

@-ms-viewport {
    /*noinspection CssInvalidPropertyValue*/
    width: device-width;
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
}

body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--gray-900);
    text-align: left;
    background-color: #e4e5e6;
}

[tabindex="-1"]:focus {
    outline: 0 !important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0.5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit;
}

ol,
ul,
dl {
    margin-top: 0;
    margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

dfn {
    font-style: italic;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%;
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: var(--primary);
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:hover {
    color: #167495;
    text-decoration: underline;
}

a:not([href]):not([tabindex]) {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
    color: inherit;
    text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
    outline: 0;
}

pre,
code,
kbd,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    color: var(--gray-600);
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}

button {
    border-radius: 0;
}

button:focus {
    outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
    -webkit-appearance: listbox;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

/*noinspection CssUnusedSymbol*/
h1, .h1 {
    font-size: 2.1875rem;
}

/*noinspection CssUnusedSymbol*/
h2, .h2 {
    font-size: 1.75rem;
}

h3, .h3 {
    font-size: 1.53125rem;
}

h4, .h4 {
    font-size: 1.3125rem;
}

h5, .h5 {
    font-size: 1.09375rem;
}

/*noinspection CssUnusedSymbol*/
h6, .h6 {
    font-size: 0.875rem;
}

/*noinspection CssUnusedSymbol*/
.lead {
    font-size: 1.09375rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-3 {
    font-size: 4.5rem;
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
    font-weight: 300;
    line-height: 1.2;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

/*noinspection CssUnusedSymbol*/
small,
.small {
    font-size: 80%;
    font-weight: 400;
}

/*noinspection CssUnusedSymbol*/
mark,
.mark {
    padding: 0.2em;
    background-color: #fcf8e3;
}

/*noinspection CssUnusedSymbol*/
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

/*noinspection CssUnusedSymbol*/
.list-inline {
    padding-left: 0;
    list-style: none;
}

/*noinspection CssUnusedSymbol*/
.list-inline-item {
    display: inline-block;
}

/*noinspection CssUnusedSymbol*/
.list-inline-item:not(:last-child) {
    margin-right: 0.5rem;
}

/*noinspection CssUnusedSymbol*/
.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

/*noinspection CssUnusedSymbol*/
.blockquote {
    margin-bottom: 1rem;
    font-size: 1.09375rem;
}

/*noinspection CssUnusedSymbol*/
.blockquote-footer {
    display: block;
    font-size: 80%;
    color: var(--gray-600);
}

/*noinspection CssUnusedSymbol*/
.blockquote-footer::before {
    content: "\2014 \00A0";
}

/*noinspection CssUnusedSymbol*/
.img-fluid {
    max-width: 100%;
    height: auto;
}

/*noinspection CssUnusedSymbol*/
.img-thumbnail {
    padding: 0.25rem;
    background-color: #e4e5e6;
    border: 1px solid var(--secondary);
    border-radius: 0.25rem;
    max-width: 100%;
    height: auto;
}

/*noinspection CssUnusedSymbol*/
.figure {
    display: inline-block;
}

/*noinspection CssUnusedSymbol*/
.figure-img {
    margin-bottom: 0.5rem;
    line-height: 1;
}

/*noinspection CssUnusedSymbol*/
.figure-caption {
    font-size: 90%;
    color: var(--gray-600);
}

code {
    font-size: 87.5%;
    color: var(--pink);
    word-break: break-word;
}

a > code {
    color: inherit;
}

kbd {
    padding: 0.2rem 0.4rem;
    font-size: 87.5%;
    color: var(--white);
    background-color: var(--gray-900);
    border-radius: 0.2rem;
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700;
}

pre {
    display: block;
    font-size: 87.5%;
    color: var(--gray-900);
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal;
}

/*noinspection CssUnusedSymbol*/
.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll;
}

/*noinspection CssUnusedSymbol*/
.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .container {
        max-width: 540px;
    }
    .select-date-label {
        float: right !important;
    }

    .show-all-btn {
        float: right !important;
        margin: 0 !important;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    .container {
        max-width: 720px;
    }
    .select-date-label {
        float: right !important;
    }

    .show-all-btn {
        float: right !important;
        margin: 0 !important;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .container {
        max-width: 960px;
    }
    .select-date-label {
        float: right !important;
    }

    .show-all-btn {
        float: right !important;
        margin: 0 !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .container {
        max-width: 1140px;
    }
    .select-date-label {
        float: right !important;
    }

    .show-all-btn {
        float: right !important;
        margin: 0 !important;
    }
}

/*noinspection CssUnusedSymbol*/
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    /*padding-top: 1rem !important;*/
}

/*@media(max-width: 1200px) {*/
/*    .main .container-fluid {*/
/*        padding-top: 6rem !important;*/
/*    }*/
/*}*/

/*@media(max-width: 556px) {*/
/*    .main .container-fluid {*/
/*        padding-top: 13rem !important;*/
/*    }*/
/*}*/

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

/*noinspection CssUnusedSymbol*/
.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
}

/*noinspection CssUnusedSymbol*/
.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: none;
}

.col-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%;
}

.col-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%;
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
}

.col-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%;
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%;
}

/*noinspection CssUnusedSymbol*/
.col-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%;
}

.col-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%;
}

/*noinspection CssUnusedSymbol*/
.col-9 {
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%;
}

/*noinspection CssUnusedSymbol*/
.col-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%;
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%;
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

/*noinspection CssUnusedSymbol*/
.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

/*noinspection CssUnusedSymbol*/
.order-6 {
    order: 6;
}

/*noinspection CssUnusedSymbol*/
.order-7 {
    order: 7;
}

/*noinspection CssUnusedSymbol*/
.order-8 {
    order: 8;
}

/*noinspection CssUnusedSymbol*/
.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

/*noinspection CssUnusedSymbol*/
.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

/*noinspection CssUnusedSymbol*/
.offset-1 {
    margin-left: 8.33333%;
}

/*noinspection CssUnusedSymbol*/
.offset-2 {
    margin-left: 16.66667%;
}

/*noinspection CssUnusedSymbol*/
.offset-3 {
    margin-left: 25%;
}

/*noinspection CssUnusedSymbol*/
.offset-4 {
    margin-left: 33.33333%;
}

/*noinspection CssUnusedSymbol*/
.offset-5 {
    margin-left: 41.66667%;
}

/*noinspection CssUnusedSymbol*/
.offset-6 {
    margin-left: 50%;
}

/*noinspection CssUnusedSymbol*/
.offset-7 {
    margin-left: 58.33333%;
}

/*noinspection CssUnusedSymbol*/
.offset-8 {
    margin-left: 66.66667%;
}

/*noinspection CssUnusedSymbol*/
.offset-9 {
    margin-left: 75%;
}

/*noinspection CssUnusedSymbol*/
.offset-10 {
    margin-left: 83.33333%;
}

/*noinspection CssUnusedSymbol*/
.offset-11 {
    margin-left: 91.66667%;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-sm-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-sm-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-sm-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-sm-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    .col-sm-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-sm-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-first {
        order: -1;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-last {
        order: 13;
    }

    .order-sm-0 {
        order: 0;
    }

    .order-sm-1 {
        order: 1;
    }

    .order-sm-2 {
        order: 2;
    }

    .order-sm-3 {
        order: 3;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-4 {
        order: 4;
    }

    .order-sm-5 {
        order: 5;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-6 {
        order: 6;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-7 {
        order: 7;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-8 {
        order: 8;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-9 {
        order: 9;
    }

    .order-sm-10 {
        order: 10;
    }

    /*noinspection CssUnusedSymbol*/
    .order-sm-11 {
        order: 11;
    }

    .order-sm-12 {
        order: 12;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-0 {
        margin-left: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-1 {
        margin-left: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-2 {
        margin-left: 16.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-3 {
        margin-left: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-4 {
        margin-left: 33.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-5 {
        margin-left: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-6 {
        margin-left: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-7 {
        margin-left: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-8 {
        margin-left: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-9 {
        margin-left: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-10 {
        margin-left: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-sm-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-md-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-md-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-md-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        order: -1;
    }

    .order-md-last {
        order: 13;
    }

    .order-md-0 {
        order: 0;
    }

    .order-md-1 {
        order: 1;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-2 {
        order: 2;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-3 {
        order: 3;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-4 {
        order: 4;
    }

    .order-md-5 {
        order: 5;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-6 {
        order: 6;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-7 {
        order: 7;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-8 {
        order: 8;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-9 {
        order: 9;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-10 {
        order: 10;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-11 {
        order: 11;
    }

    /*noinspection CssUnusedSymbol*/
    .order-md-12 {
        order: 12;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-0 {
        margin-left: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-1 {
        margin-left: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-2 {
        margin-left: 16.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-3 {
        margin-left: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-4 {
        margin-left: 33.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-5 {
        margin-left: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-6 {
        margin-left: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-7 {
        margin-left: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-8 {
        margin-left: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-9 {
        margin-left: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-10 {
        margin-left: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-md-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    .col-lg-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    .col-lg-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    .col-lg-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-lg-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-first {
        order: -1;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-last {
        order: 13;
    }

    .order-lg-0 {
        order: 0;
    }

    .order-lg-1 {
        order: 1;
    }

    .order-lg-2 {
        order: 2;
    }

    .order-lg-3 {
        order: 3;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-4 {
        order: 4;
    }

    .order-lg-5 {
        order: 5;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-6 {
        order: 6;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-7 {
        order: 7;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-8 {
        order: 8;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-9 {
        order: 9;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-10 {
        order: 10;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-11 {
        order: 11;
    }

    /*noinspection CssUnusedSymbol*/
    .order-lg-12 {
        order: 12;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-0 {
        margin-left: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-1 {
        margin-left: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-2 {
        margin-left: 16.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-3 {
        margin-left: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-4 {
        margin-left: 33.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-5 {
        margin-left: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-6 {
        margin-left: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-7 {
        margin-left: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-8 {
        margin-left: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-9 {
        margin-left: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-10 {
        margin-left: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-lg-11 {
        margin-left: 91.66667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-1 {
        flex: 0 0 8.33333%;
        max-width: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-2 {
        flex: 0 0 16.66667%;
        max-width: 16.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-4 {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-5 {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-7 {
        flex: 0 0 58.33333%;
        max-width: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-8 {
        flex: 0 0 66.66667%;
        max-width: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-10 {
        flex: 0 0 83.33333%;
        max-width: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-11 {
        flex: 0 0 91.66667%;
        max-width: 91.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        order: -1;
    }

    .order-xl-last {
        order: 13;
    }

    .order-xl-0 {
        order: 0;
    }

    .order-xl-1 {
        order: 1;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-2 {
        order: 2;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-3 {
        order: 3;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-4 {
        order: 4;
    }

    .order-xl-5 {
        order: 5;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-6 {
        order: 6;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-7 {
        order: 7;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-8 {
        order: 8;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-9 {
        order: 9;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-10 {
        order: 10;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-11 {
        order: 11;
    }

    /*noinspection CssUnusedSymbol*/
    .order-xl-12 {
        order: 12;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-0 {
        margin-left: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-1 {
        margin-left: 8.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-2 {
        margin-left: 16.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-3 {
        margin-left: 25%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-4 {
        margin-left: 33.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-5 {
        margin-left: 41.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-6 {
        margin-left: 50%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-7 {
        margin-left: 58.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-8 {
        margin-left: 66.66667%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-9 {
        margin-left: 75%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-10 {
        margin-left: 83.33333%;
    }

    /*noinspection CssUnusedSymbol*/
    .offset-xl-11 {
        margin-left: 91.66667%;
    }
}

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 1rem;
    background-color: transparent;
}

.table th,
.table td {
    padding: 0.15rem;
    vertical-align: center;
    border-top: 1px solid var(--primary);
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid var(--primary);
}

.table tbody + tbody {
    border-top: 2px solid var(--secondary);
}

.table .table {
    background-color: var(--white);
}

.table-sm th,
.table-sm td {
    padding: 0.3rem;
}

/*noinspection CssUnusedSymbol*/
.table-bordered {
    border: 1px solid var(--secondary);
}

.table-bordered th,
.table-bordered td {
    border: 1px solid var(--secondary);
}

.table-bordered thead th,
.table-bordered thead td {
    border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
    border: 0;
}

.table-striped tbody tr {
    height: 2.5rem;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
}

.table-hover tbody tr:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
    background-color: #c1e7f4;
}

.table-hover .table-primary:hover {
    background-color: #abdff0;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
    background-color: #abdff0;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
    background-color: var(--light-gray);
}

.table-hover .table-secondary:hover {
    background-color: var(--gray);
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
    background-color: var(--gray);
}

/*noinspection CssUnusedSymbol*/
.table-success,
.table-success > th,
.table-success > td {
    background-color: #cdedd8;
}

/*noinspection CssUnusedSymbol*/
.table-hover .table-success:hover {
    background-color: #bae6c9;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
    background-color: #bae6c9;
}

.table-info,
.table-info > th,
.table-info > td {
    background-color: #d3eef6;
}

.table-hover .table-info:hover {
    background-color: #bee6f2;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
    background-color: #bee6f2;
}

.table-warning,
.table-warning > th,
.table-warning > td {
    background-color: #ffeeba;
}

.table-hover .table-warning:hover {
    background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
    background-color: #ffe8a1;
}

/*noinspection CssUnusedSymbol*/
.table-danger,
.table-danger > th,
.table-danger > td {
    background-color: #fdd6d6;
}

/*noinspection CssUnusedSymbol*/
.table-hover .table-danger:hover {
    background-color: #fcbebe;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
    background-color: #fcbebe;
}

.table-light,
.table-light > th,
.table-light > td {
    background-color: #fbfcfc;
}

.table-hover .table-light:hover {
    background-color: #ecf1f1;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
    background-color: #ecf1f1;
}

/*noinspection CssUnusedSymbol*/
.table-dark,
.table-dark > th,
.table-dark > td {
    background-color: #c5c6c8;
}

/*noinspection CssUnusedSymbol*/
.table-hover .table-dark:hover {
    background-color: #b8b9bc;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
    background-color: #b8b9bc;
}

.table-active,
.table-active > th,
.table-active > td {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075);
}

.table .thead-dark th {
    color: #e4e5e6;
    background-color: var(--gray-900);
    border-color: #343b41;
}

.table .thead-light th {
    color: var(--gray-700);
    background-color: var(--gray-200);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.table-dark {
    color: #e4e5e6;
    background-color: var(--gray-900);
}

.table-dark th,
.table-dark td,
.table-dark thead th {
    border-color: #343b41;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.table-dark.table-bordered {
    border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05);
}

.table-dark.table-hover tbody tr:hover {
    background-color: rgba(255, 255, 255, 0.075);
}

/*noinspection CssFloatPxLength*/
@media (max-width: 575.98px) {
    /*noinspection CssUnusedSymbol*/
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    /*noinspection CssUnusedSymbol*/
    .table-responsive-sm > .table-bordered {
        border: 0;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 767.98px) {
    /*noinspection CssUnusedSymbol*/
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    /*noinspection CssUnusedSymbol*/
    .table-responsive-md > .table-bordered {
        border: 0;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 991.98px) {
    /*noinspection CssUnusedSymbol*/
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    /*noinspection CssUnusedSymbol*/
    .table-responsive-lg > .table-bordered {
        border: 0;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 1199.98px) {
    /*noinspection CssUnusedSymbol*/
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    /*noinspection CssUnusedSymbol*/
    .table-responsive-xl > .table-bordered {
        border: 0;
    }
}

/*noinspection CssUnusedSymbol*/
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*noinspection CssUnusedSymbol*/
.table-responsive > .table-bordered {
    border: 0;
}

.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: var(--gray-700);
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid var(--gray-200);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: var(--gray-700);
    background-color: var(--white);
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.form-control::placeholder {
    color: var(--gray-600);
    opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
    background-color: var(--gray-200);
    opacity: 1;
}

select.form-control:not([size]):not([multiple]) {
    height: calc(2.0625rem + 2px);
}

select.form-control:focus::-ms-value {
    color: var(--gray-700);
    background-color: var(--white);
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

/*noinspection CssUnusedSymbol*/
.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
}

/*noinspection CssUnusedSymbol*/
.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76563rem;
    line-height: 1.5;
}

/*noinspection CssUnusedSymbol*/
.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: var(--gray-900);
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.form-control-plaintext.form-control-sm, .input-group-sm > .form-control-plaintext.form-control,
.input-group-sm > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-sm > .input-group-prepend > .form-control-plaintext.btn,
.input-group-sm > .input-group-append > .form-control-plaintext.btn, .form-control-plaintext.form-control-lg, .input-group-lg > .form-control-plaintext.form-control,
.input-group-lg > .input-group-prepend > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-append > .form-control-plaintext.input-group-text,
.input-group-lg > .input-group-prepend > .form-control-plaintext.btn,
.input-group-lg > .input-group-append > .form-control-plaintext.btn {
    padding-right: 0;
    padding-left: 0;
}

/*noinspection CssUnusedSymbol*/
.form-control-sm, .input-group-sm > .form-control,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/*noinspection CssUnusedSymbol*/
select.form-control-sm:not([size]):not([multiple]), .input-group-sm > select.form-control:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-sm > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-sm > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(1.64844rem + 2px);
}

/*noinspection CssUnusedSymbol*/
.form-control-lg, .input-group-lg > .form-control,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

/*noinspection CssUnusedSymbol*/
select.form-control-lg:not([size]):not([multiple]), .input-group-lg > select.form-control:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.input-group-text:not([size]):not([multiple]),
.input-group-lg > .input-group-prepend > select.btn:not([size]):not([multiple]),
.input-group-lg > .input-group-append > select.btn:not([size]):not([multiple]) {
    height: calc(2.64063rem + 2px);
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: 0.25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
    color: var(--gray-600);
}

.form-check-label {
    margin-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: var(--white);
    background-color: rgba(77, 189, 116, 0.8);
    border-radius: .2rem;
}

/*noinspection CssUnusedSymbol*/
.was-validated .form-control:valid, .form-control.is-valid, .was-validated,
.custom-select:valid,
.custom-select.is-valid {
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.was-validated .form-control:valid:focus, .form-control.is-valid:focus, .was-validated,
.custom-select:valid:focus,
.custom-select.is-valid:focus {
    border-color: var(--success);
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip, .was-validated,
.custom-select:valid ~ .valid-feedback,
.was-validated,
.custom-select:valid ~ .valid-tooltip,
.custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
    display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
    color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    background-color: #aadfbd;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
    background-color: #72cb91;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-file-input:valid ~ .custom-file-label::before, .custom-file-input.is-valid ~ .custom-file-label::before {
    border-color: inherit;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.25);
}

/*noinspection CssUnusedSymbol*/
.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1;
    color: var(--white);
    background-color: rgba(248, 108, 107, 0.8);
    border-radius: .2rem;
}

/*noinspection CssUnusedSymbol*/
.was-validated .form-control:invalid, .form-control.is-invalid, .was-validated,
.custom-select:invalid,
.custom-select.is-invalid {
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, .was-validated,
.custom-select:invalid:focus,
.custom-select.is-invalid:focus {
    border-color: var(--danger);
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip, .was-validated,
.custom-select:invalid ~ .invalid-feedback,
.was-validated,
.custom-select:invalid ~ .invalid-tooltip,
.custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
    display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
    color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    background-color: #fee5e5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
    background-color: #fa9c9c;
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-file-input:invalid ~ .custom-file-label::before, .custom-file-input.is-invalid ~ .custom-file-label::before {
    border-color: inherit;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
    display: block;
}

/*noinspection CssUnusedSymbol*/
.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.25);
}

/*noinspection CssUnusedSymbol*/
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%;
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    /*noinspection CssUnusedSymbol*/
    .form-inline .form-control-plaintext {
        display: inline-block;
    }

    .form-inline .input-group,
    .form-inline .custom-select {
        width: auto;
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }

    .form-inline .form-check-input {
        position: relative;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }

    /*noinspection CssUnusedSymbol*/
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover, .btn:focus {
    text-decoration: none;
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.btn.disabled, .btn:disabled {
    opacity: 0.65;
    cursor: unset;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled).active {
    background-image: none;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    overflow: hidden;
}

.btn-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:focus, .btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-secondary {
    color: var(--primary);
    background-color: var(--white);
    border-color: var(--primary);
    font-size: .75rem;
}

/*noinspection CssUnusedSymbol*/
.btn-secondary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.btn-secondary:focus, .btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-secondary.disabled, .btn-secondary:disabled {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: #acb5bc;
    border-color: #a5aeb7;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-success {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.btn-success:hover {
    color: var(--white);
    background-color: #3ea662;
    border-color: #3a9d5d;
}

/*noinspection CssUnusedSymbol*/
.btn-success:focus, .btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-success.disabled, .btn-success:disabled {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
    color: var(--white);
    background-color: #3a9d5d;
    border-color: #379457;
}

/*noinspection CssUnusedSymbol*/
.btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-info {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
    overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.btn-info:hover {
    color: var(--gray-900);
    background-color: #43b6d7;
    border-color: #39b2d5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-info:focus, .btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-info.disabled, .btn-info:disabled {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: var(--white);
    background-color: #39b2d5;
    border-color: #2eadd3;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-warning {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.btn-warning:hover {
    color: var(--gray-900);
    background-color: #e0a800;
    border-color: #d39e00;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-warning:focus, .btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-warning.disabled, .btn-warning:disabled {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: #d39e00;
    border-color: #c69500;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-danger {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.btn-danger:hover {
    color: var(--white);
    background-color: #f64846;
    border-color: #f63c3a;
}

/*noinspection CssUnusedSymbol*/
.btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-danger.disabled, .btn-danger:disabled {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
    color: var(--white);
    background-color: #f63c3a;
    border-color: #f5302e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-light {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.btn-light:hover {
    color: var(--gray-900);
    background-color: #d9e1e6;
    border-color: #d1dbe1;
}

/*noinspection CssUnusedSymbol*/
.btn-light:focus, .btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-light.disabled, .btn-light:disabled {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: #d1dbe1;
    border-color: #cad4dc;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-dark {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.btn-dark:hover {
    color: var(--white);
    background-color: #1e2225;
    border-color: #181b1e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-dark:focus, .btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-dark.disabled, .btn-dark:disabled {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
    color: var(--white);
    background-color: #181b1e;
    border-color: #121517;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-primary {
    color: var(--primary);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: var(--primary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-secondary {
    color: var(--secondary);
    background-color: transparent;
    background-image: none;
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-secondary:hover {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: var(--secondary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-success {
    color: var(--success);
    background-color: transparent;
    background-image: none;
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-success:hover {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: var(--success);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-info {
    color: var(--info);
    background-color: transparent;
    background-image: none;
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-info:hover {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: var(--info);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-warning {
    color: var(--warning);
    background-color: transparent;
    background-image: none;
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-warning:hover {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: var(--warning);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-danger {
    color: var(--danger);
    background-color: transparent;
    background-image: none;
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-danger:hover {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: var(--danger);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-light {
    color: var(--gray-100);
    background-color: transparent;
    background-image: none;
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-light:hover {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: var(--gray-100);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-dark {
    color: var(--gray-800);
    background-color: transparent;
    background-image: none;
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-dark:hover {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: var(--gray-800);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-link {
    font-weight: 400;
    color: var(--primary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-link:hover {
    color: #167495;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-link:disabled, .btn-link.disabled {
    color: var(--gray-600);
    pointer-events: none;
}

.btn-lg, .btn-group-lg > .btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/*noinspection CssUnusedSymbol*/
.btn-block {
    display: block;
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.btn-block + .btn-block {
    margin-top: 0.5rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.fade {
    transition: opacity 0.15s linear;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    /*noinspection CssUnusedSymbol*/
    .fade {
        transition: none;
    }
}

/*noinspection CssUnusedSymbol*/
.fade:not(.show) {
    opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.collapse:not(.show) {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    /*noinspection CssUnusedSymbol*/
    .collapsing {
        transition: none;
    }
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
}

/*noinspection CssUnusedSymbol*/
.dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}

/*noinspection CssUnusedSymbol*/
.dropdown-toggle:empty::after {
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0 0;
    margin: 0.125rem 0 0;
    font-size: 0.875rem;
    color: var(--gray-900);
    text-align: left;
    list-style: none;
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid var(--secondary);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.dropdown-menu-right {
    right: 0;
    left: auto;
}

/*noinspection CssUnusedSymbol*/
.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: 0.125rem;
}

/*noinspection CssUnusedSymbol*/
.dropup .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0;
    border-right: 0.3em solid transparent;
    border-bottom: 0.3em solid;
    border-left: 0.3em solid transparent;
}

/*noinspection CssUnusedSymbol*/
.dropup .dropdown-toggle:empty::after {
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: 0.125rem;
}

/*noinspection CssUnusedSymbol*/
.dropright .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0;
    border-bottom: 0.3em solid transparent;
    border-left: 0.3em solid;
}

/*noinspection CssUnusedSymbol*/
.dropright .dropdown-toggle:empty::after {
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.dropright .dropdown-toggle::after {
    vertical-align: 0;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: 0.125rem;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle::after {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle::after {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle::before {
    display: inline-block;
    width: 0;
    height: 0;
    margin-right: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid transparent;
    border-right: 0.3em solid;
    border-bottom: 0.3em solid transparent;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle:empty::after {
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle::before {
    vertical-align: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
    right: auto;
    bottom: auto;
}

/*noinspection CssUnusedSymbol*/
.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    overflow: hidden;
    border-top: 1px solid var(--gray-200);
}

/*noinspection CssUnusedSymbol*/
.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: var(--gray-900);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.dropdown-item:hover, .dropdown-item:focus {
    color: #181b1e;
    text-decoration: none;
    background-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.dropdown-item.active, .dropdown-item:active {
    color: var(--white);
    text-decoration: none;
    background-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.dropdown-item.disabled, .dropdown-item:disabled {
    color: var(--gray-600);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.dropdown-menu.show {
    display: block;
}

/*noinspection CssUnusedSymbol*/
.dropdown-header {
    display: block;
    padding: 0 1.5rem;
    margin-bottom: 0;
    font-size: 0.76563rem;
    color: var(--gray-600);
    white-space: nowrap;
}

/*noinspection CssUnusedSymbol*/
.dropdown-item-text {
    display: block;
    padding: 0.25rem 1.5rem;
    color: var(--gray-900);
}

/*noinspection CssUnusedSymbol*/
.btn-group,
.btn-group-vertical {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    vertical-align: middle;
    max-width: 100%;
    box-sizing: content-box;
    margin-bottom: 4px;
    height: max-content;
    justify-self: center;
}

.btn-group > .btn,
.btn-group-vertical > .btn {
    position: relative;
    flex: 0 1 auto;
}

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover {
    z-index: 1;
}

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
    z-index: 1;
}

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group {
    margin-left: -1px;
}

/*noinspection CssUnusedSymbol*/
.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.btn-toolbar .input-group {
    width: auto;
}

.btn-group > .btn:first-child {
    margin-left: 0;
}

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.dropdown-toggle-split {
    padding-right: 0.5625rem;
    padding-left: 0.5625rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.dropdown-toggle-split::after,
.dropup .dropdown-toggle-split::after,
.dropright .dropdown-toggle-split::after {
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.dropleft .dropdown-toggle-split::before {
    margin-right: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

/*noinspection CssUnusedSymbol*/
.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.btn-group-vertical .btn,
.btn-group-vertical .btn-group {
    width: 100%;
}

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0;
}

.btn-group-toggle > .btn input[type="radio"],
.btn-group-toggle > .btn input[type="checkbox"],
.btn-group-toggle > .btn-group > .btn input[type="radio"],
.btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%;
}

.input-group > .form-control,
.input-group > .custom-select,
.input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0;
}

.input-group > .form-control:focus,
.input-group > .custom-select:focus,
.input-group > .custom-file:focus {
    z-index: 3;
}

.input-group > .form-control + .form-control,
.input-group > .form-control + .custom-select,
.input-group > .form-control + .custom-file,
.input-group > .custom-select + .form-control,
.input-group > .custom-select + .custom-select,
.input-group > .custom-select + .custom-file,
.input-group > .custom-file + .form-control,
.input-group > .custom-file + .custom-select,
.input-group > .custom-file + .custom-file {
    margin-left: -1px;
}

.input-group > .form-control:not(:last-child),
.input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .form-control:not(:first-child),
.input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group > .custom-file {
    display: flex;
    align-items: center;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.input-group > .custom-file:not(:last-child) .custom-file-label,
.input-group > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.input-group-prepend,
.input-group-append {
    display: flex;
}

.input-group-prepend .btn,
.input-group-append .btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .btn + .btn,
.input-group-prepend .btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .btn,
.input-group-append .btn + .btn,
.input-group-append .btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .btn {
    margin-left: -1px;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-append {
    margin-left: -1px;
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: var(--gray-700);
    text-align: center;
    white-space: nowrap;
    background-color: var(--gray-100);
    border: 1px solid var(--gray-200);
    border-radius: 0.25rem;
}

.input-group-text input[type="radio"],
.input-group-text input[type="checkbox"] {
    margin-top: 0;
}

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

/*noinspection CssUnusedSymbol*/
.custom-control-inline {
    display: inline-flex;
    margin-right: 1rem;
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.custom-control-input:checked ~ .custom-control-label::before {
    color: var(--white);
    background-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

/*noinspection CssUnusedSymbol*/
.custom-control-input:active ~ .custom-control-label::before {
    color: var(--white);
    background-color: #b6e4f4;
}

/*noinspection CssUnusedSymbol*/
.custom-control-input:disabled ~ .custom-control-label {
    color: var(--gray-600);
}

/*noinspection CssUnusedSymbol*/
.custom-control-input:disabled ~ .custom-control-label::before {
    background-color: var(--gray-200);
}

/*noinspection CssUnusedSymbol*/
.custom-control-label {
    position: relative;
    justify-content: center;
    align-content: center;
    height: 100%;
}

/*noinspection CssUnusedSymbol*/
.custom-control-label::before {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    user-select: none;
    background-color: var(--white);
    border: var(--secondary) solid 1px;
    margin-left: 5px;
}

/*noinspection CssUnusedSymbol*/
.custom-control-label::after {
    position: absolute;
    top: 0.25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    margin-left: 5px;
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-label::before {
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--texts);
}

.badge-texts {
    background-color: var(--texts);
    color: var(--white);
}

.attachment-input-btn:hover .badge-texts {
    background-color: var(--white);
    color: var(--texts) !important;
}
/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: var(--light-red);
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
    background-color: rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.custom-radio .custom-control-label::before {
    border-radius: 50%;
}

/*noinspection CssUnusedSymbol*/
.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(32, 168, 216, 0.5);
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    line-height: 1.5;
    color: var(--gray-700);
    vertical-align: middle;
    background: var(--white) url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%232f353a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 0.75rem center;
    background-size: 8px 10px;
    border: 1px solid var(--gray-200);
    border-radius: 0.25rem;
    appearance: none;
}

.custom-select:focus {
    border-color: #8ad4ee;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(138, 212, 238, 0.5);
}

.custom-select:focus::-ms-value {
    color: var(--gray-700);
    background-color: var(--white);
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none;
}

.custom-select:disabled {
    color: var(--gray-600);
    background-color: var(--gray-200);
}

.custom-select::-ms-expand {
    opacity: 0;
}

.custom-select-sm {
    height: calc(1.64844rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 75%;
}

/*noinspection CssUnusedSymbol*/
.custom-select-lg {
    height: calc(2.64063rem + 2px);
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    font-size: 125%;
}

.custom-file {
    position: relative;
    display: inline-block;
    width: 100%;
    height: calc(2.0625rem + 2px);
    margin-bottom: 0;
}

.custom-file-input {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.0625rem + 2px);
    margin: 0;
    opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.custom-file-input:focus ~ .custom-file-label {
    border-color: #8ad4ee;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

/*noinspection CssUnusedSymbol*/
.custom-file-input:focus ~ .custom-file-label::after {
    border-color: #8ad4ee;
}

/*noinspection CssUnusedSymbol*/
.custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse";
}

/*noinspection CssUnusedSymbol*/
.custom-file-label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.0625rem + 2px);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: var(--gray-700);
    background-color: var(--white);
    border: 1px solid var(--gray-200);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: 2.0625rem;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    color: var(--gray-700);
    content: "Browse";
    background-color: var(--gray-100);
    border-left: 1px solid var(--gray-200);
    border-radius: 0 0.25rem 0.25rem 0;
}

.custom-range {
    width: 100%;
    padding-left: 0;
    background-color: transparent;
    appearance: none;
}

.custom-range:focus {
    outline: none;
}

.custom-range::-moz-focus-outer {
    border: 0;
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    appearance: none;
}

.custom-range::-webkit-slider-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #b6e4f4;
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--secondary);
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    appearance: none;
}

.custom-range::-moz-range-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.custom-range::-moz-range-thumb:active {
    background-color: #b6e4f4;
}

.custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: var(--secondary);
    border-color: transparent;
    border-radius: 1rem;
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    background-color: var(--primary);
    border: 0;
    border-radius: 1rem;
    appearance: none;
}

.custom-range::-ms-thumb:focus {
    outline: none;
    box-shadow: 0 0 0 1px #e4e5e6, 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.custom-range::-ms-thumb:active {
    background-color: #b6e4f4;
}

.custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem;
}

.custom-range::-ms-fill-lower {
    background-color: var(--secondary);
    border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: var(--secondary);
    border-radius: 1rem;
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.nav-link:hover, .nav-link:focus {
    text-decoration: none;
}

.nav-tabs {
    /*border-bottom: 1px solid var(--secondary);*/
}

/*noinspection CssUnusedSymbol*/
.nav-tabs .nav-item {
    margin-bottom: -1px;
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
    border-color: var(--gray-200) var(--gray-200) var(--secondary);
}

.nav-tabs .nav-link.disabled {
    color: var(--gray-600);
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
    color: var(--gray-700);
    background-color: #e4e5e6;
    border-color: var(--secondary) var(--secondary) #e4e5e6;
}

/*noinspection CssUnusedSymbol*/
.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: var(--white);
    background-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
}

/*noinspection CssUnusedSymbol*/
.nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

/*noinspection CssUnusedSymbol*/
.tab-content > .tab-pane {
    display: none;
}

.tab-content > .active {
    display: block;
}

.navbar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;

}

/*noinspection CssUnusedSymbol*/
.navbar > .container,
.navbar > .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

/*noinspection CssUnusedSymbol*/
.navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none;
}

/*noinspection CssUnusedSymbol*/
.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

/*noinspection CssUnusedSymbol*/
.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

/*noinspection CssUnusedSymbol*/
.navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

/*noinspection CssUnusedSymbol*/
.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

/*noinspection CssUnusedSymbol*/
.navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none;
}

/*noinspection CssUnusedSymbol*/
.navbar-toggler:not(:disabled):not(.disabled) {
    cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

/*noinspection CssFloatPxLength*/
@media (max-width: 575.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 767.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 991.98px) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 1199.98px) {
    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid {
        flex-wrap: nowrap;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto;
    }

    /*noinspection CssUnusedSymbol*/
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

/*noinspection CssUnusedSymbol*/
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

/*noinspection CssUnusedSymbol*/
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0;
}

/*noinspection CssUnusedSymbol*/
.navbar-expand .navbar-nav {
    flex-direction: row;
}

/*noinspection CssUnusedSymbol*/
.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.navbar-expand > .container,
.navbar-expand > .container-fluid {
    flex-wrap: nowrap;
}

/*noinspection CssUnusedSymbol*/
.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
}

/*noinspection CssUnusedSymbol*/
.navbar-expand .navbar-toggler {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.navbar-light .navbar-brand {
    color: rgba(0, 0, 0, 0.9);
}

/*noinspection CssUnusedSymbol*/
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

/*noinspection CssUnusedSymbol*/
.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

/*noinspection CssUnusedSymbol*/
.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

/*noinspection CssUnusedSymbol*/
.navbar-dark .navbar-brand {
    color: var(--white);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: var(--white);
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

/*noinspection CssUnusedSymbol*/
.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
    color: var(--white);
}

.navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
    color: var(--white);
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: var(--white);
    background-clip: border-box;
    border: 1px solid var(--secondary);
    border-radius: 0.25rem;
}

.card > hr {
    margin-right: 0;
    margin-left: 0;
}

/*noinspection CssUnusedSymbol*/
.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
    overflow: scroll;
}

.card-title {
    margin-bottom: 0.75rem;
}

/*noinspection CssUnusedSymbol*/
.card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link:hover {
    text-decoration: none;
}

.card-link + .card-link {
    margin-left: 1.25rem;
}

.card-header:first-child {
    border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
    margin-left: 1px;
    margin-right: 1px;
    margin-top: 1px;
    /*border-right: 1px solid var(--secondary);*/
    /*border-left: 1px solid var(--secondary);*/
}

/*noinspection CssUnusedSymbol*/
.card-header + .list-group .list-group-item:first-child {
    border-top: 0;
}

/*noinspection CssUnusedSymbol*/
.card-footer {
    padding: 0.75rem 1.25rem;
    background-color: var(--gray-100);
    border-top: 1px solid var(--secondary);
    margin-bottom: 1px;
}

/*noinspection CssUnusedSymbol*/
.card-footer:last-child {
    border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

/*noinspection CssUnusedSymbol*/
.card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
}

/*noinspection CssUnusedSymbol*/
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
}

/*noinspection CssUnusedSymbol*/
.card-img {
    width: 100%;
    border-radius: calc(0.25rem - 1px);
}

/*noinspection CssUnusedSymbol*/
.card-img-top {
    width: 100%;
    border-top-left-radius: calc(0.25rem - 1px);
    border-top-right-radius: calc(0.25rem - 1px);
}

/*noinspection CssUnusedSymbol*/
.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(0.25rem - 1px);
    border-bottom-left-radius: calc(0.25rem - 1px);
}

/*noinspection CssUnusedSymbol*/
.card-deck {
    display: flex;
    flex-direction: column;
}

.card-deck .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .card-deck {
        flex-flow: row wrap;
        margin-right: -15px;
        margin-left: -15px;
    }

    .card-deck .card {
        display: flex;
        flex: 1 0 0;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
    }
}

.card-group {
    display: flex;
    flex-direction: column;
}

.card-group > .card {
    margin-bottom: 15px;
}

@media (min-width: 576px) {
    .card-group {
        flex-flow: row wrap;
    }

    .card-group > .card {
        flex: 1 0 0;
        margin-bottom: 0;
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0;
    }

    .card-group > .card:first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .card-group > .card:first-child .card-img-top,
    .card-group > .card:first-child .card-header {
        border-top-right-radius: 0;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .card-group > .card:first-child .card-img-bottom,
    .card-group > .card:first-child .card-footer {
        border-bottom-right-radius: 0;
    }

    .card-group > .card:last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .card-group > .card:last-child .card-img-top,
    .card-group > .card:last-child .card-header {
        border-top-left-radius: 0;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .card-group > .card:last-child .card-img-bottom,
    .card-group > .card:last-child .card-footer {
        border-bottom-left-radius: 0;
    }

    .card-group > .card:only-child {
        border-radius: 0.25rem;
    }

    /*noinspection CssUnusedSymbol*/
    .card-group > .card:only-child .card-img-top,
    .card-group > .card:only-child .card-header {
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .card-group > .card:only-child .card-img-bottom,
    .card-group > .card:only-child .card-footer {
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
    }

    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) {
        border-radius: 0;
    }

    /*noinspection CssUnusedSymbol*/
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-top,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-img-bottom,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-header,
    .card-group > .card:not(:first-child):not(:last-child):not(:only-child) .card-footer {
        border-radius: 0;
    }
}

.card-columns .card {
    margin-bottom: 0.75rem;
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        orphans: 1;
        widows: 1;
    }

    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.accordion .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
}

.accordion .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0;
}

.accordion .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.accordion .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: 0.75rem 1rem;
    margin-bottom: 1.5rem;
    list-style: none;
    background-color: var(--white);
    border-radius: 0;
    position: fixed !important;
    z-index: 2;
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-item + .breadcrumb-item {
    padding-left: 0.5rem;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: var(--gray-600);
    content: "/";
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: underline;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-item + .breadcrumb-item:hover::before {
    text-decoration: none;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-item.active {
    color: var(--gray-600);
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: 0.25rem;
}

.page-link {
    position: relative;
    display: block;
    padding: 0.5rem 0.75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--primary);
    background-color: var(--white);
    border: 1px solid var(--secondary);
}

.page-link:hover {
    z-index: 2;
    color: #167495;
    text-decoration: none;
    background-color: var(--gray-200);
    border-color: var(--secondary);
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.page-link:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

.page-item:last-child .page-link {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
}

.page-item.active .page-link {
    z-index: 1;
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.page-item.disabled .page-link {
    color: var(--gray-600);
    pointer-events: none;
    cursor: auto;
    background-color: var(--white);
    border-color: var(--secondary);
}

.pagination-lg .page-link {
    padding: 0.75rem 1.5rem;
    font-size: 1.09375rem;
    line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: 0.3rem;
    border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: 0.3rem;
    border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: 0.2rem;
    border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}

.badge:empty {
    display: none;
}

.btn .badge {
    position: relative;
    top: -1px;
}

/*noinspection CssUnusedSymbol*/
.badge-pill {
    padding-right: 0.6em;
    padding-left: 0.6em;
    border-radius: 10rem;
}

/*noinspection CssUnusedSymbol*/
.badge-primary {
    color: var(--white);
    background-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.badge-primary[href]:hover, .badge-primary[href]:focus {
    color: var(--white);
    text-decoration: none;
    background-color: #1985ac;
}

/*noinspection CssUnusedSymbol*/
.badge-secondary {
    color: var(--gray-900);
    background-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.badge-secondary[href]:hover, .badge-secondary[href]:focus {
    color: var(--gray-900);
    text-decoration: none;
    background-color: #acb5bc;
}

/*noinspection CssUnusedSymbol*/
.badge-success {
    color: var(--white);
    background-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.badge-success[href]:hover, .badge-success[href]:focus {
    color: var(--white);
    text-decoration: none;
    background-color: #3a9d5d;
}

/*noinspection CssUnusedSymbol*/
.badge-info {
    color: var(--gray-900);
    background-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.badge-info[href]:hover, .badge-info[href]:focus {
    color: var(--gray-900);
    text-decoration: none;
    background-color: #39b2d5;
}

/*noinspection CssUnusedSymbol*/
.badge-warning {
    color: var(--gray-900);
    background-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.badge-warning[href]:hover, .badge-warning[href]:focus {
    color: var(--gray-900);
    text-decoration: none;
    background-color: #d39e00;
}

/*noinspection CssUnusedSymbol*/
.badge-danger {
    color: var(--white);
    background-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.badge-danger[href]:hover, .badge-danger[href]:focus {
    color: var(--white);
    text-decoration: none;
    background-color: #f63c3a;
}

/*noinspection CssUnusedSymbol*/
.badge-light {
    color: var(--gray-900);
    background-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.badge-light[href]:hover, .badge-light[href]:focus {
    color: var(--gray-900);
    text-decoration: none;
    background-color: #d1dbe1;
}

/*noinspection CssUnusedSymbol*/
.badge-dark {
    color: var(--white);
    background-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.badge-dark[href]:hover, .badge-dark[href]:focus {
    color: var(--white);
    text-decoration: none;
    background-color: #181b1e;
}

/*noinspection CssUnusedSymbol*/
.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: var(--gray-200);
    border-radius: 0.3rem;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .jumbotron {
        padding: 4rem 2rem;
    }
}

/*noinspection CssUnusedSymbol*/
.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0;
}

.alert {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

/*noinspection CssUnusedSymbol*/
.alert-dismissible {
    padding-right: 3.8125rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit;
}

/*noinspection CssUnusedSymbol*/
.alert-primary {
    color: #115770;
    background-color: #d2eef7;
    border-color: #c1e7f4;
}

.alert-primary hr {
    border-top-color: #abdff0;
}

.alert-primary .alert-link {
    color: #0a3544;
}

/*noinspection CssUnusedSymbol*/
.alert-secondary {
    color: #686b6e;
    background-color: #f4f5f6;
    border-color: #f0f1f3;
}

.alert-secondary hr {
    border-top-color: #e2e4e8;
}

.alert-secondary .alert-link {
    color: #4f5254;
}

/*noinspection CssUnusedSymbol*/
.alert-success {
    color: #28623c;
    background-color: #dbf2e3;
    border-color: #cdedd8;
}

.alert-success hr {
    border-top-color: #bae6c9;
}

.alert-success .alert-link {
    color: #193e26;
}

.alert-info {
    color: #336573;
    background-color: #e0f3f8;
    border-color: #d3eef6;
}

.alert-info hr {
    border-top-color: #bee6f2;
}

.alert-info .alert-link {
    color: #234650;
}

.alert-warning {
    color: #856404;
    background-color: var(--white) 3 cd;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

/*noinspection CssUnusedSymbol*/
.alert-danger {
    color: #813838;
    background-color: #fee2e1;
    border-color: #fdd6d6;
}

.alert-danger hr {
    border-top-color: #fcbebe;
}

.alert-danger .alert-link {
    color: #5d2929;
}

/*noinspection CssUnusedSymbol*/
.alert-light {
    color: #7d7e7f;
    background-color: #fcfdfd;
    border-color: #fbfcfc;
}

.alert-light hr {
    border-top-color: #ecf1f1;
}

.alert-light .alert-link {
    color: #646565;
}

/*noinspection CssUnusedSymbol*/
.alert-dark {
    color: #181c1e;
    background-color: #d5d7d8;
    border-color: #c5c6c8;
}

.alert-dark hr {
    border-top-color: #b8b9bc;
}

.alert-dark .alert-link {
    color: #010202;
}

@keyframes progress-bar-stripes {
    from {
        background-position: 1rem 0;
    }
    to {
        background-position: 0 0;
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: var(--gray-100);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: var(--white);
    text-align: center;
    white-space: nowrap;
    background-color: var(--primary);
    transition: width 0.6s ease;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    /*noinspection CssUnusedSymbol*/
    .progress-bar {
        transition: none;
    }
}

/*noinspection CssUnusedSymbol*/
.progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
}

/*noinspection CssUnusedSymbol*/
.progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
}

.media {
    display: flex;
    align-items: flex-start;
}

.media-body {
    flex: 1;
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-action {
    width: 100%;
    color: var(--gray-700);
    text-align: inherit;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-action:hover, .list-group-item-action:focus {
    color: var(--gray-700);
    text-decoration: none;
    background-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-action:active {
    color: var(--gray-900);
    background-color: var(--gray-200);
}

/*noinspection CssUnusedSymbol*/
.list-group-item {
    position: relative;
    display: block;
    padding: 0.75rem 1.25rem;
    margin-bottom: -1px;
    background-color: var(--white);
    border: 1px solid rgba(0, 0, 0, 0.125);
}

/*noinspection CssUnusedSymbol*/
.list-group-item:first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item:hover, .list-group-item:focus {
    z-index: 1;
    text-decoration: none;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item.disabled, .list-group-item:disabled {
    color: var(--gray-600);
    background-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.list-group-item.active {
    z-index: 2;
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0;
}

/*noinspection CssUnusedSymbol*/
.list-group-flush:last-child .list-group-item:last-child {
    border-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-primary {
    color: #115770;
    background-color: #c1e7f4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #115770;
    background-color: #abdff0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-primary.list-group-item-action.active {
    color: var(--white);
    background-color: #115770;
    border-color: #115770;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-secondary {
    color: #686b6e;
    background-color: #f0f1f3;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #686b6e;
    background-color: #e2e4e8;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-secondary.list-group-item-action.active {
    color: var(--white);
    background-color: #686b6e;
    border-color: #686b6e;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-success {
    color: #28623c;
    background-color: #cdedd8;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #28623c;
    background-color: #bae6c9;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-success.list-group-item-action.active {
    color: var(--white);
    background-color: #28623c;
    border-color: #28623c;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-info {
    color: #336573;
    background-color: #d3eef6;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #336573;
    background-color: #bee6f2;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-info.list-group-item-action.active {
    color: var(--white);
    background-color: #336573;
    border-color: #336573;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-warning {
    color: #856404;
    background-color: #ffeeba;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #856404;
    background-color: #ffe8a1;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-warning.list-group-item-action.active {
    color: var(--white);
    background-color: #856404;
    border-color: #856404;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-danger {
    color: #813838;
    background-color: #fdd6d6;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #813838;
    background-color: #fcbebe;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-danger.list-group-item-action.active {
    color: var(--white);
    background-color: #813838;
    border-color: #813838;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-light {
    color: #7d7e7f;
    background-color: #fbfcfc;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7d7e7f;
    background-color: #ecf1f1;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-light.list-group-item-action.active {
    color: var(--white);
    background-color: #7d7e7f;
    border-color: #7d7e7f;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-dark {
    color: #181c1e;
    background-color: #c5c6c8;
}

/*noinspection CssUnusedSymbol*/
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #181c1e;
    background-color: #b8b9bc;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-item-dark.list-group-item-action.active {
    color: var(--white);
    background-color: #181c1e;
    border-color: #181c1e;
}

.close {
    float: right;
    font-size: 1.3125rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 var(--white);
    opacity: .5;
}

.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    opacity: .75;
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer;
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

/*noinspection CssUnusedSymbol*/
.modal-open {
    overflow: hidden;
}

.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    overflow: hidden;
    outline: 0;
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}

/*noinspection CssUnusedSymbol*/
.modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
}

/*noinspection CssUnusedSymbol*/
.modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -25%);
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    /*noinspection CssUnusedSymbol*/
    .modal.fade .modal-dialog {
        transition: none;
    }
}

/*noinspection CssUnusedSymbol*/
.modal.show .modal-dialog {
    transform: translate(0, 0);
}

/*noinspection CssUnusedSymbol*/
.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - (0.5rem * 2));
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
}

/*noinspection CssUnusedSymbol*/
.modal-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1040;
    background-color: #000;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.modal-backdrop.fade {
    opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.modal-backdrop.show {
    opacity: 0.5;
}

/*noinspection CssUnusedSymbol*/
.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid var(--gray-200);
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto;
}

/*noinspection CssUnusedSymbol*/
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
}

/*noinspection CssUnusedSymbol*/
.modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid var(--gray-200);
}

.modal-footer > :not(:first-child) {
    margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
    margin-right: .25rem;
}

/*noinspection CssUnusedSymbol*/
.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto;
    }

    /*noinspection CssUnusedSymbol*/
    .modal-dialog-centered {
        min-height: calc(100% - (1.75rem * 2));
    }

    .modal-sm {
        max-width: 300px;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 800px;
    }

    .modal-xl {
        max-width: 90%;
    }

    .modal-xxl {
        max-width: 100%;
    }
}

/*noinspection CssUnusedSymbol*/
.tooltip {
    position: relative;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    opacity: 0;
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.tooltip.show {
    opacity: 0.9;
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem;
}

.tooltip .arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
    padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
    top: 0;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: #000;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
    padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
    right: 0;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: #000;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
    padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
    bottom: 0;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: #000;
}

/*noinspection CssUnusedSymbol*/
.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
    padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
    left: 0;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: #000;
}

/*noinspection CssUnusedSymbol*/
.tooltip-inner {
    max-width: 200px;
    padding: 0.25rem 0.5rem;
    color: var(--white);
    text-align: center;
    background-color: #000;
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 0.76563rem;
    word-wrap: break-word;
    background-color: var(--white);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem;
}

.popover .arrow::before, .popover .arrow::after {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
}

/*noinspection CssUnusedSymbol*/
.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.5rem;
}

.bs-popover-top .arrow, .bs-popover-auto[x-placement^="top"] .arrow {
    bottom: calc((0.5rem + 1px) * -1);
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before,
.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    border-width: 0.5rem 0.5rem 0;
}

.bs-popover-top .arrow::before, .bs-popover-auto[x-placement^="top"] .arrow::before {
    bottom: 0;
    border-top-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-top .arrow::after,
.bs-popover-auto[x-placement^="top"] .arrow::after {
    bottom: 1px;
    border-top-color: var(--white);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
    margin-left: 0.5rem;
}

.bs-popover-right .arrow, .bs-popover-auto[x-placement^="right"] .arrow {
    left: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before,
.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    border-width: 0.5rem 0.5rem 0.5rem 0;
}

.bs-popover-right .arrow::before, .bs-popover-auto[x-placement^="right"] .arrow::before {
    left: 0;
    border-right-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-right .arrow::after,
.bs-popover-auto[x-placement^="right"] .arrow::after {
    left: 1px;
    border-right-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.5rem;
}

.bs-popover-bottom .arrow, .bs-popover-auto[x-placement^="bottom"] .arrow {
    top: calc((0.5rem + 1px) * -1);
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before,
.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    border-width: 0 0.5rem 0.5rem 0.5rem;
}

.bs-popover-bottom .arrow::before, .bs-popover-auto[x-placement^="bottom"] .arrow::before {
    top: 0;
    border-bottom-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-bottom .arrow::after,
.bs-popover-auto[x-placement^="bottom"] .arrow::after {
    top: 1px;
    border-bottom-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7;
}

/*noinspection CssUnusedSymbol*/
.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
    margin-right: 0.5rem;
}

.bs-popover-left .arrow, .bs-popover-auto[x-placement^="left"] .arrow {
    right: calc((0.5rem + 1px) * -1);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before,
.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
}

.bs-popover-left .arrow::before, .bs-popover-auto[x-placement^="left"] .arrow::before {
    right: 0;
    border-left-color: rgba(0, 0, 0, 0.25);
}

.bs-popover-left .arrow::after,
.bs-popover-auto[x-placement^="left"] .arrow::after {
    right: 1px;
    border-left-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    font-size: 0.875rem;
    color: inherit;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
}

/*noinspection CssUnusedSymbol*/
.popover-header:empty {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.popover-body {
    padding: 0.5rem 0.75rem;
    color: var(--gray-900);
}

/*noinspection CssUnusedSymbol*/
.carousel {
    position: relative;
}

/*noinspection CssUnusedSymbol*/
.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.carousel-item {
    position: relative;
    display: none;
    align-items: center;
    width: 100%;
    transition: transform 0.6s ease;
    backface-visibility: hidden;
    perspective: 1000px;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    /*noinspection CssUnusedSymbol*/
    .carousel-item {
        transition: none;
    }
}

/*noinspection CssUnusedSymbol*/
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
    display: block;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.carousel-item-next,
.carousel-item-prev {
    position: absolute;
    top: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.carousel-item-next.carousel-item-left,
.carousel-item-prev.carousel-item-right {
    transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
    .carousel-item-next.carousel-item-left,
    .carousel-item-prev.carousel-item-right {
        transform: translate3d(0, 0, 0);
    }
}

/*noinspection CssUnusedSymbol*/
.carousel-item-next,
.active.carousel-item-right {
    transform: translateX(100%);
}

@supports (transform-style: preserve-3d) {
    /*noinspection CssUnusedSymbol*/
    .carousel-item-next,
    .active.carousel-item-right {
        transform: translate3d(100%, 0, 0);
    }
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.carousel-item-prev,
.active.carousel-item-left {
    transform: translateX(-100%);
}

@supports (transform-style: preserve-3d) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .carousel-item-prev,
    .active.carousel-item-left {
        transform: translate3d(-100%, 0, 0);
    }
}

/*noinspection CssUnusedSymbol*/
.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
    opacity: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
    transform: translateX(0);
}

@supports (transform-style: preserve-3d) {
    /*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
    .carousel-fade .carousel-item-next,
    .carousel-fade .carousel-item-prev,
    .carousel-fade .carousel-item.active,
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-prev {
        transform: translate3d(0, 0, 0);
    }
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    color: var(--white);
    text-align: center;
    opacity: 0.5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
    color: var(--white);
    text-decoration: none;
    outline: 0;
    opacity: .9;
}

/*noinspection CssUnusedSymbol*/
.carousel-control-prev {
    left: 0;
}

/*noinspection CssUnusedSymbol*/
.carousel-control-next {
    right: 0;
}

/*noinspection CssUnusedSymbol*/
.carousel-control-prev-icon,
.carousel-control-next-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: transparent no-repeat center center;
    background-size: 100% 100%;
}

/*noinspection CssUnusedSymbol*/
.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 10px;
    left: 0;
    z-index: 15;
    display: flex;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
}

.carousel-indicators li {
    position: relative;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.5);
}

.carousel-indicators li::before {
    position: absolute;
    top: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators li::after {
    position: absolute;
    bottom: -10px;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 10px;
    content: "";
}

.carousel-indicators .active {
    background-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: var(--white);
    text-align: center;
}

/*noinspection CssUnusedSymbol*/
.align-baseline {
    vertical-align: baseline !important;
}

.align-top {
    vertical-align: top !important;
}

/*noinspection CssUnusedSymbol*/
.align-middle {
    vertical-align: middle !important;
}

.align-bottom {
    vertical-align: bottom !important;
}

.align-text-bottom {
    vertical-align: text-bottom !important;
}

/*noinspection CssUnusedSymbol*/
.align-text-top {
    vertical-align: text-top !important;
}

/*noinspection CssUnusedSymbol*/
.bg-primary {
    background-color: var(--primary) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
    background-color: #1985ac !important;
}

/*noinspection CssUnusedSymbol*/
.bg-secondary {
    background-color: var(--secondary) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
    background-color: #acb5bc !important;
}

/*noinspection CssUnusedSymbol*/
.bg-success {
    background-color: var(--success) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
    background-color: #3a9d5d !important;
}

/*noinspection CssUnusedSymbol*/
.bg-info {
    background-color: var(--info) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
    background-color: #39b2d5 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-warning {
    background-color: var(--warning) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
    background-color: #d39e00 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-danger {
    background-color: var(--danger) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
    background-color: #f63c3a !important;
}

/*noinspection CssUnusedSymbol*/
.bg-light {
    background-color: var(--gray-100) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
    background-color: #d1dbe1 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-dark {
    background-color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
    background-color: #181b1e !important;
}

/*noinspection CssUnusedSymbol*/
.bg-white {
    background-color: var(--white) !important;
}

/*noinspection CssUnusedSymbol*/
.bg-transparent {
    background-color: transparent !important;
}

.border {
    border: 1px solid var(--secondary) !important;
}

.border-top {
    border-top: 1px solid var(--secondary) !important;
}

.border-right {
    border-right: 1px solid var(--secondary) !important;
}

.border-bottom {
    border-bottom: 1px solid var(--secondary) !important;
}

.border-left {
    border-left: 1px solid var(--secondary) !important;
}

.border-0 {
    border: 0 !important;
}

.border-top-0 {
    border-top: 0 !important;
}

.border-right-0 {
    border-right: 0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.border-left-0 {
    border-left: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.border-primary {
    border-color: var(--primary) !important;
}

/*noinspection CssUnusedSymbol*/
.border-secondary {
    border-color: var(--secondary) !important;
}

/*noinspection CssUnusedSymbol*/
.border-success {
    border-color: var(--success) !important;
}

/*noinspection CssUnusedSymbol*/
.border-info {
    border-color: var(--info) !important;
}

/*noinspection CssUnusedSymbol*/
.border-warning {
    border-color: var(--warning) !important;
}

/*noinspection CssUnusedSymbol*/
.border-danger {
    border-color: var(--danger) !important;
}

/*noinspection CssUnusedSymbol*/
.border-light {
    border-color: var(--gray-100) !important;
}

/*noinspection CssUnusedSymbol*/
.border-dark {
    border-color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol*/
.border-white {
    border-color: var(--white) !important;
}

/*noinspection CssUnusedSymbol*/
.rounded {
    border-radius: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-top {
    border-top-left-radius: 0.25rem !important;
    border-top-right-radius: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-right {
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-bottom {
    border-bottom-right-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-left {
    border-top-left-radius: 0.25rem !important;
    border-bottom-left-radius: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-circle {
    border-radius: 50% !important;
}

/*noinspection CssUnusedSymbol*/
.rounded-0 {
    border-radius: 0 !important;
}

.clearfix::after {
    display: block;
    clear: both;
    content: "";
}

.d-none {
    display: none !important;
}

/*noinspection CssUnusedSymbol*/
.d-inline {
    display: inline !important;
}

/*noinspection CssUnusedSymbol*/
.d-inline-block {
    display: inline-block !important;
}

.d-block {
    display: block !important;
}

.d-table {
    display: table !important;
}

/*noinspection CssUnusedSymbol*/
.d-table-row {
    display: table-row !important;
}

/*noinspection CssUnusedSymbol*/
.d-table-cell {
    display: table-cell !important;
}

/*noinspection CssUnusedSymbol*/
.d-flex {
    display: flex !important;
}

/*noinspection CssUnusedSymbol*/
.d-inline-flex {
    display: inline-flex !important;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .d-sm-none {
        display: none !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-inline {
        display: inline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-inline-block {
        display: inline-block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-block {
        display: block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-table {
        display: table !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-table-row {
        display: table-row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-table-cell {
        display: table-cell !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-flex {
        display: flex !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-sm-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    .d-md-none {
        display: none !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-inline {
        display: inline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-inline-block {
        display: inline-block !important;
    }

    .d-md-block {
        display: block !important;
    }

    .d-md-table {
        display: table !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-table-row {
        display: table-row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-table-cell {
        display: table-cell !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-flex {
        display: flex !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-md-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-inline {
        display: inline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-inline-block {
        display: inline-block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-block {
        display: block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-table {
        display: table !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-table-row {
        display: table-row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-table-cell {
        display: table-cell !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-flex {
        display: flex !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-lg-inline-flex {
        display: inline-flex !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .d-xl-none {
        display: none !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-inline {
        display: inline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-inline-block {
        display: inline-block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-block {
        display: block !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-table {
        display: table !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-table-row {
        display: table-row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-table-cell {
        display: table-cell !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-flex {
        display: flex !important;
    }

    /*noinspection CssUnusedSymbol*/
    .d-xl-inline-flex {
        display: inline-flex !important;
    }
}

@media print {
    .d-print-none {
        display: none !important;
    }

    .d-print-inline {
        display: inline !important;
    }

    .d-print-inline-block {
        display: inline-block !important;
    }

    .d-print-block {
        display: block !important;
    }

    .d-print-table {
        display: table !important;
    }

    .d-print-table-row {
        display: table-row !important;
    }

    .d-print-table-cell {
        display: table-cell !important;
    }

    .d-print-flex {
        display: flex !important;
    }

    .d-print-inline-flex {
        display: inline-flex !important;
    }
}

/*noinspection CssUnusedSymbol*/
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.embed-responsive::before {
    display: block;
    content: "";
}

/*noinspection CssUnusedSymbol*/
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}

/*noinspection CssUnusedSymbol*/
.embed-responsive-21by9::before {
    padding-top: 42.85714%;
}

/*noinspection CssUnusedSymbol*/
.embed-responsive-16by9::before {
    padding-top: 56.25%;
}

/*noinspection CssUnusedSymbol*/
.embed-responsive-4by3::before {
    padding-top: 75%;
}

/*noinspection CssUnusedSymbol*/
.embed-responsive-1by1::before {
    padding-top: 100%;
}

.flex-row {
    flex-direction: row !important;
}

/*noinspection CssUnusedSymbol*/
.flex-column {
    flex-direction: column !important;
}

/*noinspection CssUnusedSymbol*/
.flex-row-reverse {
    flex-direction: row-reverse !important;
}

/*noinspection CssUnusedSymbol*/
.flex-column-reverse {
    flex-direction: column-reverse !important;
}

/*noinspection CssUnusedSymbol*/
.flex-wrap {
    flex-wrap: wrap !important;
}

/*noinspection CssUnusedSymbol*/
.flex-nowrap {
    flex-wrap: nowrap !important;
}

/*noinspection CssUnusedSymbol*/
.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important;
}

/*noinspection CssUnusedSymbol*/
.flex-fill {
    flex: 1 1 auto !important;
}

/*noinspection CssUnusedSymbol*/
.flex-grow-0 {
    flex-grow: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.flex-grow-1 {
    flex-grow: 1 !important;
}

/*noinspection CssUnusedSymbol*/
.flex-shrink-0 {
    flex-shrink: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.flex-shrink-1 {
    flex-shrink: 1 !important;
}

/*noinspection CssUnusedSymbol*/
.justify-content-start {
    justify-content: flex-start !important;
}

/*noinspection CssUnusedSymbol*/
.justify-content-end {
    justify-content: flex-end !important;
}

.justify-content-center {
    justify-content: center !important;
}

/*noinspection CssUnusedSymbol*/
.justify-content-between {
    justify-content: space-between !important;
}

/*noinspection CssUnusedSymbol*/
.justify-content-around {
    justify-content: space-around !important;
}

/*noinspection CssUnusedSymbol*/
.align-items-start {
    align-items: flex-start !important;
}

/*noinspection CssUnusedSymbol*/
.align-items-end {
    align-items: flex-end !important;
}

.align-items-center {
    align-items: center !important;
}

/*noinspection CssUnusedSymbol*/
.align-items-baseline {
    align-items: baseline !important;
}

/*noinspection CssUnusedSymbol*/
.align-items-stretch {
    align-items: stretch !important;
}

/*noinspection CssUnusedSymbol*/
.align-content-start {
    align-content: flex-start !important;
}

.align-content-end {
    align-content: flex-end !important;
}

.align-content-center {
    align-content: center !important;
}

/*noinspection CssUnusedSymbol*/
.align-content-between {
    align-content: space-between !important;
}

/*noinspection CssUnusedSymbol*/
.align-content-around {
    align-content: space-around !important;
}

/*noinspection CssUnusedSymbol*/
.align-content-stretch {
    align-content: stretch !important;
}

/*noinspection CssUnusedSymbol*/
.align-self-auto {
    align-self: auto !important;
}

/*noinspection CssUnusedSymbol*/
.align-self-start {
    align-self: flex-start !important;
}

.align-self-end {
    align-self: flex-end !important;
}

/*noinspection CssUnusedSymbol*/
.align-self-center {
    align-self: center !important;
}

/*noinspection CssUnusedSymbol*/
.align-self-baseline {
    align-self: baseline !important;
}

/*noinspection CssUnusedSymbol*/
.align-self-stretch {
    align-self: stretch !important;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .flex-sm-row {
        flex-direction: row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-column {
        flex-direction: column !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-row-reverse {
        flex-direction: row-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-column-reverse {
        flex-direction: column-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-wrap {
        flex-wrap: wrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-nowrap {
        flex-wrap: nowrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-fill {
        flex: 1 1 auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-grow-0 {
        flex-grow: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-grow-1 {
        flex-grow: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-shrink-0 {
        flex-shrink: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-sm-shrink-1 {
        flex-shrink: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-sm-start {
        justify-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-sm-end {
        justify-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-sm-center {
        justify-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-sm-between {
        justify-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-sm-around {
        justify-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-sm-start {
        align-items: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-sm-end {
        align-items: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-sm-center {
        align-items: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-sm-baseline {
        align-items: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-sm-stretch {
        align-items: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-start {
        align-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-end {
        align-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-center {
        align-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-between {
        align-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-around {
        align-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-sm-stretch {
        align-content: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-auto {
        align-self: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-start {
        align-self: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-end {
        align-self: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-center {
        align-self: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-baseline {
        align-self: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-sm-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-column {
        flex-direction: column !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-row-reverse {
        flex-direction: row-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-column-reverse {
        flex-direction: column-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-wrap {
        flex-wrap: wrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-nowrap {
        flex-wrap: nowrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-fill {
        flex: 1 1 auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-grow-0 {
        flex-grow: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-grow-1 {
        flex-grow: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-shrink-0 {
        flex-shrink: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-md-shrink-1 {
        flex-shrink: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-md-start {
        justify-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-md-end {
        justify-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-md-center {
        justify-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-md-between {
        justify-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-md-around {
        justify-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-md-start {
        align-items: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-md-end {
        align-items: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-md-center {
        align-items: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-md-baseline {
        align-items: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-md-stretch {
        align-items: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-md-start {
        align-content: flex-start !important;
    }

    .align-content-md-end {
        align-content: flex-end !important;
    }

    .align-content-md-center {
        align-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-md-between {
        align-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-md-around {
        align-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-md-stretch {
        align-content: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-md-auto {
        align-self: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-md-start {
        align-self: flex-start !important;
    }

    .align-self-md-end {
        align-self: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-md-center {
        align-self: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-md-baseline {
        align-self: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-md-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .flex-lg-row {
        flex-direction: row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-column {
        flex-direction: column !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-row-reverse {
        flex-direction: row-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-column-reverse {
        flex-direction: column-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-wrap {
        flex-wrap: wrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-nowrap {
        flex-wrap: nowrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-fill {
        flex: 1 1 auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-grow-0 {
        flex-grow: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-grow-1 {
        flex-grow: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-shrink-0 {
        flex-shrink: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-lg-shrink-1 {
        flex-shrink: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-lg-start {
        justify-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-lg-end {
        justify-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-lg-center {
        justify-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-lg-between {
        justify-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-lg-around {
        justify-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-lg-start {
        align-items: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-lg-end {
        align-items: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-lg-center {
        align-items: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-lg-baseline {
        align-items: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-lg-stretch {
        align-items: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-lg-start {
        align-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-lg-end {
        align-content: flex-end !important;
    }

    .align-content-lg-center {
        align-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-lg-between {
        align-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-lg-around {
        align-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-lg-stretch {
        align-content: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-auto {
        align-self: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-start {
        align-self: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-end {
        align-self: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-center {
        align-self: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-baseline {
        align-self: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-lg-stretch {
        align-self: stretch !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .flex-xl-row {
        flex-direction: row !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-column {
        flex-direction: column !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-row-reverse {
        flex-direction: row-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-column-reverse {
        flex-direction: column-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-wrap {
        flex-wrap: wrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-nowrap {
        flex-wrap: nowrap !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-fill {
        flex: 1 1 auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-grow-0 {
        flex-grow: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-grow-1 {
        flex-grow: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-shrink-0 {
        flex-shrink: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .flex-xl-shrink-1 {
        flex-shrink: 1 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-xl-start {
        justify-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-xl-end {
        justify-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-xl-center {
        justify-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-xl-between {
        justify-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .justify-content-xl-around {
        justify-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-xl-start {
        align-items: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-xl-end {
        align-items: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-xl-center {
        align-items: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-xl-baseline {
        align-items: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-items-xl-stretch {
        align-items: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-start {
        align-content: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-end {
        align-content: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-center {
        align-content: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-between {
        align-content: space-between !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-around {
        align-content: space-around !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-content-xl-stretch {
        align-content: stretch !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-auto {
        align-self: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-start {
        align-self: flex-start !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-end {
        align-self: flex-end !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-center {
        align-self: center !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-baseline {
        align-self: baseline !important;
    }

    /*noinspection CssUnusedSymbol*/
    .align-self-xl-stretch {
        align-self: stretch !important;
    }
}

.float-left {
    float: left !important;
}

.float-right {
    float: right !important;
}

/*noinspection CssUnusedSymbol*/
.float-none {
    float: none !important;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .float-sm-left {
        float: left !important;
    }

    .float-sm-right {
        float: right !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-sm-none {
        float: none !important;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    .float-md-left {
        float: left !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-md-right {
        float: right !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-md-none {
        float: none !important;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .float-lg-left {
        float: left !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-lg-right {
        float: right !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-lg-none {
        float: none !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .float-xl-left {
        float: left !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-xl-right {
        float: right !important;
    }

    /*noinspection CssUnusedSymbol*/
    .float-xl-none {
        float: none !important;
    }
}

/*noinspection CssUnusedSymbol*/
.position-static {
    position: static !important;
}

.position-relative {
    position: relative !important;
}

.position-absolute {
    position: absolute !important;
}

/*noinspection CssUnusedSymbol*/
.position-fixed {
    position: fixed !important;
}

/*noinspection CssUnusedSymbol*/
.position-sticky {
    position: sticky !important;
}

/*noinspection CssUnusedSymbol*/
.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}

/*noinspection CssUnusedSymbol*/
.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030;
}

@supports (position: sticky) {
    /*noinspection CssUnusedSymbol*/
    .sticky-top {
        position: sticky;
        top: 0;
        z-index: 1020;
    }
}

/*noinspection CssUnusedSymbol*/
.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal;
}

/*noinspection CssUnusedSymbol*/
.shadow-sm {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

/*noinspection CssUnusedSymbol*/
.shadow {
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

/*noinspection CssUnusedSymbol*/
.shadow-lg {
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

/*noinspection CssUnusedSymbol*/
.shadow-none {
    box-shadow: none !important;
}

/*noinspection CssUnusedSymbol*/
.w-25 {
    width: 25% !important;
}

.w-50 {
    width: 50% !important;
}

/*noinspection CssUnusedSymbol*/
.w-75 {
    width: 75% !important;
}

/*noinspection CssUnusedSymbol*/
.w-100 {
    width: 100% !important;
}

/*noinspection CssUnusedSymbol*/
.w-auto {
    width: auto !important;
}

/*noinspection CssUnusedSymbol*/
.h-25 {
    height: 25% !important;
}

.h-50 {
    height: 50% !important;
}

/*noinspection CssUnusedSymbol*/
.h-75 {
    height: 75% !important;
}

/*noinspection CssUnusedSymbol*/
.h-100 {
    height: 100% !important;
}

/*noinspection CssUnusedSymbol*/
.h-auto {
    height: auto !important;
}

/*noinspection CssUnusedSymbol*/
.mw-100 {
    max-width: 100% !important;
}

/*noinspection CssUnusedSymbol*/
.mh-100 {
    max-height: 100% !important;
}

.m-0 {
    margin: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.mt-0,
.my-0 {
    margin-top: 0 !important;
}

.mr-0,
.mx-0 {
    margin-right: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.mb-0,
.my-0 {
    margin-bottom: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.ml-0,
.mx-0 {
    margin-left: 0 !important;
}

.m-1 {
    margin: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.mt-1,
.my-1 {
    margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
    margin-right: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.mb-1,
.my-1 {
    margin-bottom: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.ml-1,
.mx-1 {
    margin-left: 0.25rem !important;
}

.m-2 {
    margin: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.mt-2,
.my-2 {
    margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
    margin-right: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.mb-2,
.my-2 {
    margin-bottom: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.ml-2,
.mx-2 {
    margin-left: 0.5rem !important;
}

.m-3 {
    margin: 1rem !important;
}

.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
    margin-right: 1rem !important;
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important;
}

/*noinspection CssUnusedSymbol*/
.ml-3,
.mx-3 {
    margin-left: 1rem !important;
}

.m-4 {
    margin: 1.5rem !important;
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
    margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.ml-4,
.mx-4 {
    margin-left: 1.5rem !important;
}

.m-5 {
    margin: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.mt-5,
.my-5 {
    margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
    margin-right: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.mb-5,
.my-5 {
    margin-bottom: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.ml-5,
.mx-5 {
    margin-left: 3rem !important;
}

.p-0 {
    padding: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.pt-0,
.py-0 {
    padding-top: 0 !important;
}

/*noinspection CssUnusedSymbol*/
.pr-0,
.px-0 {
    padding-right: 0 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.pb-0,
.py-0 {
    padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
    padding-left: 0 !important;
}

.p-1 {
    padding: 0.25rem !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.pt-1,
.py-1 {
    padding-top: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.pr-1,
.px-1 {
    padding-right: 0.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.pb-1,
.py-1 {
    padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
    padding-left: 0.25rem !important;
}

.p-2 {
    padding: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.pt-2,
.py-2 {
    padding-top: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.pr-2,
.px-2 {
    padding-right: 0.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.pb-2,
.py-2 {
    padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
    padding-left: 0.5rem !important;
}

.p-3 {
    padding: 1rem !important;
}

/*noinspection CssUnusedSymbol*/
.pt-3,
.py-3 {
    padding-top: 1rem !important;
}

/*noinspection CssUnusedSymbol*/
.pr-3,
.px-3 {
    padding-right: 1rem !important;
}

/*noinspection CssUnusedSymbol*/
.pb-3,
.py-3 {
    padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
    padding-left: 1rem !important;
}

.p-4 {
    padding: 1.5rem !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.pt-4,
.py-4 {
    padding-top: 1.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.pr-4,
.px-4 {
    padding-right: 1.5rem !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
    padding-left: 1.5rem !important;
}

.p-5 {
    padding: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.pt-5,
.py-5 {
    padding-top: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.pr-5,
.px-5 {
    padding-right: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.pb-5,
.py-5 {
    padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
    padding-left: 3rem !important;
}

/*noinspection CssUnusedSymbol*/
.m-auto {
    margin: auto !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.mt-auto,
.my-auto {
    margin-top: auto !important;
}

/*noinspection CssUnusedSymbol*/
.mr-auto,
.mx-auto {
    margin-right: auto !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.mb-auto,
.my-auto {
    margin-bottom: auto !important;
}

/*noinspection CssUnusedSymbol*/
.ml-auto,
.mx-auto {
    margin-left: auto !important;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .m-sm-0 {
        margin: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-0,
    .my-sm-0 {
        margin-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-sm-0,
    .mx-sm-0 {
        margin-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-0,
    .my-sm-0 {
        margin-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-sm-0,
    .mx-sm-0 {
        margin-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-1 {
        margin: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-1,
    .my-sm-1 {
        margin-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-sm-1,
    .mx-sm-1 {
        margin-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-1,
    .my-sm-1 {
        margin-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-1,
    .mx-sm-1 {
        margin-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-2 {
        margin: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-2,
    .my-sm-2 {
        margin-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-sm-2,
    .mx-sm-2 {
        margin-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-2,
    .my-sm-2 {
        margin-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-2,
    .mx-sm-2 {
        margin-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-3 {
        margin: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-3,
    .my-sm-3 {
        margin-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-sm-3,
    .mx-sm-3 {
        margin-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-3,
    .my-sm-3 {
        margin-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-3,
    .mx-sm-3 {
        margin-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-4 {
        margin: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-4,
    .my-sm-4 {
        margin-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-sm-4,
    .mx-sm-4 {
        margin-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-4,
    .my-sm-4 {
        margin-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-4,
    .mx-sm-4 {
        margin-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-5 {
        margin: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-sm-5,
    .my-sm-5 {
        margin-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-sm-5,
    .mx-sm-5 {
        margin-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-sm-5,
    .my-sm-5 {
        margin-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-5,
    .mx-sm-5 {
        margin-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-0 {
        padding: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-sm-0,
    .py-sm-0 {
        padding-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-sm-0,
    .px-sm-0 {
        padding-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-sm-0,
    .py-sm-0 {
        padding-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-sm-0,
    .px-sm-0 {
        padding-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-1 {
        padding: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-sm-1,
    .py-sm-1 {
        padding-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-sm-1,
    .px-sm-1 {
        padding-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-sm-1,
    .py-sm-1 {
        padding-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-sm-1,
    .px-sm-1 {
        padding-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-2 {
        padding: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-sm-2,
    .py-sm-2 {
        padding-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-sm-2,
    .px-sm-2 {
        padding-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-sm-2,
    .py-sm-2 {
        padding-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-sm-2,
    .px-sm-2 {
        padding-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-3 {
        padding: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-sm-3,
    .py-sm-3 {
        padding-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-sm-3,
    .px-sm-3 {
        padding-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-sm-3,
    .py-sm-3 {
        padding-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-sm-3,
    .px-sm-3 {
        padding-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-4 {
        padding: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-sm-4,
    .py-sm-4 {
        padding-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-sm-4,
    .px-sm-4 {
        padding-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-sm-4,
    .py-sm-4 {
        padding-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-sm-4,
    .px-sm-4 {
        padding-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-sm-5 {
        padding: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-sm-5,
    .py-sm-5 {
        padding-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-sm-5,
    .px-sm-5 {
        padding-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-sm-5,
    .py-sm-5 {
        padding-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-sm-5,
    .px-sm-5 {
        padding-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-sm-auto {
        margin: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mt-sm-auto,
    .my-sm-auto {
        margin-top: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-sm-auto,
    .mx-sm-auto {
        margin-right: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mb-sm-auto,
    .my-sm-auto {
        margin-bottom: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-sm-auto,
    .mx-sm-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-0,
    .my-md-0 {
        margin-top: 0 !important;
    }

    .mr-md-0,
    .mx-md-0 {
        margin-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-0,
    .my-md-0 {
        margin-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-0,
    .mx-md-0 {
        margin-left: 0 !important;
    }

    .m-md-1 {
        margin: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-1,
    .my-md-1 {
        margin-top: 0.25rem !important;
    }

    .mr-md-1,
    .mx-md-1 {
        margin-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-1,
    .my-md-1 {
        margin-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-1,
    .mx-md-1 {
        margin-left: 0.25rem !important;
    }

    .m-md-2 {
        margin: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-2,
    .my-md-2 {
        margin-top: 0.5rem !important;
    }

    .mr-md-2,
    .mx-md-2 {
        margin-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-2,
    .my-md-2 {
        margin-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-2,
    .mx-md-2 {
        margin-left: 0.5rem !important;
    }

    .m-md-3 {
        margin: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-3,
    .my-md-3 {
        margin-top: 1rem !important;
    }

    .mr-md-3,
    .mx-md-3 {
        margin-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-3,
    .my-md-3 {
        margin-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-3,
    .mx-md-3 {
        margin-left: 1rem !important;
    }

    .m-md-4 {
        margin: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-4,
    .my-md-4 {
        margin-top: 1.5rem !important;
    }

    .mr-md-4,
    .mx-md-4 {
        margin-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-4,
    .my-md-4 {
        margin-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-4,
    .mx-md-4 {
        margin-left: 1.5rem !important;
    }

    .m-md-5 {
        margin: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-5,
    .my-md-5 {
        margin-top: 3rem !important;
    }

    .mr-md-5,
    .mx-md-5 {
        margin-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-5,
    .my-md-5 {
        margin-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-5,
    .mx-md-5 {
        margin-left: 3rem !important;
    }

    .p-md-0 {
        padding: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-md-0,
    .py-md-0 {
        padding-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-md-0,
    .px-md-0 {
        padding-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-md-0,
    .py-md-0 {
        padding-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-0,
    .px-md-0 {
        padding-left: 0 !important;
    }

    .p-md-1 {
        padding: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-md-1,
    .py-md-1 {
        padding-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-md-1,
    .px-md-1 {
        padding-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-md-1,
    .py-md-1 {
        padding-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-1,
    .px-md-1 {
        padding-left: 0.25rem !important;
    }

    .p-md-2 {
        padding: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-md-2,
    .py-md-2 {
        padding-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-md-2,
    .px-md-2 {
        padding-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-md-2,
    .py-md-2 {
        padding-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-2,
    .px-md-2 {
        padding-left: 0.5rem !important;
    }

    .p-md-3 {
        padding: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-md-3,
    .py-md-3 {
        padding-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-md-3,
    .px-md-3 {
        padding-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-md-3,
    .py-md-3 {
        padding-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-3,
    .px-md-3 {
        padding-left: 1rem !important;
    }

    .p-md-4 {
        padding: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-md-4,
    .py-md-4 {
        padding-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-md-4,
    .px-md-4 {
        padding-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-md-4,
    .py-md-4 {
        padding-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-4,
    .px-md-4 {
        padding-left: 1.5rem !important;
    }

    .p-md-5 {
        padding: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-md-5,
    .py-md-5 {
        padding-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-md-5,
    .px-md-5 {
        padding-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-md-5,
    .py-md-5 {
        padding-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-md-5,
    .px-md-5 {
        padding-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-md-auto {
        margin: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-md-auto,
    .my-md-auto {
        margin-top: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-md-auto,
    .mx-md-auto {
        margin-right: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-md-auto,
    .my-md-auto {
        margin-bottom: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-md-auto,
    .mx-md-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .m-lg-0 {
        margin: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-0,
    .my-lg-0 {
        margin-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-lg-0,
    .mx-lg-0 {
        margin-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-0,
    .my-lg-0 {
        margin-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-lg-0,
    .mx-lg-0 {
        margin-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-1 {
        margin: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-1,
    .my-lg-1 {
        margin-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-lg-1,
    .mx-lg-1 {
        margin-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-1,
    .my-lg-1 {
        margin-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-lg-1,
    .mx-lg-1 {
        margin-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-2 {
        margin: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-2,
    .my-lg-2 {
        margin-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-lg-2,
    .mx-lg-2 {
        margin-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-2,
    .my-lg-2 {
        margin-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-lg-2,
    .mx-lg-2 {
        margin-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-3 {
        margin: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-3,
    .my-lg-3 {
        margin-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-lg-3,
    .mx-lg-3 {
        margin-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-3,
    .my-lg-3 {
        margin-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-lg-3,
    .mx-lg-3 {
        margin-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-4 {
        margin: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mt-lg-4,
    .my-lg-4 {
        margin-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-lg-4,
    .mx-lg-4 {
        margin-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-4,
    .my-lg-4 {
        margin-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-lg-4,
    .mx-lg-4 {
        margin-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-5 {
        margin: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-5,
    .my-lg-5 {
        margin-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-lg-5,
    .mx-lg-5 {
        margin-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-5,
    .my-lg-5 {
        margin-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-lg-5,
    .mx-lg-5 {
        margin-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-0 {
        padding: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-lg-0,
    .py-lg-0 {
        padding-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-lg-0,
    .px-lg-0 {
        padding-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-lg-0,
    .py-lg-0 {
        padding-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-lg-0,
    .px-lg-0 {
        padding-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-1 {
        padding: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-lg-1,
    .py-lg-1 {
        padding-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-lg-1,
    .px-lg-1 {
        padding-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-lg-1,
    .py-lg-1 {
        padding-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-lg-1,
    .px-lg-1 {
        padding-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-2 {
        padding: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pt-lg-2,
    .py-lg-2 {
        padding-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-lg-2,
    .px-lg-2 {
        padding-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-lg-2,
    .py-lg-2 {
        padding-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-lg-2,
    .px-lg-2 {
        padding-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-3 {
        padding: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-lg-3,
    .py-lg-3 {
        padding-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-lg-3,
    .px-lg-3 {
        padding-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-lg-3,
    .py-lg-3 {
        padding-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-lg-3,
    .px-lg-3 {
        padding-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-4 {
        padding: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-lg-4,
    .py-lg-4 {
        padding-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-lg-4,
    .px-lg-4 {
        padding-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-lg-4,
    .py-lg-4 {
        padding-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-lg-4,
    .px-lg-4 {
        padding-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-lg-5 {
        padding: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-lg-5,
    .py-lg-5 {
        padding-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-lg-5,
    .px-lg-5 {
        padding-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-lg-5,
    .py-lg-5 {
        padding-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-lg-5,
    .px-lg-5 {
        padding-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-lg-auto {
        margin: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-lg-auto,
    .my-lg-auto {
        margin-top: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-lg-auto,
    .mx-lg-auto {
        margin-right: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-lg-auto,
    .my-lg-auto {
        margin-bottom: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-lg-auto,
    .mx-lg-auto {
        margin-left: auto !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .m-xl-0 {
        margin: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-0,
    .my-xl-0 {
        margin-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-xl-0,
    .mx-xl-0 {
        margin-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-0,
    .my-xl-0 {
        margin-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-xl-0,
    .mx-xl-0 {
        margin-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-1 {
        margin: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-1,
    .my-xl-1 {
        margin-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-xl-1,
    .mx-xl-1 {
        margin-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-1,
    .my-xl-1 {
        margin-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-xl-1,
    .mx-xl-1 {
        margin-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-2 {
        margin: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-2,
    .my-xl-2 {
        margin-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-xl-2,
    .mx-xl-2 {
        margin-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-2,
    .my-xl-2 {
        margin-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-xl-2,
    .mx-xl-2 {
        margin-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-3 {
        margin: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-3,
    .my-xl-3 {
        margin-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-xl-3,
    .mx-xl-3 {
        margin-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-3,
    .my-xl-3 {
        margin-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-xl-3,
    .mx-xl-3 {
        margin-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-4 {
        margin: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-4,
    .my-xl-4 {
        margin-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-xl-4,
    .mx-xl-4 {
        margin-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mb-xl-4,
    .my-xl-4 {
        margin-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-xl-4,
    .mx-xl-4 {
        margin-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-5 {
        margin: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mt-xl-5,
    .my-xl-5 {
        margin-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mr-xl-5,
    .mx-xl-5 {
        margin-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-5,
    .my-xl-5 {
        margin-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .ml-xl-5,
    .mx-xl-5 {
        margin-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-0 {
        padding: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-0,
    .py-xl-0 {
        padding-top: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-xl-0,
    .px-xl-0 {
        padding-right: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-xl-0,
    .py-xl-0 {
        padding-bottom: 0 !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-xl-0,
    .px-xl-0 {
        padding-left: 0 !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-1 {
        padding: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-1,
    .py-xl-1 {
        padding-top: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-xl-1,
    .px-xl-1 {
        padding-right: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-xl-1,
    .py-xl-1 {
        padding-bottom: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-xl-1,
    .px-xl-1 {
        padding-left: 0.25rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-2 {
        padding: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-2,
    .py-xl-2 {
        padding-top: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-xl-2,
    .px-xl-2 {
        padding-right: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-xl-2,
    .py-xl-2 {
        padding-bottom: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-xl-2,
    .px-xl-2 {
        padding-left: 0.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-3 {
        padding: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-3,
    .py-xl-3 {
        padding-top: 1rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pr-xl-3,
    .px-xl-3 {
        padding-right: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-xl-3,
    .py-xl-3 {
        padding-bottom: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-xl-3,
    .px-xl-3 {
        padding-left: 1rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-4 {
        padding: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-4,
    .py-xl-4 {
        padding-top: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-xl-4,
    .px-xl-4 {
        padding-right: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pb-xl-4,
    .py-xl-4 {
        padding-bottom: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pl-xl-4,
    .px-xl-4 {
        padding-left: 1.5rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .p-xl-5 {
        padding: 3rem !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .pt-xl-5,
    .py-xl-5 {
        padding-top: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pr-xl-5,
    .px-xl-5 {
        padding-right: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pb-xl-5,
    .py-xl-5 {
        padding-bottom: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .pl-xl-5,
    .px-xl-5 {
        padding-left: 3rem !important;
    }

    /*noinspection CssUnusedSymbol*/
    .m-xl-auto {
        margin: auto !important;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    .mt-xl-auto,
    .my-xl-auto {
        margin-top: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mr-xl-auto,
    .mx-xl-auto {
        margin-right: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .mb-xl-auto,
    .my-xl-auto {
        margin-bottom: auto !important;
    }

    /*noinspection CssUnusedSymbol*/
    .ml-xl-auto,
    .mx-xl-auto {
        margin-left: auto !important;
    }
}

/*noinspection CssUnusedSymbol*/
.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/*noinspection CssUnusedSymbol*/
.text-justify {
    text-align: justify !important;
}

/*noinspection CssUnusedSymbol*/
.text-nowrap {
    white-space: nowrap !important;
}

/*noinspection CssUnusedSymbol*/
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .text-lg-left {
        text-align: left !important;
    }

    /*noinspection CssUnusedSymbol*/
    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    .text-xl-left {
        text-align: left !important;
    }

    /*noinspection CssUnusedSymbol*/
    .text-xl-right {
        text-align: right !important;
    }

    .text-xl-center {
        text-align: center !important;
    }
}

.text-lowercase {
    text-transform: lowercase !important;
}

/*noinspection CssUnusedSymbol*/
.text-uppercase {
    text-transform: uppercase !important;
}

/*noinspection CssUnusedSymbol*/
.text-capitalize {
    text-transform: capitalize !important;
}

/*noinspection CssUnusedSymbol*/
.font-weight-light {
    font-weight: 300 !important;
}

/*noinspection CssUnusedSymbol*/
.font-weight-normal {
    font-weight: 400 !important;
}

/*noinspection CssUnusedSymbol*/
.font-weight-bold {
    font-weight: 700 !important;
}

/*noinspection CssUnusedSymbol*/
.font-italic {
    font-style: italic !important;
}

.text-white {
    color: var(--white) !important;
}

.text-primary {
    color: var(--primary) !important;
}

a.text-primary:hover, a.text-primary:focus {
    color: #1985ac !important;
}

.text-secondary {
    color: var(--secondary) !important;
}

a.text-secondary:hover, a.text-secondary:focus {
    color: #acb5bc !important;
}

.text-success {
    color: var(--success) !important;
}

a.text-success:hover, a.text-success:focus {
    color: #3a9d5d !important;
}

.text-info {
    color: var(--info) !important;
}

a.text-info:hover, a.text-info:focus {
    color: #39b2d5 !important;
}

.text-warning {
    color: var(--warning) !important;
}

a.text-warning:hover, a.text-warning:focus {
    color: #d39e00 !important;
}

/*noinspection CssUnusedSymbol*/
.text-danger {
    color: var(--danger) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.text-danger:hover, a.text-danger:focus {
    color: #f63c3a !important;
}

.text-light {
    color: var(--gray-100) !important;
}

a.text-light:hover, a.text-light:focus {
    color: #d1dbe1 !important;
}

/*noinspection CssUnusedSymbol*/
.text-dark {
    color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol*/
a.text-dark:hover, a.text-dark:focus {
    color: #181b1e !important;
}

.text-body {
    color: var(--gray-900) !important;
}

.text-muted {
    color: var(--gray-600) !important;
}

.text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

/*noinspection CssUnusedSymbol*/
.text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

/*noinspection CssUnusedSymbol*/
.text-hide {
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.visible {
    visibility: visible !important;
}

/*noinspection CssUnusedSymbol*/
.invisible {
    visibility: hidden !important;
}

@media print {
    *,
    *::before,
    *::after {
        text-shadow: none !important;
        box-shadow: none !important;
    }

    a:not(.btn) {
        text-decoration: underline;
    }

    abbr[title]::after {
        content: " (" attr(title) ")";
    }

    pre {
        white-space: pre-wrap !important;
    }

    pre,
    blockquote {
        border: 1px solid var(--gray-500);
        page-break-inside: avoid;
    }

    thead {
        display: table-header-group;
    }

    tr,
    img {
        page-break-inside: avoid;
    }

    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }

    h2,
    h3 {
        page-break-after: avoid;
    }

    @page {
        size: a3;
    }

    body {
        min-width: 992px !important;
    }

    .container {
        min-width: 992px !important;
    }

    .navbar {
        display: none;
    }

    .badge {
        border: 1px solid #000;
    }

    .table {
        border-collapse: collapse !important;
    }

    .table td,
    .table th {
        background-color: var(--white) !important;
    }

    .table-bordered th,
    .table-bordered td {
        border: 1px solid var(--secondary) !important;
    }

    .table-dark {
        color: inherit;
    }

    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
        border-color: var(--secondary);
    }

    .table .thead-dark th {
        color: inherit;
        border-color: var(--secondary);
    }
}

.animated {
    animation-duration: 1s;
}

/*noinspection CssUnusedSymbol*/
.animated.infinite {
    animation-iteration-count: infinite;
}

/*noinspection CssUnusedSymbol*/
.animated.hinge {
    animation-duration: 2s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fadeIn {
    animation-name: fadeIn;
}

/*noinspection CssUnusedSymbol*/
.ps {
    overflow: hidden !important;
    -ms-touch-action: auto;
    touch-action: auto;
    -ms-overflow-style: none;
    overflow-anchor: none;
}

/*noinspection CssUnusedSymbol*/
.ps__rail-x {
    position: absolute;
    bottom: 0;
    display: none;
    height: 15px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
}

/*noinspection CssUnusedSymbol*/
.ps__rail-y {
    position: absolute;
    right: 0;
    display: none;
    width: 15px;
    opacity: 0;
    transition: background-color .2s linear, opacity .2s linear;
}

/*noinspection CssUnusedSymbol*/
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
    display: block;
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
    opacity: .6;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.ps__rail-x:hover,
.ps__rail-y:hover,
.ps__rail-x:focus,
.ps__rail-y:focus {
    background-color: #eee;
    opacity: .9;
}

/*
 * Scrollbar thumb styles
 */
/*noinspection CssUnusedSymbol*/
.ps__thumb-x {
    position: absolute;
    bottom: 2px;
    height: 6px;
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, height .2s ease-in-out;
}

/*noinspection CssUnusedSymbol*/
.ps__thumb-y {
    position: absolute;
    right: 2px;
    width: 6px;
    background-color: #aaa;
    border-radius: 6px;
    transition: background-color .2s linear, width .2s ease-in-out;
}

/*noinspection CssUnusedSymbol*/
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x {
    height: 11px;
    background-color: #999;
}

/*noinspection CssUnusedSymbol*/
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y {
    width: 11px;
    background-color: #999;
}

@supports (-ms-overflow-style: none) {
    /*noinspection CssUnusedSymbol*/
    .ps {
        overflow: auto !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /*noinspection CssUnusedSymbol*/
    .ps {
        overflow: auto !important;
    }
}

/*noinspection CssUnusedSymbol*/
.aside-menu {
    z-index: 1019;
    width: 250px;
    color: var(--gray-800);
    background: var(--white);
    border-left: 1px solid var(--secondary);
}

.aside-menu .nav-tabs {
    border-color: var(--secondary);
}

.aside-menu .nav-tabs .nav-link {
    padding: 0.75rem 1rem;
    color: var(--gray-900);
    border-top: 0;
    border-radius: 0;
}

.aside-menu .nav-tabs .nav-link.active {
    color: var(--primary);
    border-right-color: var(--secondary);
    border-left-color: var(--secondary);
}

.aside-menu .nav-tabs .nav-item:first-child .nav-link {
    border-left: 0;
}

/*noinspection CssUnusedSymbol*/
.aside-menu .tab-content {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    border: 0;
    border-top: 1px solid var(--secondary);
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

/*noinspection CssUnusedSymbol*/
.aside-menu .tab-content::-webkit-scrollbar {
    width: 10px;
    margin-left: -10px;
    appearance: none;
}

/*noinspection CssUnusedSymbol*/
.aside-menu .tab-content::-webkit-scrollbar-track {
    background-color: white;
    border-right: 1px solid #f2f2f2;
    border-left: 1px solid #f2f2f2;
}

/*noinspection CssUnusedSymbol*/
.aside-menu .tab-content::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #e6e6e6;
    background-clip: content-box;
    border-color: transparent;
    border-style: solid;
    border-width: 1px 2px;
}

/*noinspection CssUnusedSymbol*/
.aside-menu .tab-content .tab-pane {
    padding: 0;
}

/*noinspection CssUnusedSymbol*/
.avatar {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 36px;
}

/*noinspection CssUnusedSymbol*/
.avatar .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid var(--white);
    border-radius: 50em;
}

.avatar > img {
    vertical-align: initial;
}

/*noinspection CssUnusedSymbol*/
.avatar-lg {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 72px;
}

/*noinspection CssUnusedSymbol*/
.avatar-lg .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid var(--white);
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.avatar-sm {
    position: relative;
    display: inline-block;
    width: 24px;
    height: 24px;
}

/*noinspection CssUnusedSymbol*/
.avatar-sm .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid var(--white);
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.avatar-xs {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
}

/*noinspection CssUnusedSymbol*/
.avatar-xs .avatar-status {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 8px;
    height: 8px;
    border: 1px solid var(--white);
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.avatars-stack .avatar {
    margin-right: -18px;
    transition: margin-right 0.25s;
}

/*noinspection CssUnusedSymbol*/
.avatars-stack .avatar:hover {
    margin-right: 0;
}

/*noinspection CssUnusedSymbol*/
.avatars-stack .avatar-lg {
    margin-right: -36px;
}

/*noinspection CssUnusedSymbol*/
.avatars-stack .avatar-sm {
    margin-right: -12px;
}

/*noinspection CssUnusedSymbol*/
.avatars-stack .avatar-xs {
    margin-right: -10px;
}

/*noinspection CssUnusedSymbol*/
.badge-pill {
    border-radius: 10rem;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-menu {
    margin-left: auto;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-menu::before {
    display: none;
}

.breadcrumb-menu .btn-group {
    vertical-align: top;
}

.breadcrumb-menu .btn {
    padding: 0 0.75rem;
    color: var(--texts);
    vertical-align: top;
    border: 0;
}

.breadcrumb-menu .btn:hover, .breadcrumb-menu .btn.active {
    color: var(--gray-900);
    background: transparent;
}

.breadcrumb-menu .open .btn {
    color: var(--gray-900);
    background: transparent;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-menu .dropdown-menu {
    min-width: 180px;
    line-height: 1.5;
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .breadcrumb-menu {
    margin-right: auto;
    margin-left: initial;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb {
    position: relative;
    border-radius: 0;
    border-bottom: 1px solid var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.brand-card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    margin-bottom: 1.5rem;
    word-wrap: break-word;
    background-color: var(--white);
    background-clip: border-box;
    border: 1px solid var(--secondary);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.brand-card-header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    border-radius: 0.25rem 0.25rem 0 0;
}

.brand-card-header i {
    font-size: 2rem;
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.brand-card-header .chart-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/*noinspection CssUnusedSymbol*/
.brand-card-body {
    display: flex;
    flex-direction: row;
    padding: 0.75rem 0;
    text-align: center;
}

.brand-card-body > * {
    flex: 1;
    padding: 0.1875rem 0;
}

.brand-card-body > *:not(:last-child) {
    border-right: 1px solid var(--secondary);
}

*[dir="rtl"] .brand-card-body > *:not(:last-child) {
    border-right: 0;
    border-left: 1px solid var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.btn-brand {
    border: 0;
}

.btn-brand i {
    display: inline-block;
    width: 2.0625rem;
    margin: -0.375rem -0.75rem;
    line-height: 2.0625rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
}

.btn-brand i + span {
    margin-left: 1.5rem;
}

.btn-brand.btn-lg i, .btn-group-lg > .btn-brand.btn i {
    width: 2.64063rem;
    margin: -0.5rem -1rem;
    line-height: 2.64063rem;
    border-radius: 0.3rem;
}

.btn-brand.btn-lg i + span, .btn-group-lg > .btn-brand.btn i + span {
    margin-left: 2rem;
}

.btn-brand.btn-sm i, .btn-group-sm > .btn-brand.btn i {
    width: 1.64844rem;
    margin: -0.25rem -0.5rem;
    line-height: 1.64844rem;
    border-radius: 0.2rem;
}

.btn-brand.btn-sm i + span, .btn-group-sm > .btn-brand.btn i + span {
    margin-left: 1rem;
}

.btn-brand.btn-square i {
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.btn-facebook {
    color: var(--white);
    background-color: #3b5998;
    border-color: #3b5998;
}

/*noinspection CssUnusedSymbol*/
.btn-facebook:hover {
    color: var(--white);
    background-color: #30497c;
    border-color: #2d4373;
}

/*noinspection CssUnusedSymbol*/
.btn-facebook:focus, .btn-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-facebook.disabled, .btn-facebook:disabled {
    color: var(--white);
    background-color: #3b5998;
    border-color: #3b5998;
}

/*noinspection CssUnusedSymbol*/
.btn-facebook:not(:disabled):not(.disabled):active, .btn-facebook:not(:disabled):not(.disabled).active,
.show > .btn-facebook.dropdown-toggle {
    color: var(--white);
    background-color: #2d4373;
    border-color: #293e6a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-facebook:not(:disabled):not(.disabled):active:focus, .btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-twitter {
    color: var(--white);
    background-color: #00aced;
    border-color: #00aced;
}

/*noinspection CssUnusedSymbol*/
.btn-twitter:hover {
    color: var(--white);
    background-color: #0090c7;
    border-color: #0087ba;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-twitter:focus, .btn-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-twitter.disabled, .btn-twitter:disabled {
    color: var(--white);
    background-color: #00aced;
    border-color: #00aced;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-twitter:not(:disabled):not(.disabled):active, .btn-twitter:not(:disabled):not(.disabled).active,
.show > .btn-twitter.dropdown-toggle {
    color: var(--white);
    background-color: #0087ba;
    border-color: #007ead;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-twitter:not(:disabled):not(.disabled):active:focus, .btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-linkedin {
    color: var(--white);
    background-color: #4875b4;
    border-color: #4875b4;
}

/*noinspection CssUnusedSymbol*/
.btn-linkedin:hover {
    color: var(--white);
    background-color: #3d6399;
    border-color: #395d90;
}

/*noinspection CssUnusedSymbol*/
.btn-linkedin:focus, .btn-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 117, 180, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-linkedin.disabled, .btn-linkedin:disabled {
    color: var(--white);
    background-color: #4875b4;
    border-color: #4875b4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-linkedin:not(:disabled):not(.disabled):active, .btn-linkedin:not(:disabled):not(.disabled).active,
.show > .btn-linkedin.dropdown-toggle {
    color: var(--white);
    background-color: #395d90;
    border-color: #365786;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-linkedin:not(:disabled):not(.disabled):active:focus, .btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .btn-linkedin.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 117, 180, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-google-plus {
    color: var(--white);
    background-color: #d34836;
    border-color: #d34836;
}

/*noinspection CssUnusedSymbol*/
.btn-google-plus:hover {
    color: var(--white);
    background-color: #ba3929;
    border-color: #b03626;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-google-plus:focus, .btn-google-plus.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 72, 54, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-google-plus.disabled, .btn-google-plus:disabled {
    color: var(--white);
    background-color: #d34836;
    border-color: #d34836;
}

/*noinspection CssUnusedSymbol*/
.btn-google-plus:not(:disabled):not(.disabled):active, .btn-google-plus:not(:disabled):not(.disabled).active,
.show > .btn-google-plus.dropdown-toggle {
    color: var(--white);
    background-color: #b03626;
    border-color: #a53324;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-google-plus:not(:disabled):not(.disabled):active:focus, .btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .btn-google-plus.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 72, 54, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-flickr {
    color: var(--white);
    background-color: #ff0084;
    border-color: #ff0084;
}

/*noinspection CssUnusedSymbol*/
.btn-flickr:hover {
    color: var(--white);
    background-color: #d90070;
    border-color: #cc006a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-flickr:focus, .btn-flickr.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 132, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-flickr.disabled, .btn-flickr:disabled {
    color: var(--white);
    background-color: #ff0084;
    border-color: #ff0084;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-flickr:not(:disabled):not(.disabled):active, .btn-flickr:not(:disabled):not(.disabled).active,
.show > .btn-flickr.dropdown-toggle {
    color: var(--white);
    background-color: #cc006a;
    border-color: #bf0063;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-flickr:not(:disabled):not(.disabled):active:focus, .btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .btn-flickr.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 132, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-tumblr {
    color: var(--white);
    background-color: #32506d;
    border-color: #32506d;
}

/*noinspection CssUnusedSymbol*/
.btn-tumblr:hover {
    color: var(--white);
    background-color: #263d53;
    border-color: #22364a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-tumblr:focus, .btn-tumblr.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 80, 109, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-tumblr.disabled, .btn-tumblr:disabled {
    color: var(--white);
    background-color: #32506d;
    border-color: #32506d;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-tumblr:not(:disabled):not(.disabled):active, .btn-tumblr:not(:disabled):not(.disabled).active,
.show > .btn-tumblr.dropdown-toggle {
    color: var(--white);
    background-color: #22364a;
    border-color: #1e3041;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-tumblr:not(:disabled):not(.disabled):active:focus, .btn-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .btn-tumblr.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 80, 109, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-xing {
    color: var(--white);
    background-color: #026466;
    border-color: #026466;
}

/*noinspection CssUnusedSymbol*/
.btn-xing:hover {
    color: var(--white);
    background-color: #013f40;
    border-color: #013334;
}

/*noinspection CssUnusedSymbol*/
.btn-xing:focus, .btn-xing.focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 100, 102, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-xing.disabled, .btn-xing:disabled {
    color: var(--white);
    background-color: #026466;
    border-color: #026466;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-xing:not(:disabled):not(.disabled):active, .btn-xing:not(:disabled):not(.disabled).active,
.show > .btn-xing.dropdown-toggle {
    color: var(--white);
    background-color: #013334;
    border-color: #012727;
}

/*noinspection CssUnusedSymbol*/
.btn-xing:not(:disabled):not(.disabled):active:focus, .btn-xing:not(:disabled):not(.disabled).active:focus,
.show > .btn-xing.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 100, 102, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-github {
    color: var(--white);
    background-color: #4183c4;
    border-color: #4183c4;
}

/*noinspection CssUnusedSymbol*/
.btn-github:hover {
    color: var(--white);
    background-color: #3570aa;
    border-color: #3269a0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-github:focus, .btn-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 131, 196, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-github.disabled, .btn-github:disabled {
    color: var(--white);
    background-color: #4183c4;
    border-color: #4183c4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-github:not(:disabled):not(.disabled):active, .btn-github:not(:disabled):not(.disabled).active,
.show > .btn-github.dropdown-toggle {
    color: var(--white);
    background-color: #3269a0;
    border-color: #2f6397;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-github:not(:disabled):not(.disabled):active:focus, .btn-github:not(:disabled):not(.disabled).active:focus,
.show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 131, 196, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-html5 {
    color: var(--white);
    background-color: #e34f26;
    border-color: #e34f26;
}

/*noinspection CssUnusedSymbol*/
.btn-html5:hover {
    color: var(--white);
    background-color: #c9401a;
    border-color: #be3c18;
}

/*noinspection CssUnusedSymbol*/
.btn-html5:focus, .btn-html5.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 79, 38, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-html5.disabled, .btn-html5:disabled {
    color: var(--white);
    background-color: #e34f26;
    border-color: #e34f26;
}

/*noinspection CssUnusedSymbol*/
.btn-html5:not(:disabled):not(.disabled):active, .btn-html5:not(:disabled):not(.disabled).active,
.show > .btn-html5.dropdown-toggle {
    color: var(--white);
    background-color: #be3c18;
    border-color: #b23917;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-html5:not(:disabled):not(.disabled):active:focus, .btn-html5:not(:disabled):not(.disabled).active:focus,
.show > .btn-html5.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 79, 38, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-openid {
    color: var(--gray-900);
    background-color: #f78c40;
    border-color: #f78c40;
}

/*noinspection CssUnusedSymbol*/
.btn-openid:hover {
    color: var(--white);
    background-color: #f5761b;
    border-color: #f56f0f;
}

/*noinspection CssUnusedSymbol*/
.btn-openid:focus, .btn-openid.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 140, 64, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-openid.disabled, .btn-openid:disabled {
    color: var(--gray-900);
    background-color: #f78c40;
    border-color: #f78c40;
}

/*noinspection CssUnusedSymbol*/
.btn-openid:not(:disabled):not(.disabled):active, .btn-openid:not(:disabled):not(.disabled).active,
.show > .btn-openid.dropdown-toggle {
    color: var(--white);
    background-color: #f56f0f;
    border-color: #ed680a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-openid:not(:disabled):not(.disabled):active:focus, .btn-openid:not(:disabled):not(.disabled).active:focus,
.show > .btn-openid.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 140, 64, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-stack-overflow {
    color: var(--white);
    background-color: #fe7a15;
    border-color: #fe7a15;
}

/*noinspection CssUnusedSymbol*/
.btn-stack-overflow:hover {
    color: var(--white);
    background-color: #ec6701;
    border-color: #df6101;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-stack-overflow:focus, .btn-stack-overflow.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-stack-overflow.disabled, .btn-stack-overflow:disabled {
    color: var(--white);
    background-color: #fe7a15;
    border-color: #fe7a15;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-stack-overflow:not(:disabled):not(.disabled):active, .btn-stack-overflow:not(:disabled):not(.disabled).active,
.show > .btn-stack-overflow.dropdown-toggle {
    color: var(--white);
    background-color: #df6101;
    border-color: #d25c01;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-stack-overflow:not(:disabled):not(.disabled):active:focus, .btn-stack-overflow:not(:disabled):not(.disabled).active:focus,
.show > .btn-stack-overflow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-youtube {
    color: var(--white);
    background-color: #b00;
    border-color: #b00;
}

/*noinspection CssUnusedSymbol*/
.btn-youtube:hover {
    color: var(--white);
    background-color: #950000;
    border-color: #880000;
}

/*noinspection CssUnusedSymbol*/
.btn-youtube:focus, .btn-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 0, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-youtube.disabled, .btn-youtube:disabled {
    color: var(--white);
    background-color: #b00;
    border-color: #b00;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-youtube:not(:disabled):not(.disabled):active, .btn-youtube:not(:disabled):not(.disabled).active,
.show > .btn-youtube.dropdown-toggle {
    color: var(--white);
    background-color: #880000;
    border-color: #7b0000;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-youtube:not(:disabled):not(.disabled):active:focus, .btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 0, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-css3 {
    color: var(--white);
    background-color: #0170ba;
    border-color: #0170ba;
}

/*noinspection CssUnusedSymbol*/
.btn-css3:hover {
    color: var(--white);
    background-color: #015994;
    border-color: #015187;
}

/*noinspection CssUnusedSymbol*/
.btn-css3:focus, .btn-css3.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 112, 186, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-css3.disabled, .btn-css3:disabled {
    color: var(--white);
    background-color: #0170ba;
    border-color: #0170ba;
}

/*noinspection CssUnusedSymbol*/
.btn-css3:not(:disabled):not(.disabled):active, .btn-css3:not(:disabled):not(.disabled).active,
.show > .btn-css3.dropdown-toggle {
    color: var(--white);
    background-color: #015187;
    border-color: #014a7b;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-css3:not(:disabled):not(.disabled):active:focus, .btn-css3:not(:disabled):not(.disabled).active:focus,
.show > .btn-css3.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 112, 186, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-dribbble {
    color: var(--white);
    background-color: #ea4c89;
    border-color: #ea4c89;
}

/*noinspection CssUnusedSymbol*/
.btn-dribbble:hover {
    color: var(--white);
    background-color: #e62a72;
    border-color: #e51e6b;
}

/*noinspection CssUnusedSymbol*/
.btn-dribbble:focus, .btn-dribbble.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-dribbble.disabled, .btn-dribbble:disabled {
    color: var(--white);
    background-color: #ea4c89;
    border-color: #ea4c89;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-dribbble:not(:disabled):not(.disabled):active, .btn-dribbble:not(:disabled):not(.disabled).active,
.show > .btn-dribbble.dropdown-toggle {
    color: var(--white);
    background-color: #e51e6b;
    border-color: #dc1a65;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-dribbble:not(:disabled):not(.disabled):active:focus, .btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-instagram {
    color: var(--white);
    background-color: #517fa4;
    border-color: #517fa4;
}

/*noinspection CssUnusedSymbol*/
.btn-instagram:hover {
    color: var(--white);
    background-color: #446b8a;
    border-color: #406582;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-instagram:focus, .btn-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 127, 164, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-instagram.disabled, .btn-instagram:disabled {
    color: var(--white);
    background-color: #517fa4;
    border-color: #517fa4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-instagram:not(:disabled):not(.disabled):active, .btn-instagram:not(:disabled):not(.disabled).active,
.show > .btn-instagram.dropdown-toggle {
    color: var(--white);
    background-color: #406582;
    border-color: #3c5e79;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-instagram:not(:disabled):not(.disabled):active:focus, .btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 127, 164, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-pinterest {
    color: var(--white);
    background-color: #cb2027;
    border-color: #cb2027;
}

/*noinspection CssUnusedSymbol*/
.btn-pinterest:hover {
    color: var(--white);
    background-color: #aa1b21;
    border-color: #9f191f;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-pinterest:focus, .btn-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 32, 39, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-pinterest.disabled, .btn-pinterest:disabled {
    color: var(--white);
    background-color: #cb2027;
    border-color: #cb2027;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-pinterest:not(:disabled):not(.disabled):active, .btn-pinterest:not(:disabled):not(.disabled).active,
.show > .btn-pinterest.dropdown-toggle {
    color: var(--white);
    background-color: #9f191f;
    border-color: #94171c;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-pinterest:not(:disabled):not(.disabled):active:focus, .btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 32, 39, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-vk {
    color: var(--white);
    background-color: #45668e;
    border-color: #45668e;
}

/*noinspection CssUnusedSymbol*/
.btn-vk:hover {
    color: var(--white);
    background-color: #385474;
    border-color: #344d6c;
}

/*noinspection CssUnusedSymbol*/
.btn-vk:focus, .btn-vk.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 102, 142, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-vk.disabled, .btn-vk:disabled {
    color: var(--white);
    background-color: #45668e;
    border-color: #45668e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-vk:not(:disabled):not(.disabled):active, .btn-vk:not(:disabled):not(.disabled).active,
.show > .btn-vk.dropdown-toggle {
    color: var(--white);
    background-color: #344d6c;
    border-color: #304763;
}

/*noinspection CssUnusedSymbol*/
.btn-vk:not(:disabled):not(.disabled):active:focus, .btn-vk:not(:disabled):not(.disabled).active:focus,
.show > .btn-vk.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 102, 142, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-yahoo {
    color: var(--white);
    background-color: #400191;
    border-color: #400191;
}

/*noinspection CssUnusedSymbol*/
.btn-yahoo:hover {
    color: var(--white);
    background-color: #2f016b;
    border-color: #2a015e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-yahoo:focus, .btn-yahoo.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 1, 145, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-yahoo.disabled, .btn-yahoo:disabled {
    color: var(--white);
    background-color: #400191;
    border-color: #400191;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-yahoo:not(:disabled):not(.disabled):active, .btn-yahoo:not(:disabled):not(.disabled).active,
.show > .btn-yahoo.dropdown-toggle {
    color: var(--white);
    background-color: #2a015e;
    border-color: #240152;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-yahoo:not(:disabled):not(.disabled):active:focus, .btn-yahoo:not(:disabled):not(.disabled).active:focus,
.show > .btn-yahoo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 1, 145, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-behance {
    color: var(--white);
    background-color: #1769ff;
    border-color: #1769ff;
}

/*noinspection CssUnusedSymbol*/
.btn-behance:hover {
    color: var(--white);
    background-color: #0055f0;
    border-color: #0050e3;
}

/*noinspection CssUnusedSymbol*/
.btn-behance:focus, .btn-behance.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-behance.disabled, .btn-behance:disabled {
    color: var(--white);
    background-color: #1769ff;
    border-color: #1769ff;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-behance:not(:disabled):not(.disabled):active, .btn-behance:not(:disabled):not(.disabled).active,
.show > .btn-behance.dropdown-toggle {
    color: var(--white);
    background-color: #0050e3;
    border-color: #004cd6;
}

/*noinspection CssUnusedSymbol*/
.btn-behance:not(:disabled):not(.disabled):active:focus, .btn-behance:not(:disabled):not(.disabled).active:focus,
.show > .btn-behance.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-dropbox {
    color: var(--white);
    background-color: #007ee5;
    border-color: #007ee5;
}

/*noinspection CssUnusedSymbol*/
.btn-dropbox:hover {
    color: var(--white);
    background-color: #0069bf;
    border-color: #0062b2;
}

/*noinspection CssUnusedSymbol*/
.btn-dropbox:focus, .btn-dropbox.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 229, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-dropbox.disabled, .btn-dropbox:disabled {
    color: var(--white);
    background-color: #007ee5;
    border-color: #007ee5;
}

/*noinspection CssUnusedSymbol*/
.btn-dropbox:not(:disabled):not(.disabled):active, .btn-dropbox:not(:disabled):not(.disabled).active,
.show > .btn-dropbox.dropdown-toggle {
    color: var(--white);
    background-color: #0062b2;
    border-color: #005ba5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-dropbox:not(:disabled):not(.disabled):active:focus, .btn-dropbox:not(:disabled):not(.disabled).active:focus,
.show > .btn-dropbox.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 229, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-reddit {
    color: var(--white);
    background-color: #ff4500;
    border-color: #ff4500;
}

/*noinspection CssUnusedSymbol*/
.btn-reddit:hover {
    color: var(--white);
    background-color: #d93b00;
    border-color: #cc3700;
}

/*noinspection CssUnusedSymbol*/
.btn-reddit:focus, .btn-reddit.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-reddit.disabled, .btn-reddit:disabled {
    color: var(--white);
    background-color: #ff4500;
    border-color: #ff4500;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-reddit:not(:disabled):not(.disabled):active, .btn-reddit:not(:disabled):not(.disabled).active,
.show > .btn-reddit.dropdown-toggle {
    color: var(--white);
    background-color: #cc3700;
    border-color: #bf3400;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-reddit:not(:disabled):not(.disabled):active:focus, .btn-reddit:not(:disabled):not(.disabled).active:focus,
.show > .btn-reddit.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-spotify {
    color: var(--white);
    background-color: #7ab800;
    border-color: #7ab800;
}

/*noinspection CssUnusedSymbol*/
.btn-spotify:hover {
    color: var(--white);
    background-color: #619200;
    border-color: #588500;
}

/*noinspection CssUnusedSymbol*/
.btn-spotify:focus, .btn-spotify.focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 184, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-spotify.disabled, .btn-spotify:disabled {
    color: var(--white);
    background-color: #7ab800;
    border-color: #7ab800;
}

/*noinspection CssUnusedSymbol*/
.btn-spotify:not(:disabled):not(.disabled):active, .btn-spotify:not(:disabled):not(.disabled).active,
.show > .btn-spotify.dropdown-toggle {
    color: var(--white);
    background-color: #588500;
    border-color: #507800;
}

/*noinspection CssUnusedSymbol*/
.btn-spotify:not(:disabled):not(.disabled):active:focus, .btn-spotify:not(:disabled):not(.disabled).active:focus,
.show > .btn-spotify.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 184, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-vine {
    color: var(--white);
    background-color: #00bf8f;
    border-color: #00bf8f;
}

/*noinspection CssUnusedSymbol*/
.btn-vine:hover {
    color: var(--white);
    background-color: #009972;
    border-color: #008c69;
}

/*noinspection CssUnusedSymbol*/
.btn-vine:focus, .btn-vine.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 191, 143, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-vine.disabled, .btn-vine:disabled {
    color: var(--white);
    background-color: #00bf8f;
    border-color: #00bf8f;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-vine:not(:disabled):not(.disabled):active, .btn-vine:not(:disabled):not(.disabled).active,
.show > .btn-vine.dropdown-toggle {
    color: var(--white);
    background-color: #008c69;
    border-color: #007f5f;
}

/*noinspection CssUnusedSymbol*/
.btn-vine:not(:disabled):not(.disabled):active:focus, .btn-vine:not(:disabled):not(.disabled).active:focus,
.show > .btn-vine.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 191, 143, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-foursquare {
    color: var(--white);
    background-color: #1073af;
    border-color: #1073af;
}

/*noinspection CssUnusedSymbol*/
.btn-foursquare:hover {
    color: var(--white);
    background-color: #0d5c8c;
    border-color: #0c5480;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-foursquare:focus, .btn-foursquare.focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 115, 175, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-foursquare.disabled, .btn-foursquare:disabled {
    color: var(--white);
    background-color: #1073af;
    border-color: #1073af;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-foursquare:not(:disabled):not(.disabled):active, .btn-foursquare:not(:disabled):not(.disabled).active,
.show > .btn-foursquare.dropdown-toggle {
    color: var(--white);
    background-color: #0c5480;
    border-color: #0b4d75;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-foursquare:not(:disabled):not(.disabled):active:focus, .btn-foursquare:not(:disabled):not(.disabled).active:focus,
.show > .btn-foursquare.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 115, 175, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-vimeo {
    color: var(--gray-900);
    background-color: #aad450;
    border-color: #aad450;
}

/*noinspection CssUnusedSymbol*/
.btn-vimeo:hover {
    color: var(--gray-900);
    background-color: #9bcc32;
    border-color: #93c130;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-vimeo:focus, .btn-vimeo.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 212, 80, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-vimeo.disabled, .btn-vimeo:disabled {
    color: var(--gray-900);
    background-color: #aad450;
    border-color: #aad450;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-vimeo:not(:disabled):not(.disabled):active, .btn-vimeo:not(:disabled):not(.disabled).active,
.show > .btn-vimeo.dropdown-toggle {
    color: var(--gray-900);
    background-color: #93c130;
    border-color: #8bb72d;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-vimeo:not(:disabled):not(.disabled):active:focus, .btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .btn-vimeo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 212, 80, 0.5);
}

button {
    cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.btn-transparent {
    color: var(--white);
    background-color: transparent;
    border-color: transparent;
}

.btn [class^="icon-"],
.btn [class*=" icon-"] {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
}

/*noinspection CssUnusedSymbol*/
.btn-pill {
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.btn-square {
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-primary {
    color: var(--primary);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-primary:focus, .btn-ghost-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-primary.disabled, .btn-ghost-primary:disabled {
    color: var(--primary);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-primary:not(:disabled):not(.disabled):active, .btn-ghost-primary:not(:disabled):not(.disabled).active,
.show > .btn-ghost-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-primary:not(:disabled):not(.disabled):active:focus, .btn-ghost-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-secondary {
    color: var(--secondary);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-secondary:hover {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-secondary:focus, .btn-ghost-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-secondary.disabled, .btn-ghost-secondary:disabled {
    color: var(--secondary);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-secondary:not(:disabled):not(.disabled):active, .btn-ghost-secondary:not(:disabled):not(.disabled).active,
.show > .btn-ghost-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-secondary:not(:disabled):not(.disabled):active:focus, .btn-ghost-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-success {
    color: var(--success);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-success:hover {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-success:focus, .btn-ghost-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-success.disabled, .btn-ghost-success:disabled {
    color: var(--success);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-success:not(:disabled):not(.disabled):active, .btn-ghost-success:not(:disabled):not(.disabled).active,
.show > .btn-ghost-success.dropdown-toggle {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-success:not(:disabled):not(.disabled):active:focus, .btn-ghost-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-info {
    color: var(--info);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-info:hover {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-info:focus, .btn-ghost-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-info.disabled, .btn-ghost-info:disabled {
    color: var(--info);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-info:not(:disabled):not(.disabled):active, .btn-ghost-info:not(:disabled):not(.disabled).active,
.show > .btn-ghost-info.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-info:not(:disabled):not(.disabled):active:focus, .btn-ghost-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-warning {
    color: var(--warning);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-warning:hover {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-warning:focus, .btn-ghost-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-warning.disabled, .btn-ghost-warning:disabled {
    color: var(--warning);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-warning:not(:disabled):not(.disabled):active, .btn-ghost-warning:not(:disabled):not(.disabled).active,
.show > .btn-ghost-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-warning:not(:disabled):not(.disabled):active:focus, .btn-ghost-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-danger {
    color: var(--danger);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-danger:hover {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-danger:focus, .btn-ghost-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-danger.disabled, .btn-ghost-danger:disabled {
    color: var(--danger);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-danger:not(:disabled):not(.disabled):active, .btn-ghost-danger:not(:disabled):not(.disabled).active,
.show > .btn-ghost-danger.dropdown-toggle {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-danger:not(:disabled):not(.disabled):active:focus, .btn-ghost-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-light {
    color: var(--gray-100);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-light:hover {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-light:focus, .btn-ghost-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-light.disabled, .btn-ghost-light:disabled {
    color: var(--gray-100);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-light:not(:disabled):not(.disabled):active, .btn-ghost-light:not(:disabled):not(.disabled).active,
.show > .btn-ghost-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-light:not(:disabled):not(.disabled):active:focus, .btn-ghost-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-dark {
    color: var(--gray-800);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-dark:hover {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-dark:focus, .btn-ghost-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-dark.disabled, .btn-ghost-dark:disabled {
    color: var(--gray-800);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.btn-ghost-dark:not(:disabled):not(.disabled):active, .btn-ghost-dark:not(:disabled):not(.disabled).active,
.show > .btn-ghost-dark.dropdown-toggle {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.btn-ghost-dark:not(:disabled):not(.disabled):active:focus, .btn-ghost-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-ghost-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.callout {
    position: relative;
    padding: 0 1rem;
    margin: 1rem 0;
    border-left: 4px solid var(--secondary);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.callout .chart-wrapper {
    position: absolute;
    top: 10px;
    left: 50%;
    float: right;
    width: 50%;
}

/*noinspection CssUnusedSymbol*/
.callout-bordered {
    border: 1px solid var(--secondary);
    border-left-width: 4px;
}

.callout code {
    border-radius: 0.25rem;
}

.callout h4 {
    margin-top: 0;
    margin-bottom: .25rem;
}

.callout p:last-child {
    margin-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.callout + .callout {
    margin-top: -0.25rem;
}

/*noinspection CssUnusedSymbol*/
.callout-primary {
    border-left-color: var(--primary);
}

.callout-primary h4 {
    color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.callout-secondary {
    border-left-color: var(--secondary);
}

.callout-secondary h4 {
    color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.callout-success {
    border-left-color: var(--success);
}

.callout-success h4 {
    color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.callout-info {
    border-left-color: var(--info);
}

.callout-info h4 {
    color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.callout-warning {
    border-left-color: var(--warning);
}

.callout-warning h4 {
    color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.callout-danger {
    border-left-color: var(--danger);
}

.callout-danger h4 {
    color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.callout-light {
    border-left-color: var(--gray-100);
}

.callout-light h4 {
    color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.callout-dark {
    border-left-color: var(--gray-800);
}

.callout-dark h4 {
    color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout {
    border-right: 4px solid var(--secondary);
    border-left: 0;
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-primary {
    border-right-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-secondary {
    border-right-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-success {
    border-right-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-info {
    border-right-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-warning {
    border-right-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-danger {
    border-right-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-light {
    border-right-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout.callout-dark {
    border-right-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .callout .chart-wrapper {
    left: 0;
    float: left;
}

.card {
    margin-bottom: 1.5rem;
}

/*noinspection CssUnusedSymbol*/
.card.bg-primary {
    border-color: #187da0;
}

.card.bg-primary .card-header {
    background-color: #1e9ecb;
    border-color: #187da0;
}

/*noinspection CssUnusedSymbol*/
.card.bg-secondary {
    border-color: #a5aeb7;
}

.card.bg-secondary .card-header {
    background-color: #c0c6cc;
    border-color: #a5aeb7;
}

/*noinspection CssUnusedSymbol*/
.card.bg-success {
    border-color: #379457;
}

.card.bg-success .card-header {
    background-color: #44b76c;
    border-color: #379457;
}

/*noinspection CssUnusedSymbol*/
.card.bg-info {
    border-color: #2eadd3;
}

.card.bg-info .card-header {
    background-color: #56bddb;
    border-color: #2eadd3;
}

/*noinspection CssUnusedSymbol*/
.card.bg-warning {
    border-color: #c69500;
}

.card.bg-warning .card-header {
    background-color: #f7b900;
    border-color: #c69500;
}

/*noinspection CssUnusedSymbol*/
.card.bg-danger {
    border-color: #f5302e;
}

.card.bg-danger .card-header {
    background-color: #f75d5c;
    border-color: #f5302e;
}

/*noinspection CssUnusedSymbol*/
.card.bg-light {
    border-color: #cad4dc;
}

.card.bg-light .card-header {
    background-color: #e7ecef;
    border-color: #cad4dc;
}

/*noinspection CssUnusedSymbol*/
.card.bg-dark {
    border-color: #121517;
}

.card.bg-dark .card-header {
    background-color: #282d32;
    border-color: #121517;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.card.drag,
.card .drag {
    cursor: move;
}

.card-placeholder {
    background: rgba(0, 0, 0, 0.025);
    border: 1px dashed var(--secondary);
}

.card-header > i {
    margin-right: 0.5rem;
}

.card-header .nav-tabs {
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
    border-bottom: 0;
}

/*noinspection CssUnusedSymbol*/
.card-header .nav-tabs .nav-item {
    border-top: 0;
}

.card-header .nav-tabs .nav-link {
    padding: 0.75rem 0.625rem;
    color: var(--gray-600);
    border-top: 0;
    text-decoration: underline;
}

.card-header .nav-tabs .nav-link:hover {
    text-decoration: underline;
}

.card-header .nav-tabs .nav-link.active {
    color: var(--gray-900);
    background: var(--white);
    text-decoration: none;
}

/*noinspection CssUnusedSymbol*/
.card-header-icon-bg {
    display: inline-block;
    width: 2.8125rem;
    padding: 0.75rem 0;
    margin: -0.75rem 1.25rem -0.75rem -1.25rem;
    line-height: inherit;
    color: var(--gray-900);
    text-align: center;
    background: transparent;
    border-right: 1px solid var(--secondary);
}

.card-header-actions {
    display: inline-block;
    float: right;
    margin-right: -0.25rem;
}

/*noinspection CssUnusedSymbol*/
.card-header-action {
    padding: 0 0.25rem;
    color: var(--gray-600);
}

/*noinspection CssUnusedSymbol*/
.card-header-action:hover {
    color: var(--gray-900);
    text-decoration: none;
}

/*noinspection CssUnusedSymbol*/
.card-accent-primary {
    border-top-color: var(--primary);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-secondary {
    border-top-color: var(--secondary);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-success {
    border-top-color: var(--success);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-info {
    border-top-color: var(--info);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-warning {
    border-top-color: var(--warning);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-danger {
    border-top-color: var(--danger);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-light {
    border-top-color: var(--gray-100);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-accent-dark {
    border-top-color: var(--gray-800);
    border-top-width: 2px;
}

/*noinspection CssUnusedSymbol*/
.card-full {
    margin-top: -1rem;
    margin-right: -15px;
    margin-left: -15px;
    border: 0;
    border-bottom: 1px solid var(--secondary);
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    .card-columns.cols-2 {
        column-count: 2;
    }
}

.chart-wrapper canvas {
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.base-chart.chart {
    display: block;
}

canvas {
    user-select: none;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip {
    position: absolute;
    z-index: 1021;
    display: flex;
    flex-direction: column;
    padding: 0.25rem 0.5rem;
    color: var(--white);
    pointer-events: none;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: all 0.25s ease;
    transform: translate(-50%, 0);
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip .tooltip-header {
    margin-bottom: 0.5rem;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip .tooltip-header-item {
    font-size: 0.76563rem;
    font-weight: 700;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip .tooltip-body-item {
    display: flex;
    align-items: center;
    font-size: 0.76563rem;
    white-space: nowrap;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip .tooltip-body-item-color {
    display: inline-block;
    width: 0.875rem;
    height: 0.875rem;
    margin-right: 0.875rem;
}

/*noinspection CssUnusedSymbol*/
.chartjs-tooltip .tooltip-body-item-value {
    padding-left: 1rem;
    margin-left: auto;
    font-weight: 700;
}

/*noinspection CssUnusedSymbol*/
.dropdown-item {
    position: relative;
    padding: 10px 20px;
    border-bottom: 1px solid var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.dropdown-item:last-child {
    border-bottom: 0;
}

.dropdown-item i {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
    margin-left: -10px;
    color: var(--secondary);
    text-align: center;
}

.dropdown-item .badge {
    position: absolute;
    right: 10px;
    margin-top: 2px;
}

/*noinspection CssUnusedSymbol*/
.dropdown-header {
    padding: 8px 20px;
    background: var(--gray-200);
    border-bottom: 1px solid var(--secondary);
}

.dropdown-header .btn {
    margin-top: -7px;
    color: var(--gray-600);
}

.dropdown-header .btn:hover {
    color: var(--gray-900);
}

/*noinspection CssUnusedSymbol*/
.dropdown-header .btn.pull-right {
    margin-right: -20px;
}

/*noinspection CssUnusedSymbol*/
.dropdown-menu-lg {
    width: 250px;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-nav .dropdown-menu {
    position: absolute;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-nav .dropdown-menu-left {
    right: auto;
    left: 0;
}

/*noinspection CssUnusedSymbol*/
.app-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0 1rem;
    color: var(--gray-900);
    background: var(--gray-100);
    /*border-top: 1px solid var(--secondary);*/
}

/*noinspection CssInvalidPropertyValue,CssFloatPxLength,CssUnusedSymbol*/
.row.row-equal {
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-right: -15px;
    margin-left: -15px;
}

/*noinspection CssFloatPxLength*/
.row.row-equal [class*="col-"] {
    padding-right: 7.5px;
    padding-left: 7.5px;
}

/*noinspection CssUnusedSymbol*/
.main .container-fluid {
    padding: 0 20px;
    margin-top: .5rem;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-brand {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 155px;
    height: 55px;
    padding: 0;
    margin-right: 0;
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-brand .navbar-brand-minimized {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-toggler {
    padding: 0.25rem 0;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-toggler:hover .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%232f353a' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-toggler-icon {
    height: 23px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-nav {
    flex-direction: row;
    align-items: center;
}

/*noinspection CssUnusedSymbol*/
.app-header .nav-item {
    position: relative;
    min-width: 1rem;
    text-align: center;
    margin: 0 0 0 .25rem;
}

.app-header .nav-item button {
    margin: 0 auto;
}

.app-header .nav-item .nav-link {
    padding-top: 0;
    padding-bottom: 0;
    background: 0;
    border: 0;
}

.app-header .nav-item .nav-link .badge {
    position: absolute;
    top: 30%;
    left: 50%;
    margin-top: -16px;
    margin-left: 0;
}

.app-header .nav-item .nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img {
    height: 35px;
    margin: 0 10px;
}

/*noinspection CssUnusedSymbol*/
.app-header .dropdown-menu {
    padding-bottom: 0;
    line-height: 1.5;
}

/*noinspection CssUnusedSymbol*/
.app-header .dropdown-item {
    min-width: 180px;
}

.navbar-nav .nav-link {
    color: var(--texts);
}

.navbar-nav .nav-link:hover, .navbar-nav .nav-link:focus {
    color: var(--primary);
}

.navbar-nav .open > .nav-link, .navbar-nav .open > .nav-link:hover, .navbar-nav .open > .nav-link:focus,
.navbar-nav .active > .nav-link,
.navbar-nav .active > .nav-link:hover,
.navbar-nav .active > .nav-link:focus,
.navbar-nav .nav-link.open,
.navbar-nav .nav-link.open:hover,
.navbar-nav .nav-link.open:focus,
.navbar-nav .nav-link.active,
.navbar-nav .nav-link.active:hover,
.navbar-nav .nav-link.active:focus {
    color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.navbar-divider {
    background-color: rgba(0, 0, 0, 0.075);
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    .brand-minimized .app-header .navbar-brand {
        width: 50px;
        background-color: transparent;
    }

    /*noinspection CssUnusedSymbol*/
    .brand-minimized .app-header .navbar-brand .navbar-brand-full {
        display: none;
    }

    /*noinspection CssUnusedSymbol*/
    .brand-minimized .app-header .navbar-brand .navbar-brand-minimized {
        display: block;
    }
}

.input-group-prepend,
.input-group-append {
    white-space: nowrap;
    vertical-align: middle;
}

/*noinspection CssUnusedSymbol*/
.img-avatar, .avatar > img,
.img-circle {
    max-width: 100%;
    height: auto;
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.list-group-accent .list-group-item {
    margin-bottom: 1px;
    border-top: 0;
    border-right: 0;
    border-bottom: 0;
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.list-group-accent .list-group-item.list-group-item-divider {
    position: relative;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.list-group-accent .list-group-item.list-group-item-divider::before {
    position: absolute;
    bottom: -1px;
    left: 5%;
    width: 90%;
    height: 1px;
    content: "";
    background-color: var(--gray-200);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-primary {
    border-left: 4px solid var(--primary);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-secondary {
    border-left: 4px solid var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-success {
    border-left: 4px solid var(--success);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-info {
    border-left: 4px solid var(--info);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-warning {
    border-left: 4px solid var(--warning);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-danger {
    border-left: 4px solid var(--danger);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-light {
    border-left: 4px solid var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.list-group-item-accent-dark {
    border-left: 4px solid var(--gray-800);
}

.modal-primary .modal-content {
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.modal-primary .modal-header {
    color: var(--white);
    background-color: var(--primary);
}

.modal-secondary .modal-content {
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.modal-secondary .modal-header {
    color: var(--white);
    background-color: var(--secondary);
}

.modal-success .modal-content {
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.modal-success .modal-header {
    color: var(--white);
    background-color: var(--success);
}

.modal-info .modal-content {
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.modal-info .modal-header {
    color: var(--white);
    background-color: var(--info);
}

.modal-warning .modal-content {
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.modal-warning .modal-header {
    color: var(--white);
    background-color: var(--warning);
}

.modal-danger .modal-content {
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.modal-danger .modal-header {
    color: var(--white);
    background-color: var(--danger);
}

.modal-light .modal-content {
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.modal-light .modal-header {
    color: var(--white);
    background-color: var(--gray-100);
}

.modal-dark .modal-content {
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.modal-dark .modal-header {
    color: var(--white);
    background-color: var(--gray-800);
}

.nav-tabs .nav-link {
    color: var(--gray-600);
}

.nav-tabs .nav-link:hover {
    cursor: pointer;
}

.nav-tabs .nav-link.active {
    color: var(--texts);
    background: var(--white);
    border-color: var(--secondary);
    border-bottom-color: var(--white);
}

.nav-tabs .nav-link.active:focus {
    background: var(--white);
    border-color: var(--secondary);
    border-bottom-color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.tab-content {
    margin-top: -1px;
    background: var(--white);
    /*border: 1px solid var(--secondary);*/
}

/*noinspection CssUnusedSymbol*/
.tab-content .tab-pane {
    padding: 0;
}

/*noinspection CssUnusedSymbol*/
.card-block .tab-content {
    margin-top: 0;
    border: 0;
}

.nav-fill .nav-link {
    background-color: var(--white);
    border-color: var(--secondary);
}

.nav-fill .nav-link + .nav-link {
    margin-left: -1px;
}

.nav-fill .nav-link.active {
    margin-top: -1px;
    border-top: 2px solid var(--primary);
}

*[dir="rtl"] .nav {
    padding-right: 0;
}

/*noinspection CssUnusedSymbol*/
.progress-xs {
    height: 4px;
}

/*noinspection CssUnusedSymbol*/
.progress-sm {
    height: 8px;
}

/*noinspection CssUnusedSymbol*/
.progress-white {
    background-color: rgba(255, 255, 255, 0.2);
}

/*noinspection CssUnusedSymbol*/
.progress-white .progress-bar {
    background-color: var(--white);
}

.progress-group {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 1rem;
}

.progress-group-prepend {
    flex: 0 0 100px;
    align-self: center;
}

.progress-group-icon {
    margin: 0 1rem 0 0.25rem;
    font-size: 1.09375rem;
}

.progress-group-text {
    font-size: 0.76563rem;
    color: var(--gray-600);
}

/*noinspection CssUnusedSymbol*/
.progress-group-header {
    display: flex;
    flex-basis: 100%;
    align-items: flex-end;
    margin-bottom: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.progress-group-bars {
    flex-grow: 1;
    align-self: center;
}

.progress-group-bars .progress:not(:last-child) {
    margin-bottom: 2px;
}

/*noinspection CssUnusedSymbol*/
.progress-group-header + .progress-group-bars {
    flex-basis: 100%;
}

/*noinspection CssUnusedSymbol*/
.sidebar .sidebar-close {
    position: absolute;
    right: 0;
    display: none;
    padding: 0 1rem;
    font-size: 24px;
    font-weight: 800;
    line-height: 55px;
    color: var(--white);
    background: 0;
    border: 0;
    opacity: .8;
}

/*noinspection CssUnusedSymbol*/
.sidebar .sidebar-close:hover {
    opacity: 1;
}

.sidebar .sidebar-form .form-control {
    color: var(--white);
    background: #181b1e;
    border: 0;
}

.sidebar .sidebar-form .form-control::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.sidebar .sidebar-nav {
    position: relative;
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 200px;
}

.sidebar .nav {
    width: 200px;
    flex-direction: column;
    min-height: 100%;
    padding: 0;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-title {
    padding: 0.75rem 1rem;
    font-size: 80%;
    font-weight: 700;
    color: var(--gray-200);
    text-transform: uppercase;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-divider {
    height: 10px;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-item {
    position: relative;
    margin: 0;
    transition: background .3s ease-in-out;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown-items {
    max-height: 0;
    padding: 0;
    margin: 0;
    overflow-y: hidden;
    transition: max-height .3s ease-in-out;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown-items .nav-item {
    padding: 0;
    list-style: none;
}

.sidebar .nav-link .badge {
    float: right;
    margin-top: 2px;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link:hover.nav-dropdown-toggle::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-primary {
    background: var(--primary);
}

.sidebar .nav-link.nav-link-primary .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-primary:hover {
    background: #1d97c2;
}

.sidebar .nav-link.nav-link-primary:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-secondary {
    background: var(--secondary);
}

.sidebar .nav-link.nav-link-secondary .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-secondary:hover {
    background: #bac1c8;
}

.sidebar .nav-link.nav-link-secondary:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-success {
    background: var(--success);
}

.sidebar .nav-link.nav-link-success .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-success:hover {
    background: #41af67;
}

.sidebar .nav-link.nav-link-success:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-info {
    background: var(--info);
}

.sidebar .nav-link.nav-link-info .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-info:hover {
    background: #4ebada;
}

.sidebar .nav-link.nav-link-info:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-warning {
    background: var(--warning);
}

.sidebar .nav-link.nav-link-warning .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-warning:hover {
    background: #edb100;
}

.sidebar .nav-link.nav-link-warning:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-danger {
    background: var(--danger);
}

.sidebar .nav-link.nav-link-danger .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-danger:hover {
    background: #f75453;
}

.sidebar .nav-link.nav-link-danger:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-light {
    background: var(--gray-100);
}

.sidebar .nav-link.nav-link-light .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-light:hover {
    background: #e1e7eb;
}

.sidebar .nav-link.nav-link-light:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-dark {
    background: var(--gray-800);
}

.sidebar .nav-link.nav-link-dark .nav-icon {
    color: rgba(255, 255, 255, 0.7);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-link.nav-link-dark:hover {
    background: #24282c;
}

.sidebar .nav-link.nav-link-dark:hover i {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown-toggle {
    position: relative;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown-toggle::before {
    position: absolute;
    top: 50%;
    right: 1rem;
    display: block;
    width: 8px;
    height: 8px;
    padding: 0;
    margin-top: -4px;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    transition: transform .3s;
}

.sidebar .nav-dropdown.open {
    background: rgba(0, 0, 0, 0.2);
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown.open > .nav-dropdown-items {
    max-height: 1500px;
}

.sidebar .nav-dropdown.open .nav-link {
    color: var(--white);
    border-left: 0;
}

/*noinspection CssUnusedSymbol*/
.sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
    transform: rotate(-90deg);
}

.sidebar .nav-dropdown.open .nav-dropdown.open {
    border-left: 0;
}

.sidebar .nav-label {
    display: block;
    padding: 0.09375rem 1rem;
    color: var(--gray-200);
}

.sidebar .nav-label:hover {
    color: var(--white);
    text-decoration: none;
}

.sidebar .nav-label .nav-icon {
    width: 20px;
    margin: -3px 0.5rem 0 0;
    font-size: 10px;
    color: var(--gray-600);
    text-align: center;
    vertical-align: middle;
}

.sidebar .progress {
    background-color: #515c64 !important;
}

.sidebar .sidebar-footer {
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    background: rgba(0, 0, 0, 0.2);
}

/*noinspection CssUnusedSymbol*/
.sidebar .sidebar-minimizer {
    position: relative;
    flex: 0 0 50px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 0;
}

/*noinspection CssFloatPxLength,CssUnusedSymbol*/
.sidebar .sidebar-minimizer::before {
    position: absolute;
    top: 0;
    right: 0;
    width: 50px;
    height: 50px;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%2373818f' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 12.5px;
    transition: .3s;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.sidebar .sidebar-minimizer:focus, .sidebar .sidebar-minimizer.focus {
    outline: 0;
}

/*noinspection CssUnusedSymbol*/
.sidebar .sidebar-minimizer:hover {
    background-color: rgba(0, 0, 0, 0.3);
}

/*noinspection CssUnusedSymbol*/
.sidebar .sidebar-minimizer:hover::before {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 11 14'%3E%3Cpath fill='%23fff' d='M9.148 2.352l-4.148 4.148 4.148 4.148q0.148 0.148 0.148 0.352t-0.148 0.352l-1.297 1.297q-0.148 0.148-0.352 0.148t-0.352-0.148l-5.797-5.797q-0.148-0.148-0.148-0.352t0.148-0.352l5.797-5.797q0.148-0.148 0.352-0.148t0.352 0.148l1.297 1.297q0.148 0.148 0.148 0.352t-0.148 0.352z'/%3E%3C/svg%3E");
}

@media (min-width: 992px) {
    .sidebar-compact .sidebar .sidebar-nav {
        width: 150px;
    }

    .sidebar-compact .sidebar .nav {
        width: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-compact .sidebar .d-compact-none {
        display: none;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-compact .sidebar .nav-title {
        text-align: center;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-compact .sidebar .nav-item {
        width: 150px;
        border-left: 0;
    }

    .sidebar-compact .sidebar .nav-link {
        text-align: center;
    }

    .sidebar-compact .sidebar .nav-link .nav-icon {
        display: block;
        width: 100%;
        margin: 0.25rem 0;
        font-size: 24px;
    }

    .sidebar-compact .sidebar .nav-link .badge {
        position: absolute;
        top: 18px;
        right: 10px;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-compact .sidebar .nav-link.nav-dropdown-toggle::before {
        top: 30px;
    }

    .sidebar-minimized .sidebar {
        z-index: 1019;
    }

    .sidebar-minimized .sidebar .sidebar-nav {
        overflow: visible;
        width: 50px;
    }

    .sidebar-minimized .sidebar .nav {
        width: 50px;
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
    .sidebar-minimized .sidebar .d-minimized-none,
    .sidebar-minimized .sidebar .nav-divider,
    .sidebar-minimized .sidebar .nav-label,
    .sidebar-minimized .sidebar .nav-title,
    .sidebar-minimized .sidebar .sidebar-footer,
    .sidebar-minimized .sidebar .sidebar-form,
    .sidebar-minimized .sidebar .sidebar-header {
        display: none;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .sidebar-minimizer::before {
        width: 100%;
        transform: rotate(-180deg);
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav-item {
        width: 50px;
        overflow: hidden;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav-item:hover {
        width: 250px;
        overflow: visible;
    }

    .sidebar-minimized .sidebar .nav-item:hover > .nav-link {
        color: var(--dark);
        background: var(--thrintary);
    }

    .sidebar-minimized .sidebar .nav-item:hover > .nav-link .nav-icon {
        color: var(--dark);
    }

    .sidebar-minimized .sidebar .nav-link {
        position: relative;
        padding-left: 0;
        margin: 0;
        white-space: nowrap;
        border-left: 0;
    }

    .sidebar-minimized .sidebar .nav-link .nav-icon {
        display: block;
        float: left;
        width: 50px;
        font-size: 18px;
    }

    .sidebar-minimized .sidebar .nav-link .badge {
        position: absolute;
        right: 15px;
        display: none;
    }

    .sidebar-minimized .sidebar .nav-link:hover {
        width: 250px;
        background: var(--primary);
    }

    .sidebar-minimized .sidebar .nav-link:hover .badge {
        display: inline;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav-link.nav-dropdown-toggle::before {
        display: none;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav-dropdown-items .nav-item {
        width: 200px;
    }

    .sidebar-minimized .sidebar .nav-dropdown-items .nav-item .nav-link {
        width: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav > .nav-dropdown > .nav-dropdown-items {
        display: none;
        max-height: 1000px;
        background: var(--gray-800);
    }

    .sidebar-minimized .sidebar .nav > .nav-dropdown:hover {
        background: var(--primary);
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimized .sidebar .nav > .nav-dropdown:hover > .nav-dropdown-items {
        position: absolute;
        left: 50px;
        display: inline;
    }
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .sidebar .nav-dropdown-toggle::before {
    position: absolute;
    right: auto;
    left: 1rem;
    transform: rotate(180deg);
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .sidebar .nav-dropdown.open > .nav-dropdown-toggle::before {
    transform: rotate(270deg);
}

*[dir="rtl"] .sidebar .nav-link .nav-icon {
    margin: 0 0 0 0.5rem;
}

*[dir="rtl"] .sidebar .nav-link .badge {
    float: left;
    margin-top: 2px;
}

*[dir="rtl"] .sidebar-minimized .sidebar .nav-link {
    padding-right: 0;
}

*[dir="rtl"] .sidebar-minimized .sidebar .nav-link .nav-icon {
    float: right;
    padding: 0;
    margin: 0;
}

*[dir="rtl"] .sidebar-minimized .sidebar .nav-link .badge {
    right: auto;
    left: 15px;
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .sidebar-minimized .sidebar .nav-dropdown:hover > .nav-dropdown-items {
    right: 50px;
    left: 0;
}

.switch {
    display: inline-block;
    width: 40px;
    height: 26px;
}

.switch-input {
    display: none;
}

/*noinspection CssUnusedSymbol*/
.switch-slider {
    position: relative;
    display: block;
    height: inherit;
    cursor: pointer;
    background-color: var(--white);
    border: 1px solid var(--secondary);
    transition: .15s ease-out;
    border-radius: 0.25rem;
}

/*noinspection CssUnusedSymbol*/
.switch-slider::before {
    position: absolute;
    top: 2px;
    left: 2px;
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    content: "";
    background-color: var(--white);
    border: 1px solid var(--secondary);
    transition: .15s ease-out;
    border-radius: 0.125rem;
}

/*noinspection CssUnusedSymbol*/
.switch-input:checked ~ .switch-slider::before {
    transform: translateX(14px);
}

/*noinspection CssUnusedSymbol*/
.switch-input:disabled ~ .switch-slider {
    cursor: not-allowed;
    opacity: .5;
}

.switch-lg {
    width: 48px;
    height: 30px;
}

/*noinspection CssUnusedSymbol*/
.switch-lg .switch-slider {
    font-size: 12px;
}

/*noinspection CssUnusedSymbol*/
.switch-lg .switch-slider::before {
    width: 24px;
    height: 24px;
}

/*noinspection CssUnusedSymbol*/
.switch-lg .switch-slider::after {
    font-size: 12px;
}

/*noinspection CssUnusedSymbol*/
.switch-lg .switch-input:checked ~ .switch-slider::before {
    transform: translateX(18px);
}

.switch-sm {
    width: 32px;
    height: 22px;
}

/*noinspection CssUnusedSymbol*/
.switch-sm .switch-slider {
    font-size: 8px;
}

/*noinspection CssUnusedSymbol*/
.switch-sm .switch-slider::before {
    width: 16px;
    height: 16px;
}

/*noinspection CssUnusedSymbol*/
.switch-sm .switch-slider::after {
    font-size: 8px;
}

/*noinspection CssUnusedSymbol*/
.switch-sm .switch-input:checked ~ .switch-slider::before {
    transform: translateX(10px);
}

.switch-label {
    width: 48px;
}

/*noinspection CssUnusedSymbol*/
.switch-label .switch-slider::before {
    z-index: 2;
}

/*noinspection CssUnusedSymbol*/
.switch-label .switch-slider::after {
    position: absolute;
    top: 50%;
    right: 1px;
    z-index: 1;
    width: 50%;
    margin-top: -.5em;
    font-size: 10px;
    font-weight: 600;
    line-height: 1;
    color: var(--secondary);
    text-align: center;
    text-transform: uppercase;
    content: attr(data-unchecked);
    transition: inherit;
}

/*noinspection CssUnusedSymbol*/
.switch-label .switch-input:checked ~ .switch-slider::before {
    transform: translateX(22px);
}

/*noinspection CssUnusedSymbol*/
.switch-label .switch-input:checked ~ .switch-slider::after {
    left: 1px;
    color: var(--white);
    content: attr(data-checked);
}

.switch-label.switch-lg {
    width: 56px;
    height: 30px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-lg .switch-slider {
    font-size: 12px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-lg .switch-slider::before {
    width: 24px;
    height: 24px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-lg .switch-slider::after {
    font-size: 12px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-lg .switch-input:checked ~ .switch-slider::before {
    transform: translateX(26px);
}

.switch-label.switch-sm {
    width: 40px;
    height: 22px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-sm .switch-slider {
    font-size: 8px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-sm .switch-slider::before {
    width: 16px;
    height: 16px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-sm .switch-slider::after {
    font-size: 8px;
}

/*noinspection CssUnusedSymbol*/
.switch-label.switch-sm .switch-input:checked ~ .switch-slider::before {
    transform: translateX(18px);
}

/*noinspection CssUnusedSymbol*/
.switch-3d .switch-slider {
    background-color: var(--gray-100);
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.switch-3d .switch-slider::before {
    top: -1px;
    left: -1px;
    width: 26px;
    height: 26px;
    border: 0;
    border-radius: 50em;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-lg {
    width: 48px;
    height: 30px;
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-lg .switch-slider::before {
    width: 30px;
    height: 30px;
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-lg .switch-input:checked ~ .switch-slider::before {
    transform: translateX(18px);
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-sm {
    width: 32px;
    height: 22px;
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-sm .switch-slider::before {
    width: 22px;
    height: 22px;
}

/*noinspection CssUnusedSymbol*/
.switch-3d.switch-sm .switch-input:checked ~ .switch-slider::before {
    transform: translateX(10px);
}

/*noinspection CssUnusedSymbol*/
.switch-primary .switch-input:checked + .switch-slider {
    background-color: var(--primary);
    border-color: #1985ac;
}

/*noinspection CssUnusedSymbol*/
.switch-primary .switch-input:checked + .switch-slider::before {
    border-color: #1985ac;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary .switch-input:checked + .switch-slider::before {
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary .switch-input:checked + .switch-slider::after {
    color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-primary-alt .switch-input:checked + .switch-slider::after {
    color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.switch-secondary .switch-input:checked + .switch-slider {
    background-color: var(--secondary);
    border-color: #acb5bc;
}

/*noinspection CssUnusedSymbol*/
.switch-secondary .switch-input:checked + .switch-slider::before {
    border-color: #acb5bc;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary .switch-input:checked + .switch-slider::before {
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary .switch-input:checked + .switch-slider::after {
    color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-secondary-alt .switch-input:checked + .switch-slider::after {
    color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.switch-success .switch-input:checked + .switch-slider {
    background-color: var(--success);
    border-color: #3a9d5d;
}

/*noinspection CssUnusedSymbol*/
.switch-success .switch-input:checked + .switch-slider::before {
    border-color: #3a9d5d;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success .switch-input:checked + .switch-slider::before {
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success .switch-input:checked + .switch-slider::after {
    color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-success-alt .switch-input:checked + .switch-slider::after {
    color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.switch-info .switch-input:checked + .switch-slider {
    background-color: var(--info);
    border-color: #39b2d5;
}

/*noinspection CssUnusedSymbol*/
.switch-info .switch-input:checked + .switch-slider::before {
    border-color: #39b2d5;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info .switch-input:checked + .switch-slider::before {
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info .switch-input:checked + .switch-slider::after {
    color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-info-alt .switch-input:checked + .switch-slider::after {
    color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.switch-warning .switch-input:checked + .switch-slider {
    background-color: var(--warning);
    border-color: #d39e00;
}

/*noinspection CssUnusedSymbol*/
.switch-warning .switch-input:checked + .switch-slider::before {
    border-color: #d39e00;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning .switch-input:checked + .switch-slider::before {
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning .switch-input:checked + .switch-slider::after {
    color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-warning-alt .switch-input:checked + .switch-slider::after {
    color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.switch-danger .switch-input:checked + .switch-slider {
    background-color: var(--danger);
    border-color: #f63c3a;
}

/*noinspection CssUnusedSymbol*/
.switch-danger .switch-input:checked + .switch-slider::before {
    border-color: #f63c3a;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger .switch-input:checked + .switch-slider::before {
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger .switch-input:checked + .switch-slider::after {
    color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-danger-alt .switch-input:checked + .switch-slider::after {
    color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.switch-light .switch-input:checked + .switch-slider {
    background-color: var(--gray-100);
    border-color: #d1dbe1;
}

/*noinspection CssUnusedSymbol*/
.switch-light .switch-input:checked + .switch-slider::before {
    border-color: #d1dbe1;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light .switch-input:checked + .switch-slider::before {
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light .switch-input:checked + .switch-slider::after {
    color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-light-alt .switch-input:checked + .switch-slider::after {
    color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.switch-dark .switch-input:checked + .switch-slider {
    background-color: var(--gray-800);
    border-color: #181b1e;
}

/*noinspection CssUnusedSymbol*/
.switch-dark .switch-input:checked + .switch-slider::before {
    border-color: #181b1e;
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark .switch-input:checked + .switch-slider::before {
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark .switch-input:checked + .switch-slider::after {
    color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark-alt .switch-input:checked + .switch-slider {
    background-color: var(--white);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark-alt .switch-input:checked + .switch-slider::before {
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-outline-dark-alt .switch-input:checked + .switch-slider::after {
    color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.switch-pill .switch-slider {
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.switch-pill .switch-slider::before {
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.table-outline {
    border: 1px solid var(--secondary);
}

.table-outline td {
    vertical-align: middle;
}

.table-align-middle td {
    vertical-align: middle;
}

.table-clear td {
    border: 0;
}

@media all and (-ms-high-contrast: none) {
    html {
        display: flex;
        flex-direction: column;
    }
}

/*noinspection CssUnusedSymbol*/
.app,
.app-dashboard,
.app-root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.app-header {
    flex: 0 0 55px;
}

/*noinspection CssUnusedSymbol*/
.app-footer {
    flex: 0 0 50px;
}

.app-body {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
}

.app-body .main {
    flex: 1;
    min-width: 0;
}

.app-body .sidebar {
    flex: 0 0 200px;
    order: -1;
}

/*noinspection CssUnusedSymbol*/
.app-body .aside-menu {
    flex: 0 0 250px;
}

html:not([dir="rtl"]) .sidebar {
    margin-left: -200px;
}

/*noinspection CssUnusedSymbol*/
html:not([dir="rtl"]) .aside-menu {
    margin-right: -250px;
}

html[dir="rtl"] .sidebar {
    margin-right: -200px;
}

/*noinspection CssUnusedSymbol*/
html[dir="rtl"] .aside-menu {
    margin-left: -250px;
}

@media (min-width: 992px) {
    .header-fixed .app-header {
        position: -webkit-sticky;
        position: sticky;
        z-index: 2;
        top: 0;
        /*position: fixed;*/
        /*z-index: 1020;*/
        width: 100%;
    }

    .header-fixed .app-body {
        /*margin-top: 55px;*/
    }

    .sidebar-fixed .sidebar {
        position: fixed;
        z-index: 1019;
        width: 200px;
        height: 100vh;
    }

    .sidebar-fixed .app-header + .app-body .sidebar {
        height: calc(100vh - 55px);
    }

    .sidebar-compact .sidebar {
        flex: 0 0 150px;
    }

    .sidebar-compact.sidebar-fixed .sidebar {
        width: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-compact .sidebar-minimizer {
        display: none;
    }

    .sidebar-minimized .sidebar {
        flex: 0 0 50px;
    }

    .sidebar-minimized.sidebar-fixed .sidebar {
        width: 50px;
    }

    .sidebar-off-canvas .sidebar {
        position: fixed;
        z-index: 1019;
        height: 100%;
    }

    .sidebar-off-canvas .app-header + .app-body .sidebar {
        height: calc(100vh - 55px);
    }

    html:not([dir="rtl"]) .sidebar-compact .sidebar {
        margin-left: -150px;
    }

    html:not([dir="rtl"]) .sidebar-minimized .sidebar {
        margin-left: -50px;
    }

    html[dir="rtl"] .sidebar-compact .sidebar {
        margin-right: -150px;
    }

    html[dir="rtl"] .sidebar-minimized .sidebar {
        margin-right: -50px;
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu-fixed .aside-menu {
        position: fixed;
        height: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu-fixed .aside-menu .tab-content {
        height: calc(100vh - 2.375rem - 55px);
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu-fixed .app-header + .app-body .aside-menu {
        height: calc(100vh - 55px);
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu-off-canvas .aside-menu {
        position: fixed;
        z-index: 1019;
        height: 100%;
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu-off-canvas .app-header + .app-body .aside-menu {
        height: calc(100vh - 55px);
    }

    /*noinspection CssUnusedSymbol,CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-fixed .aside-menu,
    html:not([dir="rtl"]) .aside-menu-off-canvas .aside-menu {
        right: 0;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-fixed .aside-menu,
    html[dir="rtl"] .aside-menu-off-canvas .aside-menu {
        left: 0;
    }
}

.breadcrumb-fixed .main {
    padding-top: 3.875rem;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb-fixed .breadcrumb {
    position: fixed;
    top: 55px;
    right: 0;
    left: 0;
    z-index: 1017;
}

html:not([dir="rtl"]) .sidebar-show .sidebar {
    margin-left: 0;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-compact .main,
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir="rtl"]) .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-left: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.breadcrumb-fixed .breadcrumb {
        left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        left: 50px;
    }
}

/*noinspection CssUnusedSymbol*/
html:not([dir="rtl"]) .aside-menu-show .aside-menu {
    margin-right: 0;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-show.aside-menu-fixed .main,
    html:not([dir="rtl"]) .aside-menu-show.aside-menu-fixed .app-footer {
        margin-right: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-show.breadcrumb-fixed .breadcrumb {
        right: 250px;
    }
}

html[dir="rtl"] .sidebar-show .sidebar {
    margin-right: 0;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.sidebar-fixed .main,
    html[dir="rtl"] .sidebar-show.sidebar-fixed .app-footer {
        margin-right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.sidebar-fixed.sidebar-compact .main,
    html[dir="rtl"] .sidebar-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.sidebar-fixed.sidebar-minimized .main,
    html[dir="rtl"] .sidebar-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-right: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.breadcrumb-fixed .breadcrumb {
        right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        right: 50px;
    }
}

/*noinspection CssUnusedSymbol*/
html[dir="rtl"] .aside-menu-show .aside-menu {
    margin-left: 0;
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-show.aside-menu-fixed .main,
    html[dir="rtl"] .aside-menu-show.aside-menu-fixed .app-footer {
        margin-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-show.breadcrumb-fixed .breadcrumb {
        left: 250px;
    }
}

@keyframes opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 575.98px) {
    .sidebar-show .main,
    .aside-menu-show .main {
        position: relative;
    }

    .sidebar-show .main::before,
    .aside-menu-show .main::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.7);
        animation: opacity 0.25s;
    }
}

@media (min-width: 576px) {
    html:not([dir="rtl"]) .sidebar-sm-show .sidebar {
        margin-left: 0;
    }
}

@media (min-width: 576px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed .app-footer {
        margin-left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed.sidebar-compact .main,
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir="rtl"]) .sidebar-sm-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-left: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.breadcrumb-fixed .breadcrumb {
        left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-sm-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        left: 50px;
    }
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-sm-show .aside-menu {
        margin-right: 0;
    }
}

@media (min-width: 576px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-sm-show.aside-menu-fixed .main,
    html:not([dir="rtl"]) .aside-menu-sm-show.aside-menu-fixed .app-footer {
        margin-right: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-sm-show.breadcrumb-fixed .breadcrumb {
        right: 250px;
    }
}

@media (min-width: 576px) {
    html[dir="rtl"] .sidebar-sm-show .sidebar {
        margin-right: 0;
    }
}

@media (min-width: 576px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed .main,
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed .app-footer {
        margin-right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed.sidebar-compact .main,
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed.sidebar-minimized .main,
    html[dir="rtl"] .sidebar-sm-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-right: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.breadcrumb-fixed .breadcrumb {
        right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-sm-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        right: 50px;
    }
}

@media (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-sm-show .aside-menu {
        margin-left: 0;
    }
}

@media (min-width: 576px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-sm-show.aside-menu-fixed .main,
    html[dir="rtl"] .aside-menu-sm-show.aside-menu-fixed .app-footer {
        margin-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-sm-show.breadcrumb-fixed .breadcrumb {
        left: 250px;
    }
}

@media (min-width: 576px) {
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/*noinspection CssFloatPxLength*/
@media (min-width: 576px) and (max-width: 575.98px) {
    .sidebar-sm-show .main,
    .aside-menu-sm-show .main {
        position: relative;
    }

    .sidebar-sm-show .main::before,
    .aside-menu-sm-show .main::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.7);
        animation: opacity 0.25s;
    }
}

@media (min-width: 768px) {
    html:not([dir="rtl"]) .sidebar-md-show .sidebar {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed .app-footer {
        margin-left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-compact .main,
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir="rtl"]) .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-left: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.breadcrumb-fixed .breadcrumb {
        left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-md-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        left: 50px;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-md-show .aside-menu {
        margin-right: 0;
    }
}

@media (min-width: 768px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-md-show.aside-menu-fixed .main,
    html:not([dir="rtl"]) .aside-menu-md-show.aside-menu-fixed .app-footer {
        margin-right: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-md-show.breadcrumb-fixed .breadcrumb {
        right: 250px;
    }
}

@media (min-width: 768px) {
    html[dir="rtl"] .sidebar-md-show .sidebar {
        margin-right: 0;
    }
}

@media (min-width: 768px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed .main,
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed .app-footer {
        margin-right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed.sidebar-compact .main,
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed.sidebar-minimized .main,
    html[dir="rtl"] .sidebar-md-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-right: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.breadcrumb-fixed .breadcrumb {
        right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-md-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        right: 50px;
    }
}

@media (min-width: 768px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-md-show .aside-menu {
        margin-left: 0;
    }
}

@media (min-width: 768px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-md-show.aside-menu-fixed .main,
    html[dir="rtl"] .aside-menu-md-show.aside-menu-fixed .app-footer {
        margin-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-md-show.breadcrumb-fixed .breadcrumb {
        left: 250px;
    }
}

@media (min-width: 768px) {
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/*noinspection CssFloatPxLength*/
@media (min-width: 768px) and (max-width: 575.98px) {
    .sidebar-md-show .main,
    .aside-menu-md-show .main {
        position: relative;
    }

    .sidebar-md-show .main::before,
    .aside-menu-md-show .main::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.7);
        animation: opacity 0.25s;
    }
}

@media (min-width: 992px) {
    html:not([dir="rtl"]) .sidebar-lg-show .sidebar {
        margin-left: 0;
    }
}

@media (min-width: 992px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer {
        margin-left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-compact .main,
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-left: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.breadcrumb-fixed .breadcrumb {
        left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-lg-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        left: 50px;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-lg-show .aside-menu {
        margin-right: 0;
    }
}

@media (min-width: 992px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-lg-show.aside-menu-fixed .main,
    html:not([dir="rtl"]) .aside-menu-lg-show.aside-menu-fixed .app-footer {
        margin-right: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-lg-show.breadcrumb-fixed .breadcrumb {
        right: 250px;
    }
}

@media (min-width: 992px) {
    html[dir="rtl"] .sidebar-lg-show .sidebar {
        margin-right: 0;
    }
}

@media (min-width: 992px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed .main,
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed .app-footer {
        margin-right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed.sidebar-compact .main,
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed.sidebar-minimized .main,
    html[dir="rtl"] .sidebar-lg-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-right: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.breadcrumb-fixed .breadcrumb {
        right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-lg-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        right: 50px;
    }
}

@media (min-width: 992px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-lg-show .aside-menu {
        margin-left: 0;
    }
}

@media (min-width: 992px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-lg-show.aside-menu-fixed .main,
    html[dir="rtl"] .aside-menu-lg-show.aside-menu-fixed .app-footer {
        margin-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-lg-show.breadcrumb-fixed .breadcrumb {
        left: 250px;
    }
}

@media (min-width: 992px) {
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/*noinspection CssFloatPxLength*/
@media (min-width: 992px) and (max-width: 575.98px) {
    .sidebar-lg-show .main,
    .aside-menu-lg-show .main {
        position: relative;
    }

    .sidebar-lg-show .main::before,
    .aside-menu-lg-show .main::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.7);
        animation: opacity 0.25s;
    }
}

@media (min-width: 1200px) {
    html:not([dir="rtl"]) .sidebar-xl-show .sidebar {
        margin-left: 0;
    }
}

@media (min-width: 1200px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed .main,
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed .app-footer {
        margin-left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed.sidebar-compact .main,
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed.sidebar-minimized .main,
    html:not([dir="rtl"]) .sidebar-xl-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-left: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.breadcrumb-fixed .breadcrumb {
        left: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        left: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .sidebar-xl-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        left: 50px;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-xl-show .aside-menu {
        margin-right: 0;
    }
}

@media (min-width: 1200px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-xl-show.aside-menu-fixed .main,
    html:not([dir="rtl"]) .aside-menu-xl-show.aside-menu-fixed .app-footer {
        margin-right: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html:not([dir="rtl"]) .aside-menu-xl-show.breadcrumb-fixed .breadcrumb {
        right: 250px;
    }
}

@media (min-width: 1200px) {
    html[dir="rtl"] .sidebar-xl-show .sidebar {
        margin-right: 0;
    }
}

@media (min-width: 1200px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed .main,
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed .app-footer {
        margin-right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed.sidebar-compact .main,
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed.sidebar-compact .app-footer {
        margin-right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed.sidebar-minimized .main,
    html[dir="rtl"] .sidebar-xl-show.sidebar-fixed.sidebar-minimized .app-footer {
        margin-right: 50px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.breadcrumb-fixed .breadcrumb {
        right: 200px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.breadcrumb-fixed.sidebar-compact .breadcrumb {
        right: 150px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .sidebar-xl-show.breadcrumb-fixed.sidebar-minimized .breadcrumb {
        right: 50px;
    }
}

@media (min-width: 1200px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-xl-show .aside-menu {
        margin-left: 0;
    }
}

@media (min-width: 1200px) and (min-width: 576px) {
    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-xl-show.aside-menu-fixed .main,
    html[dir="rtl"] .aside-menu-xl-show.aside-menu-fixed .app-footer {
        margin-left: 250px;
    }

    /*noinspection CssUnusedSymbol*/
    html[dir="rtl"] .aside-menu-xl-show.breadcrumb-fixed .breadcrumb {
        left: 250px;
    }
}

@media (min-width: 1200px) {
    @keyframes opacity {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}

/*noinspection CssFloatPxLength*/
@media (min-width: 1200px) and (max-width: 575.98px) {
    .sidebar-xl-show .main,
    .aside-menu-xl-show .main {
        position: relative;
    }

    .sidebar-xl-show .main::before,
    .aside-menu-xl-show .main::before {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1018;
        width: 100%;
        height: 100%;
        content: "";
        background: rgba(0, 0, 0, 0.7);
        animation: opacity 0.25s;
    }
}

/*noinspection CssUnusedSymbol*/
.footer-fixed .app-footer {
    position: sticky;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1020;
    height: 50px;
}

.footer-fixed .app-body {
    /*margin-bottom: 50px;*/
}

/*noinspection CssUnusedSymbol*/
.app-header,
.app-footer,
.sidebar,
.main,
.aside-menu {
    transition: margin-left 0.25s, margin-right 0.25s, width 0.25s, flex 0.25s;
}

.sidebar-nav {
    transition: width 0.25s;
}

/*noinspection CssUnusedSymbol*/
.breadcrumb {
    transition: left 0.25s, right 0.25s, width 0.25s;
}

/*noinspection CssFloatPxLength*/
@media (max-width: 991.98px) {
    .app-header {
        position: -webkit-sticky;
        position: sticky;
        z-index: 3;
        top: 0;
        padding-bottom: 4rem;
        /*position: fixed;*/
        /*z-index: 1020;*/
        width: 100%;
        text-align: center;
        background-color: var(--white);
    }

    /*noinspection CssUnusedSymbol*/
    .app-header .navbar-toggler {
        color: var(--white);
    }

    /*noinspection CssUnusedSymbol*/
    .app-header .navbar-brand {
        position: absolute;
        left: 50%;
        margin-left: -77.5px;
    }

    .app-body {
        /*margin-top: 55px;*/
    }

    .sidebar {
        position: fixed;
        z-index: 1019;
        width: 200px;
        height: calc(100vh - 55px);
    }

    /*noinspection CssUnusedSymbol*/
    .sidebar-minimizer {
        display: none;
    }

    /*noinspection CssUnusedSymbol*/
    .aside-menu {
        position: fixed;
        right: 0;
        height: 100%;
    }
}

hr.transparent {
    border-top: 1px solid transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bg-primary,
.bg-success,
.bg-info,
.bg-warning,
.bg-danger,
.bg-dark {
    color: var(--white);
}

/*noinspection CssUnusedSymbol*/
.bg-facebook {
    background-color: #3b5998 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-facebook:hover, a.bg-facebook:focus,
button.bg-facebook:hover,
button.bg-facebook:focus {
    background-color: #2d4373 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-twitter {
    background-color: #00aced !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-twitter:hover, a.bg-twitter:focus,
button.bg-twitter:hover,
button.bg-twitter:focus {
    background-color: #0087ba !important;
}

/*noinspection CssUnusedSymbol*/
.bg-linkedin {
    background-color: #4875b4 !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-linkedin:hover, a.bg-linkedin:focus,
button.bg-linkedin:hover,
button.bg-linkedin:focus {
    background-color: #395d90 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-google-plus {
    background-color: #d34836 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-google-plus:hover, a.bg-google-plus:focus,
button.bg-google-plus:hover,
button.bg-google-plus:focus {
    background-color: #b03626 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-flickr {
    background-color: #ff0084 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-flickr:hover, a.bg-flickr:focus,
button.bg-flickr:hover,
button.bg-flickr:focus {
    background-color: #cc006a !important;
}

/*noinspection CssUnusedSymbol*/
.bg-tumblr {
    background-color: #32506d !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-tumblr:hover, a.bg-tumblr:focus,
button.bg-tumblr:hover,
button.bg-tumblr:focus {
    background-color: #22364a !important;
}

/*noinspection CssUnusedSymbol*/
.bg-xing {
    background-color: #026466 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-xing:hover, a.bg-xing:focus,
button.bg-xing:hover,
button.bg-xing:focus {
    background-color: #013334 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-github {
    background-color: #4183c4 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-github:hover, a.bg-github:focus,
button.bg-github:hover,
button.bg-github:focus {
    background-color: #3269a0 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-html5 {
    background-color: #e34f26 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-html5:hover, a.bg-html5:focus,
button.bg-html5:hover,
button.bg-html5:focus {
    background-color: #be3c18 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-openid {
    background-color: #f78c40 !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-openid:hover, a.bg-openid:focus,
button.bg-openid:hover,
button.bg-openid:focus {
    background-color: #f56f0f !important;
}

/*noinspection CssUnusedSymbol*/
.bg-stack-overflow {
    background-color: #fe7a15 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-stack-overflow:hover, a.bg-stack-overflow:focus,
button.bg-stack-overflow:hover,
button.bg-stack-overflow:focus {
    background-color: #df6101 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-youtube {
    background-color: #b00 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-youtube:hover, a.bg-youtube:focus,
button.bg-youtube:hover,
button.bg-youtube:focus {
    background-color: #880000 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-css3 {
    background-color: #0170ba !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-css3:hover, a.bg-css3:focus,
button.bg-css3:hover,
button.bg-css3:focus {
    background-color: #015187 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-dribbble {
    background-color: #ea4c89 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-dribbble:hover, a.bg-dribbble:focus,
button.bg-dribbble:hover,
button.bg-dribbble:focus {
    background-color: #e51e6b !important;
}

/*noinspection CssUnusedSymbol*/
.bg-instagram {
    background-color: #517fa4 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-instagram:hover, a.bg-instagram:focus,
button.bg-instagram:hover,
button.bg-instagram:focus {
    background-color: #406582 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-pinterest {
    background-color: #cb2027 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-pinterest:hover, a.bg-pinterest:focus,
button.bg-pinterest:hover,
button.bg-pinterest:focus {
    background-color: #9f191f !important;
}

/*noinspection CssUnusedSymbol*/
.bg-vk {
    background-color: #45668e !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-vk:hover, a.bg-vk:focus,
button.bg-vk:hover,
button.bg-vk:focus {
    background-color: #344d6c !important;
}

/*noinspection CssUnusedSymbol*/
.bg-yahoo {
    background-color: #400191 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-yahoo:hover, a.bg-yahoo:focus,
button.bg-yahoo:hover,
button.bg-yahoo:focus {
    background-color: #2a015e !important;
}

/*noinspection CssUnusedSymbol*/
.bg-behance {
    background-color: #1769ff !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-behance:hover, a.bg-behance:focus,
button.bg-behance:hover,
button.bg-behance:focus {
    background-color: #0050e3 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-dropbox {
    background-color: #007ee5 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-dropbox:hover, a.bg-dropbox:focus,
button.bg-dropbox:hover,
button.bg-dropbox:focus {
    background-color: #0062b2 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-reddit {
    background-color: #ff4500 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-reddit:hover, a.bg-reddit:focus,
button.bg-reddit:hover,
button.bg-reddit:focus {
    background-color: #cc3700 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-spotify {
    background-color: #7ab800 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-spotify:hover, a.bg-spotify:focus,
button.bg-spotify:hover,
button.bg-spotify:focus {
    background-color: #588500 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-vine {
    background-color: #00bf8f !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-vine:hover, a.bg-vine:focus,
button.bg-vine:hover,
button.bg-vine:focus {
    background-color: #008c69 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-foursquare {
    background-color: #1073af !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-foursquare:hover, a.bg-foursquare:focus,
button.bg-foursquare:hover,
button.bg-foursquare:focus {
    background-color: #0c5480 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-vimeo {
    background-color: #aad450 !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-vimeo:hover, a.bg-vimeo:focus,
button.bg-vimeo:hover,
button.bg-vimeo:focus {
    background-color: #93c130 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-blue {
    background-color: var(--primary) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-blue:hover, a.bg-blue:focus,
button.bg-blue:hover,
button.bg-blue:focus {
    background-color: #1985ac !important;
}

/*noinspection CssUnusedSymbol*/
.bg-indigo {
    background-color: var(--indigo) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-indigo:hover, a.bg-indigo:focus,
button.bg-indigo:hover,
button.bg-indigo:focus {
    background-color: #510bc4 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-purple {
    background-color: var(--purple) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-purple:hover, a.bg-purple:focus,
button.bg-purple:hover,
button.bg-purple:focus {
    background-color: #59339d !important;
}

/*noinspection CssUnusedSymbol*/
.bg-pink {
    background-color: var(--pink) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-pink:hover, a.bg-pink:focus,
button.bg-pink:hover,
button.bg-pink:focus {
    background-color: #d91a72 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-red {
    background-color: var(--danger) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-red:hover, a.bg-red:focus,
button.bg-red:hover,
button.bg-red:focus {
    background-color: #f63c3a !important;
}

/*noinspection CssUnusedSymbol*/
.bg-orange {
    background-color: var(--orange) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-orange:hover, a.bg-orange:focus,
button.bg-orange:hover,
button.bg-orange:focus {
    background-color: #c5a100 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-yellow {
    background-color: var(--warning) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-yellow:hover, a.bg-yellow:focus,
button.bg-yellow:hover,
button.bg-yellow:focus {
    background-color: #d39e00 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-green {
    background-color: var(--success) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-green:hover, a.bg-green:focus,
button.bg-green:hover,
button.bg-green:focus {
    background-color: #3a9d5d !important;
}

/*noinspection CssUnusedSymbol*/
.bg-teal {
    background-color: var(--teal) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-teal:hover, a.bg-teal:focus,
button.bg-teal:hover,
button.bg-teal:focus {
    background-color: #199d76 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-cyan {
    background-color: var(--cyan) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-cyan:hover, a.bg-cyan:focus,
button.bg-cyan:hover,
button.bg-cyan:focus {
    background-color: #117a8b !important;
}

/*noinspection CssUnusedSymbol*/
.bg-white {
    background-color: var(--white) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray {
    background-color: var(--gray-600) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray:hover, a.bg-gray:focus,
button.bg-gray:hover,
button.bg-gray:focus {
    background-color: var(--gray-700) !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-dark {
    background-color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-dark:hover, a.bg-gray-dark:focus,
button.bg-gray-dark:hover,
button.bg-gray-dark:focus {
    background-color: #181b1e !important;
}

/*noinspection CssUnusedSymbol*/
.bg-light-blue {
    background-color: var(--info) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-light-blue:hover, a.bg-light-blue:focus,
button.bg-light-blue:hover,
button.bg-light-blue:focus {
    background-color: #39b2d5 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
    background-color: #d1dbe1 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-200 {
    background-color: var(--gray-200) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
    background-color: #c7ced4 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-300 {
    background-color: var(--secondary) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
    background-color: #acb5bc !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-400 {
    background-color: var(--gray-400) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
    background-color: #909ba5 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-500 {
    background-color: var(--gray-500) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
    background-color: #73828f !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-600 {
    background-color: var(--gray-600) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
    background-color: var(--gray-700) !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-700 {
    background-color: var(--gray-700) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
    background-color: #454e57 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-800 {
    background-color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
    background-color: #181b1e !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-900 {
    background-color: var(--gray-900) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
    background-color: #0c0e10 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-100:hover, a.bg-gray-100:focus,
button.bg-gray-100:hover,
button.bg-gray-100:focus {
    background-color: #d1dbe1 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-200 {
    background-color: var(--gray-200) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-200:hover, a.bg-gray-200:focus,
button.bg-gray-200:hover,
button.bg-gray-200:focus {
    background-color: #c7ced4 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-300 {
    background-color: var(--secondary) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-300:hover, a.bg-gray-300:focus,
button.bg-gray-300:hover,
button.bg-gray-300:focus {
    background-color: #acb5bc !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-400 {
    background-color: var(--gray-400) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-400:hover, a.bg-gray-400:focus,
button.bg-gray-400:hover,
button.bg-gray-400:focus {
    background-color: #909ba5 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-500 {
    background-color: var(--gray-500) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-500:hover, a.bg-gray-500:focus,
button.bg-gray-500:hover,
button.bg-gray-500:focus {
    background-color: #73828f !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-600 {
    background-color: var(--gray-600) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-600:hover, a.bg-gray-600:focus,
button.bg-gray-600:hover,
button.bg-gray-600:focus {
    background-color: var(--gray-700) !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-700 {
    background-color: var(--gray-700) !important;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
a.bg-gray-700:hover, a.bg-gray-700:focus,
button.bg-gray-700:hover,
button.bg-gray-700:focus {
    background-color: #454e57 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-800 {
    background-color: var(--gray-800) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-800:hover, a.bg-gray-800:focus,
button.bg-gray-800:hover,
button.bg-gray-800:focus {
    background-color: #181b1e !important;
}

/*noinspection CssUnusedSymbol*/
.bg-gray-900 {
    background-color: var(--gray-900) !important;
}

/*noinspection CssUnusedSymbol*/
a.bg-gray-900:hover, a.bg-gray-900:focus,
button.bg-gray-900:hover,
button.bg-gray-900:focus {
    background-color: #0c0e10 !important;
}

/*noinspection CssUnusedSymbol*/
.bg-box {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
}

.b-a-0 {
    border: 0 !important;
}

.b-t-0 {
    border-top: 0 !important;
}

.b-r-0 {
    border-right: 0 !important;
}

.b-b-0 {
    border-bottom: 0 !important;
}

.b-l-0 {
    border-left: 0 !important;
}

.b-a-1 {
    border: 1px solid var(--secondary);
}

.b-t-1 {
    border-top: 1px solid var(--secondary);
}

.b-r-1 {
    border-right: 1px solid var(--secondary);
}

.b-b-1 {
    border-bottom: 1px solid var(--secondary);
}

.b-l-1 {
    border-left: 1px solid var(--secondary);
}

.b-a-2 {
    border: 2px solid var(--secondary);
}

.b-t-2 {
    border-top: 2px solid var(--secondary);
}

.b-r-2 {
    border-right: 2px solid var(--secondary);
}

.b-b-2 {
    border-bottom: 2px solid var(--secondary);
}

.b-l-2 {
    border-left: 2px solid var(--secondary);
}

/*noinspection CssFloatPxLength*/
@media (max-width: 575.98px) {
    /*noinspection CssUnusedSymbol*/
    .d-down-none {
        display: none !important;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 767.98px) {
    /*noinspection CssUnusedSymbol*/
    .d-sm-down-none {
        display: none !important;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 991.98px) {
    .d-md-down-none {
        display: none !important;
    }
}

/*noinspection CssFloatPxLength*/
@media (max-width: 1199.98px) {
    /*noinspection CssUnusedSymbol*/
    .d-lg-down-none {
        display: none !important;
    }
}

/*noinspection CssUnusedSymbol*/
.d-xl-down-none {
    display: none !important;
}

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/*noinspection CssUnusedSymbol*/
.font-xs {
    font-size: .75rem !important;
}

.font-sm {
    font-size: .85rem !important;
}

.font-lg {
    font-size: 1rem !important;
}

/*noinspection CssUnusedSymbol*/
.font-xl {
    font-size: 1.25rem !important;
}

/*noinspection CssUnusedSymbol*/
.font-2xl {
    font-size: 1.5rem !important;
}

/*noinspection CssUnusedSymbol*/
.font-3xl {
    font-size: 1.75rem !important;
}

/*noinspection CssUnusedSymbol*/
.font-4xl {
    font-size: 2rem !important;
}

/*noinspection CssUnusedSymbol*/
.font-5xl {
    font-size: 2.5rem !important;
}

.text-value {
    font-size: 1.3125rem;
    font-weight: 600;
}

.text-value-sm {
    font-size: 1.09375rem;
    font-weight: 600;
}

.text-value-lg {
    font-size: 1.53125rem;
    font-weight: 600;
}

.text-white .text-muted {
    color: rgba(255, 255, 255, 0.6) !important;
}

*[dir="rtl"] {
    direction: rtl;
    unicode-bidi: embed;
}

*[dir="rtl"] body {
    text-align: right;
}

/*noinspection CssUnusedSymbol*/
*[dir="rtl"] .dropdown-item {
    text-align: right;
}

*[dir="rtl"] .dropdown-item i {
    margin-right: -10px;
    margin-left: 10px;
}

*[dir="rtl"] .dropdown-item .badge {
    right: auto;
    left: 10px;
}

/*noinspection CssUnusedSymbol*/
.app-header .navbar-nav .dropdown-menu-right {
    right: auto;
}

html body .app.flex-row.align-items-center {
    height: 100vh;
}

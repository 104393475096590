@import 'base.css';
@import "bootstrap.css";
:root {
    --blue: #00aaa6;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --orange: #f8cb00;
    --yellow: yellow;
    --green: #4dbd74;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #cccccc;
    --light-gray: #f0f0f0;
    --gray-dark: #2f353a;
    --gray-100: #f0f3f5;
    --gray-200: #e4e7ea;
    --gray-300: #c8ced3;
    --gray-400: #acb4bc;
    --gray-500: #8f9ba6;
    --gray-600: #73818f;
    --gray-700: #5c6873;
    --gray-800: #2f353a;
    --gray-900: #23282c;
    --success: #4dbd74;
    --info: #63c2de;
    --danger: #C40D0D;
    --warning: yellow;
    --light: #f0f3f5;
    --dark: #2f353a;
    --texts: #4d453f;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --red: #C40D0D;--light-red: #ff6666;
    --light-yellow: #ffff99;
    --light-green: #80ff80;
}

.tooltip {
    font-size: 11pt;
}

.user-edit-footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid var(--gray-200);
    background-color: transparent;
}

.unit-edit-footer {
    display: flex;
    flex-flow: row-reverse;
    padding: 1rem;
    border-top: 1px solid var(--gray-200);
    background-color: transparent;
    margin-top: .5rem
}

.dropdown-item:active i {
    color: white;
}

.dropdown-item:hover i {
    color: white;
}

.dropdown-item:hover {
    color: white;
    background-color: var(--texts);
}

.dropdown-item:active {
    background-color: var(--texts);
}

.user-edit-footer > :not(:last-child) {
    margin-right: .5rem;
}

.fa-user-plus {
    padding-left: .5rem;
    color: var(--texts);
}

.fa-users {
    padding-left: .5rem;
    color: var(--texts);
}

.dropdown-item {
    border-bottom: 1px solid var(--texts);
}

.dropdown-item:active {
    box-shadow: unset;
}

.dashboard-clr.focus {
    box-shadow: unset;
    color: var(--white);
    background-color: var(--primary);
    border: 1px solid var(--primary);
}

.history-icon {
    content: url("../fonts/material-icons/History icon.png");
    max-width: 13px;
}

.map-pin-icon {
    content: url("../fonts/material-icons/map-pin.svg");
    max-width: 1rem;
}

.add-icon {
    content: url("../fonts/material-icons/add-24px.svg");
    cursor: pointer;
}

.btn-group::-webkit-scrollbar {
    display: none;
}

.react-bs-table-bordered {
    overflow: scroll;
    max-width: 100%;
}

.react-bs-table-container {
    max-width: 100%;
}

.yard-editor {
    display: block;
}

.yard-editor-btns {
    padding-bottom: 4px;
}

.job-editor {
    display: block;
}

.job-editor-btns {
    padding-bottom: 4px;
}

.bot-status-note-editor {
    height: 100%;
}

.bot-status-note-editor-btn {
    display: inline-block;
    background-color: transparent;
    color: var(--texts);
    border: 0;
}

.bot-job-editor-btn {
    display: inline-block;
    background-color: transparent;
    color: var(--texts);
    border: 0;
}

.bot-yard-editor-lbl {
    display: inline-block;
    background-color: transparent;
    color: var(--texts);
    border: 0;
}

.dropdown-menu {
    border: 1px solid var(--texts);
}

.dropdown-menu.show {
    z-index: 10;
}

.pdf-download {
    color: var(--red);
}

.download-col {
    align-items: center;
    text-align: center;
}

.pdfViewer {
    width: 100%;
    height: 75vh;
}

.sticky-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;

    border: 2px dashed rgba(114, 186, 94, 0.35);
    height: 4000px;
    background: rgba(114, 186, 94, 0.05);
}

.sticky-header {
    position: sticky;
    top: 0;
}

.unit-report-row {
    border: 1px solid var(--primary);
    margin-bottom: .25rem;
}

.unit-report-column {
    border-left: 1px solid var(--primary);
    border-right: 1px solid var(--primary);
    border-bottom: 1px solid var(--primary);
    margin-bottom: 0;
}

.user-drpdn-header {
    background-color: var(--quintary);
}

.dot-logo {
    content: url("../img/dot1.svg");
}

.my-table-totals {
    color: black;
    font-size: 11pt;
    font-weight: bold;
}

.app-logo {
    width: 10rem;
    height: 1.75rem;
    padding-left: 1rem;
}

.home-icon {
    content: url("../fonts/material-icons/dashboard.svg");
    max-height: 15px;
}

.reports-icon {
    content: url("../fonts/material-icons/reports.svg");
    max-width: 15px;
    margin-right: 2px;
}

.sidebar-minimized .sidebar .nav-link .reports-icon {
    margin-left: 11px;
    padding-right: 5px;
}

.units-icon {
    content: url("../fonts/material-icons/Bulldozer.svg");
    max-height: 15px;
}

.tire-size-icon {
    content: url("../fonts/material-icons/tires-size.svg");
    max-height: 15px;
}

.asset-type-icon {
    content: url("../fonts/material-icons/asset_types.svg");
    max-height: 15px;
}

.fault-code-icon {
    content: url("../fonts/material-icons/fault-code.svg");
    max-height: 15px;
}

.workorder-icon {
    content: url('../fonts/material-icons/work-orders.svg');
    max-height: .75rem;
}

.vendors-icon {
    content: url("../fonts/material-icons/sharp-build-24px.svg");
    max-height: 15px;
}

.yards-icon {
    content: url("../fonts/material-icons/garage1.svg");
    max-height: 15px;
}

.users-icon {
    content: url("../fonts/material-icons/users.svg");
    max-height: 15px;
}

.notification-icon {
    content: url("../fonts/material-icons/sharp-flag-24px.svg");
    max-height: 30px;
}

.configuration-icon {
    content: url("../fonts/material-icons/baseline-settings-20px.svg");
    max-height: 15px;
}

.pm-icon {
    content: url("../fonts/material-icons/pms.svg");
    max-height: 15px;
}

.crews-icon {
    content: url('../fonts/material-icons/crews.svg');
    max-height: 15px;
}

.equipment-type-icon {
    content: url('../fonts/material-icons/equipment-types.svg');
    max-height: 15px;
}

.equipment-class-icon {
    content: url('../fonts/material-icons/unit_class_icon.svg');
    max-height: 15px;
}

.inspection-icon {
    content: url('../fonts/material-icons/clipboard-text.svg');
    max-height: 15px;
}

.plm-icon {
    content: url('../fonts/material-icons/plm-alert.svg');
    max-height: 15px;
}

.blueocean-xpi-icon {
    content: url('../fonts/material-icons/XPI icon.svg');
    max-height: 13px;
    max-width: 15px;
}

.bot-configuration-icon {
    content: url('../fonts/material-icons/toggle-configuration-switch.svg');
    max-height: 15px;
}

.user-integration-icon {
     content: url('../fonts/material-icons/user-integration.svg');
     max-height: 15px;
 }

.equipment-menu-icon {
    content: url("../fonts/material-icons/sharp-format_list_bulleted-24px.svg");
    max-height: 15px;
}

.inspection-exception-icon {
    content: url("../fonts/material-icons/Inspection-exception.svg");
    max-height: 15px;
}

.task-exception-icon {
    content: url("../fonts/material-icons/task-exception.png");
    max-height: 15px;
}

.maintenance-status-icon {
    content: url("../fonts/material-icons/maintenance-status.svg");
    max-height: 15px;
}

.workorder-list-icon {
    content: url("../fonts/material-icons/WorkOrderList.svg");
    max-height: 15px;
}

.tasks-icon {
    content: url("../fonts/material-icons/Tasks.svg");
    max-height: 15px;
}

.footer-logo {
    width: 20px;
}

.footer-logo img {
    width: 100%;
    height: auto;
}

.uil-warning-lbl {
    color: red;
    font-size: 14pt;
    text-align: center;
    padding-top: 1rem;
}

.sidebar-header {
    background: var(--white);
}

.sidebar .sidebar-header {
    flex: 0 0 auto;
    padding: 0.75rem 1rem;
    text-align: center;
}

.sidebar .nav-link {
    display: block;
    padding: 0.75rem 1rem;
    color: black;
    text-decoration: none;
    background: transparent;
    font-weight: bold;
}

.sidebar .nav-link .nav-icon {
    display: inline-block;
    width: 1.2rem;
    margin: 0 0.5rem 0 0;
    font-size: 0.875rem;
    color: black;
    vertical-align: bottom;
}

.fa-question-circle {
    color: #00aced;
    background-color: white;
}

.fa-edit {
    color: #00aced;
    background-color: white;
}

.bot-card-header.login-header {
    background-color: var(--primary);
    height: unset;
}

.sidebar {
    display: flex;
    flex-direction: column;
    padding: 0;
    color: var(--white);
    background: linear-gradient(180deg, var(--light), var(--gray-500))
}

.sidebar .nav-link.active {
    color: #fff;
    background: var(--primary);
}

.sidebar .nav-link.active .nav-icon {
    color: #fff;
}

.sidebar .nav-link:hover {
    color: #fff;
    background: var(--primary);
}

.sidebar .nav-link:hover .nav-icon {
    color: #fff;
}

.nav-link.disabled {
    color: var(--texts);
}

body {
    font-family: Helvetica, sans-serif;
}

.drop-up .Select-menu-outer {
    top: auto; bottom: 100%;
}

.modal-dialog {
    padding-top: 40px;
}

.btn-primary {
    background: var(--primary);
}

.btn-create {
    background: var(--primary);
}

.modal-header {
    background: var(--light);
}

.status {
    line-height: 52px;
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__input {
    width: 240px;
    height: 30px;
    padding: 10px 20px;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border: 1px solid #aaa;
    border-radius: 4px;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 280px;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.big-hr {
    border: solid 5px;
}

.page-divider {
    border: solid .1rem var(--thrintary);
}
.fixed-table-pagination li.active a {
    background: var(--primary);
    color: #0c0e10;
}

.my-edit-btn {
    color: var(--primary);
    background: var(--primary);
    border-color: var(--primary);
    border-width: 2px;
}

.user-edit-btn {
    color: var(--texts);
}

.app-header {
    flex-direction: row;
    height: 55px;
    padding: 0;
    margin: 0;
    background-color: var(--white);
}

.bot-table-scroll-row {
    display: inline;
    margin-bottom: .5rem;
    padding-right: .25rem;
    padding-left: .25rem;
}

.bot-table-scroller {
    cursor: pointer;
}

.active-filters-label {
    margin-left: .75rem !important;
    font-size: 12pt;
    font-weight: bold;
    text-decoration: underline;
}

.active-filters {
    margin-left: .25rem !important;
    margin-right: .75rem !important;
    padding: 5px;
    margin-bottom: 1rem;
}

.active-filter {
    margin-right: 5px;
    margin-bottom: 5px;
    padding: 5px;
    border-radius: 10px;
    background-color: var(--thrintary);
    font-weight: bold;
    cursor: pointer;
}

.active-filters .clear {
    background-color: var(--light-yellow);
    border: 1px solid var(--gray-400);
}

.active-filters .bot-table-header-clear-icon, .sort-null {
    cursor: pointer;
}

.bot-table-header i.pointer {
    cursor: pointer;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(77, 69, 63, 0.5);
}

.image-viewer {
    /*display: none;*/
    position: fixed;
    z-index: 1;
    padding-top: 100px;
    left: -2.5vw;
    top: 0;
    width: 95vw;
    height: 95vh;
    overflow: scroll;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.8);
}

.image-viewer-content {
    margin: auto;
    display: block;
    height: 50vh;
    max-width: 80vw;
    align-content: center;
    justify-content: center;
}

.image-viewer-image {
    margin: auto;
    display: block;
    height: 50vh;
    max-width: 80vw;
    align-content: center;
    justify-content: center;
    animation-name: zoom;
    animation-duration: 0.6s;
    object-fit: contain;
    cursor: zoom-in;
}

.image-viewer-image.panning {
    cursor: move;
}

.image-viewer-thumbnails {
    position: absolute;
    bottom: 2rem;
    height: 25vh;
    width: 90%;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    overflow: scroll;
}

.image-viewer-link {
    padding-left: 5px;
}

.image-viewer-link:hover {
    color: red;
}

.image-viewer-thumbnail {
    margin: auto;
    height: 100%;
    max-width: 10rem;
    object-fit: contain;
}

@keyframes zoom {
    from {transform:scale(0)}
    to {transform:scale(1)}
}

.image-viewer-info {
    position: absolute;
    text-align: center;
    color: white;
    font-size: 25px;
    font-weight: bold;
    width: 100% !important;
    padding: 25px 0;
    bottom: 30%;
    background-color: rgba(0,0,0,0.55);
}

.image-viewer-gallery {
    position: absolute;
    color: white;
    font-size: 25px;
    font-weight: bold;
    width: 100% !important;
    padding: 10px 0;
    bottom: 10px;
}

.image-viewer-info .row {
    justify-content: center;
}

.image-viewer-left {
    position: absolute;
    top: 45px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.5;
    left: 35px;
    width: 35px;
    height: 85vh;
    color: #f1f1f1;
    font-size: 100px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
    z-index: 2;
}

.image-viewer-left.disabled, .image-viewer-right.disabled {
    cursor: default;
}

.image-viewer-right {
    position: absolute;
    top: 45px;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    opacity: 0.5;
    right: 35px;
    width: 35px;
    height: 85vh;
    color: #f1f1f1;
    font-size: 100px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
    z-index: 2;
}

.image-viewer .close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    z-index: 3;
}

.image-viewer .close:hover,
.image-viewer .close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

.save-cancel {

}

.save-btn {
    color: white;
    background-color: rgba(0, 0, 255, 0.75);
    box-shadow: unset !important;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border: 0;
}

.save-btn.disabled {
    background-color: rgba(0, 0, 255, 0.5);
}

.save-btn:not(.disabled):hover {
    background-color: rgba(0, 0, 255, 1);
}

.cancel-btn {
    color: rgba(140, 140, 140, 0.80);
    border: 0;
    /*border: 1px solid rgba(140, 140, 140, 0.80);*/
    background-color: transparent;
    box-shadow: unset !important;
    font-weight: bold;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

.cancel-btn.disabled {
    background-color: transparent;
    color: rgba(140, 140, 140, 0.5);
    /*border: 1px solid rgba(140, 140, 140, 0.5);*/
}

.cancel-btn:not(.disabled):hover {
    color: black;
    background-color: rgba(140, 140, 140, 0.5);
}

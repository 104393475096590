@import '~react-virtualized-select/styles.css';
@import 'custom.css';

@font-face {
    font-family: 'Roboto', sans-serif;
}

:root {
    --quintary: #9ad2db;
    --pentary: #dcecf1;
    --primary: #069cc3;
    --secondary: #005a8b;
    --thrintary: #dcecf1;
}

.unit_status-1 {
    color: white;
    background: green;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.legacy-meter {
    color: var(--gray-500)
}

.unit_status-2 {
    color: gold;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-3 {
    color: yellow;
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-6 {
    color: white;
    background: var(--red);
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-5 {
    color: blue;
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-4 {
    color: blue;
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-7 {
    color: var(--info);
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-8 {
    color: var(--info);
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-9 {
    color: var(--info);
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.unit_status-10 {
    color: var(--info);
    background: black;
    font-size: 1rem;
    font-weight: bold;
    padding-left: .25rem;
}

.comp-logo {
    content: url("../img/test.svg");
}

.dot-logo {
    content: url("../img/dot1.svg");
}

.save-checklist-btn:disabled, .save-checklist-btn.disabled {
    color: var(--texts);
    border-color: var(--texts);
}

.cancel-checklist-btn:disabled, .cancel-checklist-btn.disabled {
    color: var(--texts);
    border-color: var(--texts);
}

.photo-only-indicator {
    color: var(--danger);
    padding-left: 0.5rem;
}

.bot-table-text-filter {
    color: #000000 !important;
}

.bot-table-text-filter.has-text {
    background-color: var(--thrintary);
    font-weight: bold;
}

bot-button {
    border-radius: 0;
}

bot-button:focus {
    outline: 5px auto -webkit-focus-ring-color;
}

input,
bot-button,
select,
optgroup,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

bot-button,
input {
    overflow: visible;
}

bot-button,
select {
    text-transform: none;
}

bot-button,
html [type="bot-button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

bot-button::-moz-focus-inner,
[type="bot-button"]::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type="bot-button"].bot-btn-block {
    width: 100%;
}

bot-button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
}

bot-button.bg-primary:hover,
bot-button.bg-primary:focus {
    background-color: #1985ac !important;
}

bot-button.bg-secondary:hover,
bot-button.bg-secondary:focus {
    background-color: #acb5bc !important;
}

bot-button.bg-success:hover,
bot-button.bg-success:focus {
    background-color: #3a9d5d !important;
}

bot-button.bg-info:hover,
bot-button.bg-info:focus {
    background-color: #39b2d5 !important;
}

bot-button.bg-warning:hover,
bot-button.bg-warning:focus {
    background-color: #d39e00 !important;
}

bot-button.bg-danger:hover,
bot-button.bg-danger:focus {
    background-color: #f63c3a !important;
}

bot-button.bg-light:hover,
bot-button.bg-light:focus {
    background-color: #d1dbe1 !important;
}

bot-button.bg-dark:hover,
bot-button.bg-dark:focus {
    background-color: #181b1e !important;
}

bot-button {
    cursor: pointer;
}






.bg-blue {
    background-color: var(--primary) !important;
}

a.bg-blue:hover, a.bg-blue:focus,
bot-button.bg-blue:hover,
bot-button.bg-blue:focus {
    background-color: #1985ac !important;
}

.bg-indigo {
    background-color: var(--indigo) !important;
}

a.bg-indigo:hover, a.bg-indigo:focus,
bot-button.bg-indigo:hover,
bot-button.bg-indigo:focus {
    background-color: #510bc4 !important;
}

.bg-purple {
    background-color: var(--purple) !important;
}

a.bg-purple:hover, a.bg-purple:focus,
bot-button.bg-purple:hover,
bot-button.bg-purple:focus {
    background-color: #59339d !important;
}

.bg-pink {
    background-color: var(--pink) !important;
}

a.bg-pink:hover, a.bg-pink:focus,
bot-button.bg-pink:hover,
bot-button.bg-pink:focus {
    background-color: #d91a72 !important;
}

.bg-red {
    background-color: var(--danger) !important;
}

a.bg-red:hover, a.bg-red:focus,
bot-button.bg-red:hover,
bot-button.bg-red:focus {
    background-color: #f63c3a !important;
}

.bg-orange {
    background-color: var(--orange) !important;
}

a.bg-orange:hover, a.bg-orange:focus,
bot-button.bg-orange:hover,
bot-button.bg-orange:focus {
    background-color: #c5a100 !important;
}

.bg-yellow {
    background-color: var(--warning) !important;
}

a.bg-yellow:hover, a.bg-yellow:focus,
bot-button.bg-yellow:hover,
bot-button.bg-yellow:focus {
    background-color: #d39e00 !important;
}

.bg-green {
    background-color: var(--success) !important;
}

a.bg-green:hover, a.bg-green:focus,
bot-button.bg-green:hover,
bot-button.bg-green:focus {
    background-color: #3a9d5d !important;
}

.bg-teal {
    background-color: var(--teal) !important;
}

a.bg-teal:hover, a.bg-teal:focus,
bot-button.bg-teal:hover,
bot-button.bg-teal:focus {
    background-color: #199d76 !important;
}

.bg-cyan {
    background-color: var(--cyan) !important;
}

a.bg-cyan:hover, a.bg-cyan:focus,
bot-button.bg-cyan:hover,
bot-button.bg-cyan:focus {
    background-color: #117a8b !important;
}

.bg-white {
    background-color: var(--white) !important;
}

a.bg-white:hover, a.bg-white:focus,
bot-button.bg-white:hover,
bot-button.bg-white:focus {
    background-color: #e6e6e6 !important;
}

.bg-gray {
    background-color: var(--gray-600) !important;
}

a.bg-gray:hover, a.bg-gray:focus,
bot-button.bg-gray:hover,
bot-button.bg-gray:focus {
    background-color: var(--gray-700) !important;
}

.bg-gray-dark {
    background-color: var(--gray-800) !important;
}

a.bg-gray-dark:hover, a.bg-gray-dark:focus,
bot-button.bg-gray-dark:hover,
bot-button.bg-gray-dark:focus {
    background-color: #181b1e !important;
}

.bg-light-blue {
    background-color: var(--info) !important;
}

a.bg-light-blue:hover, a.bg-light-blue:focus,
bot-button.bg-light-blue:hover,
bot-button.bg-light-blue:focus {
    background-color: #39b2d5 !important;
}

.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
bot-button.bg-gray-100:hover,
bot-button.bg-gray-100:focus {
    background-color: #d1dbe1 !important;
}

.bg-gray-200 {
    background-color: var(--gray-200) !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
bot-button.bg-gray-200:hover,
bot-button.bg-gray-200:focus {
    background-color: #c7ced4 !important;
}

.bg-gray-300 {
    background-color: var(--secondary) !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
bot-button.bg-gray-300:hover,
bot-button.bg-gray-300:focus {
    background-color: #acb5bc !important;
}

.bg-gray-400 {
    background-color: var(--gray-400) !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
bot-button.bg-gray-400:hover,
bot-button.bg-gray-400:focus {
    background-color: #909ba5 !important;
}

.bg-gray-500 {
    background-color: var(--gray-500) !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
bot-button.bg-gray-500:hover,
bot-button.bg-gray-500:focus {
    background-color: #73828f !important;
}

.bg-gray-600 {
    background-color: var(--gray-600) !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
bot-button.bg-gray-600:hover,
bot-button.bg-gray-600:focus {
    background-color: var(--gray-700) !important;
}

.bg-gray-700 {
    background-color: var(--gray-700) !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
bot-button.bg-gray-700:hover,
bot-button.bg-gray-700:focus {
    background-color: #454e57 !important;
}

.bg-gray-800 {
    background-color: var(--gray-800) !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
bot-button.bg-gray-800:hover,
bot-button.bg-gray-800:focus {
    background-color: #181b1e !important;
}

.bg-gray-900 {
    background-color: var(--gray-900) !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
bot-button.bg-gray-900:hover,
bot-button.bg-gray-900:focus {
    background-color: #0c0e10 !important;
}

.bg-gray-100 {
    background-color: var(--gray-100) !important;
}

a.bg-gray-100:hover, a.bg-gray-100:focus,
bot-button.bg-gray-100:hover,
bot-button.bg-gray-100:focus {
    background-color: #d1dbe1 !important;
}

.bg-gray-200 {
    background-color: var(--gray-200) !important;
}

a.bg-gray-200:hover, a.bg-gray-200:focus,
bot-button.bg-gray-200:hover,
bot-button.bg-gray-200:focus {
    background-color: #c7ced4 !important;
}

.bg-gray-300 {
    background-color: var(--secondary) !important;
}

a.bg-gray-300:hover, a.bg-gray-300:focus,
bot-button.bg-gray-300:hover,
bot-button.bg-gray-300:focus {
    background-color: #acb5bc !important;
}

.bg-gray-400 {
    background-color: var(--gray-400) !important;
}

a.bg-gray-400:hover, a.bg-gray-400:focus,
bot-button.bg-gray-400:hover,
bot-button.bg-gray-400:focus {
    background-color: #909ba5 !important;
}

.bg-gray-500 {
    background-color: var(--gray-500) !important;
}

a.bg-gray-500:hover, a.bg-gray-500:focus,
bot-button.bg-gray-500:hover,
bot-button.bg-gray-500:focus {
    background-color: #73828f !important;
}

.bg-gray-600 {
    background-color: var(--gray-600) !important;
}

a.bg-gray-600:hover, a.bg-gray-600:focus,
bot-button.bg-gray-600:hover,
bot-button.bg-gray-600:focus {
    background-color: var(--gray-700) !important;
}

.bg-gray-700 {
    background-color: var(--gray-700) !important;
}

a.bg-gray-700:hover, a.bg-gray-700:focus,
bot-button.bg-gray-700:hover,
bot-button.bg-gray-700:focus {
    background-color: #454e57 !important;
}

.bg-gray-800 {
    background-color: var(--gray-800) !important;
}

a.bg-gray-800:hover, a.bg-gray-800:focus,
bot-button.bg-gray-800:hover,
bot-button.bg-gray-800:focus {
    background-color: #181b1e !important;
}

.bg-gray-900 {
    background-color: var(--gray-900) !important;
}

a.bg-gray-900:hover, a.bg-gray-900:focus,
bot-button.bg-gray-900:hover,
bot-button.bg-gray-900:focus {
    background-color: #0c0e10 !important;
}

.bot-btn-primary {
    background: var(--primary);
}

.bot-btn {
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*noinspection CssInvalidMediaFeature*/
@media screen and (prefers-reduced-motion: reduce) {
    .bot-btn {
        transition: none;
    }
}

.bot-btn:hover, .bot-btn:focus {
    text-decoration: none;
}

.bot-btn:focus, .bot-btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.25);
}

.bot-btn.disabled, .bot-btn:disabled {
    opacity: 0.65;
}

.bot-btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

.bot-btn:not(:disabled):not(.disabled):active, .bot-btn:not(:disabled):not(.disabled).active {
    background-image: none;
}

a.bot-btn.disabled,
fieldset:disabled a.bot-btn {
    pointer-events: none;
}

.bot-btn-primary {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
    overflow: hidden;
}

.bot-btn-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

.bot-btn-primary:focus, .bot-btn-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

.bot-btn-primary.disabled, .bot-btn-primary:disabled {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-primary:not(:disabled):not(.disabled):active, .bot-btn-primary:not(:disabled):not(.disabled).active,
.show > .bot-btn-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-primary:not(:disabled):not(.disabled):active:focus, .bot-btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-secondary {
    color: var(--primary);
    background-color: var(--white);
    border-color: var(--primary);
    font-size: .75rem;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-secondary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-secondary:focus, .bot-btn-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-secondary.disabled, .bot-btn-secondary:disabled {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-secondary:not(:disabled):not(.disabled):active, .bot-btn-secondary:not(:disabled):not(.disabled).active,
.show > .bot-btn-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: #acb5bc;
    border-color: #a5aeb7;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-secondary:not(:disabled):not(.disabled):active:focus, .bot-btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-success {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-success:hover {
    color: var(--white);
    background-color: #3ea662;
    border-color: #3a9d5d;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-success:focus, .bot-btn-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-success.disabled, .bot-btn-success:disabled {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-success:not(:disabled):not(.disabled):active, .bot-btn-success:not(:disabled):not(.disabled).active,
.show > .bot-btn-success.dropdown-toggle {
    color: var(--white);
    background-color: #3a9d5d;
    border-color: #379457;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-success:not(:disabled):not(.disabled):active:focus, .bot-btn-success:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-info {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
    overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-info:hover {
    color: var(--gray-900);
    background-color: #43b6d7;
    border-color: #39b2d5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-info:focus, .bot-btn-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-info.disabled, .bot-btn-info:disabled {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-info:not(:disabled):not(.disabled):active, .bot-btn-info:not(:disabled):not(.disabled).active,
.show > .bot-btn-info.dropdown-toggle {
    color: var(--white);
    background-color: #39b2d5;
    border-color: #2eadd3;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-info:not(:disabled):not(.disabled):active:focus, .bot-btn-info:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-warning {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-warning:hover {
    color: var(--gray-900);
    background-color: #e0a800;
    border-color: #d39e00;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-warning:focus, .bot-btn-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-warning.disabled, .bot-btn-warning:disabled {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-warning:not(:disabled):not(.disabled):active, .bot-btn-warning:not(:disabled):not(.disabled).active,
.show > .bot-btn-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: #d39e00;
    border-color: #c69500;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-warning:not(:disabled):not(.disabled):active:focus, .bot-btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-danger {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-danger:hover {
    color: var(--white);
    background-color: #f64846;
    border-color: #f63c3a;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-danger:focus, .bot-btn-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-danger.disabled, .bot-btn-danger:disabled {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-danger:not(:disabled):not(.disabled):active, .bot-btn-danger:not(:disabled):not(.disabled).active,
.show > .bot-btn-danger.dropdown-toggle {
    color: var(--white);
    background-color: #f63c3a;
    border-color: #f5302e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-danger:not(:disabled):not(.disabled):active:focus, .bot-btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-light {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-light:hover {
    color: var(--gray-900);
    background-color: #d9e1e6;
    border-color: #d1dbe1;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-light:focus, .bot-btn-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-light.disabled, .bot-btn-light:disabled {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-light:not(:disabled):not(.disabled):active, .bot-btn-light:not(:disabled):not(.disabled).active,
.show > .bot-btn-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: #d1dbe1;
    border-color: #cad4dc;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-light:not(:disabled):not(.disabled):active:focus, .bot-btn-light:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dark {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dark:hover {
    color: var(--white);
    background-color: #1e2225;
    border-color: #181b1e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dark:focus, .bot-btn-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dark.disabled, .bot-btn-dark:disabled {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dark:not(:disabled):not(.disabled):active, .bot-btn-dark:not(:disabled):not(.disabled).active,
.show > .bot-btn-dark.dropdown-toggle {
    color: var(--white);
    background-color: #181b1e;
    border-color: #121517;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dark:not(:disabled):not(.disabled):active:focus, .bot-btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-primary {
    color: var(--primary);
    background-color: transparent;
    background-image: none;
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-primary:focus, .bot-btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-primary.disabled, .bot-btn-outline-primary:disabled {
    color: var(--primary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-primary:not(:disabled):not(.disabled):active, .bot-btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-primary:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-secondary {
    color: var(--secondary);
    background-color: transparent;
    background-image: none;
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-secondary:hover {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-secondary:focus, .bot-btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-secondary.disabled, .bot-btn-outline-secondary:disabled {
    color: var(--secondary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-secondary:not(:disabled):not(.disabled):active, .bot-btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-success {
    color: var(--success);
    background-color: transparent;
    background-image: none;
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-success:hover {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-success:focus, .bot-btn-outline-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-success.disabled, .bot-btn-outline-success:disabled {
    color: var(--success);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-success:not(:disabled):not(.disabled):active, .bot-btn-outline-success:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-success.dropdown-toggle {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-success:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-info {
    color: var(--info);
    background-color: transparent;
    background-image: none;
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-info:hover {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-info:focus, .bot-btn-outline-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-info.disabled, .bot-btn-outline-info:disabled {
    color: var(--info);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-info:not(:disabled):not(.disabled):active, .bot-btn-outline-info:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-info.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-info:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-warning {
    color: var(--warning);
    background-color: transparent;
    background-image: none;
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-warning:hover {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-warning:focus, .bot-btn-outline-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-warning.disabled, .bot-btn-outline-warning:disabled {
    color: var(--warning);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-warning:not(:disabled):not(.disabled):active, .bot-btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-warning:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-danger {
    color: var(--danger);
    background-color: transparent;
    background-image: none;
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-danger:hover {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-danger:focus, .bot-btn-outline-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-danger.disabled, .bot-btn-outline-danger:disabled {
    color: var(--danger);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-danger:not(:disabled):not(.disabled):active, .bot-btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-danger.dropdown-toggle {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-danger:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-light {
    color: var(--gray-100);
    background-color: transparent;
    background-image: none;
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-light:hover {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-light:focus, .bot-btn-outline-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-light.disabled, .bot-btn-outline-light:disabled {
    color: var(--gray-100);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-light:not(:disabled):not(.disabled):active, .bot-btn-outline-light:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-light:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-dark {
    color: var(--gray-800);
    background-color: transparent;
    background-image: none;
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-dark:hover {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-dark:focus, .bot-btn-outline-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-outline-dark.disabled, .bot-btn-outline-dark:disabled {
    color: var(--gray-800);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-dark:not(:disabled):not(.disabled):active, .bot-btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .bot-btn-outline-dark.dropdown-toggle {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-outline-dark:not(:disabled):not(.disabled):active:focus, .bot-btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-link {
    font-weight: 400;
    color: var(--primary);
    background-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-link:hover {
    color: #167495;
    text-decoration: underline;
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-link:focus, .bot-btn-link.focus {
    text-decoration: underline;
    border-color: transparent;
    box-shadow: none;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-link:disabled, .bot-btn-link.disabled {
    color: var(--gray-600);
    pointer-events: none;
}

.bot-btn-lg, .bot-btn-group-lg > .bot-btn {
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
}

.bot-btn-sm, .bot-btn-group-sm > .bot-btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.76563rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-block {
    display: block;
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-block + .bot-btn-block {
    margin-top: 0.5rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
input[type="submit"].bot-btn-block,
input[type="reset"].bot-btn-block,
input[type="bot-button"].bot-btn-block {
    width: 100%;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-group,
.bot-btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
    overflow-y: scroll;
    max-width: 100%;
    height: 2.5rem;
    box-sizing: content-box;
    margin-bottom: 10px;
}

.bot-btn-group > .bot-btn,
.bot-btn-group-vertical > .bot-btn {
    position: relative;
    flex: 0 1 auto;
}

.bot-btn-group > .bot-btn:hover,
.bot-btn-group-vertical > .bot-btn:hover {
    z-index: 1;
}

.bot-btn-group > .bot-btn:focus, .bot-btn-group > .bot-btn:active, .bot-btn-group > .bot-btn.active,
.bot-btn-group-vertical > .bot-btn:focus,
.bot-btn-group-vertical > .bot-btn:active,
.bot-btn-group-vertical > .bot-btn.active {
    z-index: 1;
}

.bot-btn-group .bot-btn + .bot-btn,
.bot-btn-group .bot-btn + .bot-btn-group,
.bot-btn-group .bot-btn-group + .bot-btn,
.bot-btn-group .bot-btn-group + .bot-btn-group,
.bot-btn-group-vertical .bot-btn + .bot-btn,
.bot-btn-group-vertical .bot-btn + .bot-btn-group,
.bot-btn-group-vertical .bot-btn-group + .bot-btn,
.bot-btn-group-vertical .bot-btn-group + .bot-btn-group {
    margin-left: -1px;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.bot-btn-toolbar .input-group {
    width: auto;
}

.bot-btn-group > .bot-btn:first-child {
    margin-left: 0;
}

.bot-btn-group > .bot-btn:not(:last-child):not(.dropdown-toggle),
.bot-btn-group > .bot-btn-group:not(:last-child) > .bot-btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.bot-btn-group > .bot-btn:not(:first-child),
.bot-btn-group > .bot-btn-group:not(:first-child) > .bot-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-sm + .dropdown-toggle-split, .bot-btn-group-sm > .bot-btn + .dropdown-toggle-split {
    padding-right: 0.375rem;
    padding-left: 0.375rem;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-lg + .dropdown-toggle-split, .bot-btn-group-lg > .bot-btn + .dropdown-toggle-split {
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.bot-btn-group-vertical .bot-btn,
.bot-btn-group-vertical .bot-btn-group {
    width: 100%;
}

.bot-btn-group-vertical > .bot-btn + .bot-btn,
.bot-btn-group-vertical > .bot-btn + .bot-btn-group,
.bot-btn-group-vertical > .bot-btn-group + .bot-btn,
.bot-btn-group-vertical > .bot-btn-group + .bot-btn-group {
    margin-top: -1px;
    margin-left: 0;
}

.bot-btn-group-vertical > .bot-btn:not(:last-child):not(.dropdown-toggle),
.bot-btn-group-vertical > .bot-btn-group:not(:last-child) > .bot-btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.bot-btn-group-vertical > .bot-btn:not(:first-child),
.bot-btn-group-vertical > .bot-btn-group:not(:first-child) > .bot-btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.bot-btn-group-toggle > .bot-btn,
.bot-btn-group-toggle > .bot-btn-group > .bot-btn {
    margin-bottom: 0;
}

.bot-btn-group-toggle > .bot-btn input[type="radio"],
.bot-btn-group-toggle > .bot-btn input[type="checkbox"],
.bot-btn-group-toggle > .bot-btn-group > .bot-btn input[type="radio"],
.bot-btn-group-toggle > .bot-btn-group > .bot-btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
}

.input-group-prepend .bot-btn,
.input-group-append .bot-btn {
    position: relative;
    z-index: 2;
}

.input-group-prepend .bot-btn + .bot-btn,
.input-group-prepend .bot-btn + .input-group-text,
.input-group-prepend .input-group-text + .input-group-text,
.input-group-prepend .input-group-text + .bot-btn,
.input-group-append .bot-btn + .bot-btn,
.input-group-append .bot-btn + .input-group-text,
.input-group-append .input-group-text + .input-group-text,
.input-group-append .input-group-text + .bot-btn {
    margin-left: -1px;
}

.input-group > .input-group-prepend > .bot-btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .bot-btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .bot-btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group > .input-group-append > .bot-btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .bot-btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .bot-btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.bot-btn .badge {
    position: relative;
    top: -1px;
}

.breadcrumb-menu .bot-btn-group {
    vertical-align: top;
}

.breadcrumb-menu .bot-btn {
    padding: 0 0.75rem;
    color: var(--texts);
    vertical-align: top;
    border: 0;
}

.breadcrumb-menu .bot-btn:hover, .breadcrumb-menu .bot-btn.active {
    color: var(--gray-900);
    background: transparent;
}

.breadcrumb-menu .open .bot-btn {
    color: var(--gray-900);
    background: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-brand {
    border: 0;
}

.bot-btn-brand i {
    display: inline-block;
    width: 2.0625rem;
    margin: -0.375rem -0.75rem;
    line-height: 2.0625rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.25rem;
}

.bot-btn-brand i + span {
    margin-left: 1.5rem;
}

.bot-btn-brand.bot-btn-lg i, .bot-btn-group-lg > .bot-btn-brand.bot-btn i {
    width: 2.64063rem;
    margin: -0.5rem -1rem;
    line-height: 2.64063rem;
    border-radius: 0.3rem;
}

.bot-btn-brand.bot-btn-lg i + span, .bot-btn-group-lg > .bot-btn-brand.bot-btn i + span {
    margin-left: 2rem;
}

.bot-btn-brand.bot-btn-sm i, .bot-btn-group-sm > .bot-btn-brand.bot-btn i {
    width: 1.64844rem;
    margin: -0.25rem -0.5rem;
    line-height: 1.64844rem;
    border-radius: 0.2rem;
}

.bot-btn-brand.bot-btn-sm i + span, .bot-btn-group-sm > .bot-btn-brand.bot-btn i + span {
    margin-left: 1rem;
}

.bot-btn-brand.bot-btn-square i {
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-facebook {
    color: var(--white);
    background-color: #3b5998;
    border-color: #3b5998;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-facebook:hover {
    color: var(--white);
    background-color: #30497c;
    border-color: #2d4373;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-facebook:focus, .bot-btn-facebook.focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-facebook.disabled, .bot-btn-facebook:disabled {
    color: var(--white);
    background-color: #3b5998;
    border-color: #3b5998;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-facebook:not(:disabled):not(.disabled):active, .bot-btn-facebook:not(:disabled):not(.disabled).active,
.show > .bot-btn-facebook.dropdown-toggle {
    color: var(--white);
    background-color: #2d4373;
    border-color: #293e6a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-facebook:not(:disabled):not(.disabled):active:focus, .bot-btn-facebook:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(59, 89, 152, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-twitter {
    color: var(--white);
    background-color: #00aced;
    border-color: #00aced;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-twitter:hover {
    color: var(--white);
    background-color: #0090c7;
    border-color: #0087ba;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-twitter:focus, .bot-btn-twitter.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-twitter.disabled, .bot-btn-twitter:disabled {
    color: var(--white);
    background-color: #00aced;
    border-color: #00aced;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-twitter:not(:disabled):not(.disabled):active, .bot-btn-twitter:not(:disabled):not(.disabled).active,
.show > .bot-btn-twitter.dropdown-toggle {
    color: var(--white);
    background-color: #0087ba;
    border-color: #007ead;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-twitter:not(:disabled):not(.disabled):active:focus, .bot-btn-twitter:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 172, 237, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-linkedin {
    color: var(--white);
    background-color: #4875b4;
    border-color: #4875b4;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-linkedin:hover {
    color: var(--white);
    background-color: #3d6399;
    border-color: #395d90;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-linkedin:focus, .bot-btn-linkedin.focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 117, 180, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-linkedin.disabled, .bot-btn-linkedin:disabled {
    color: var(--white);
    background-color: #4875b4;
    border-color: #4875b4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-linkedin:not(:disabled):not(.disabled):active, .bot-btn-linkedin:not(:disabled):not(.disabled).active,
.show > .bot-btn-linkedin.dropdown-toggle {
    color: var(--white);
    background-color: #395d90;
    border-color: #365786;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-linkedin:not(:disabled):not(.disabled):active:focus, .bot-btn-linkedin:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-linkedin.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 117, 180, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-google-plus {
    color: var(--white);
    background-color: #d34836;
    border-color: #d34836;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-google-plus:hover {
    color: var(--white);
    background-color: #ba3929;
    border-color: #b03626;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-google-plus:focus, .bot-btn-google-plus.focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 72, 54, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-google-plus.disabled, .bot-btn-google-plus:disabled {
    color: var(--white);
    background-color: #d34836;
    border-color: #d34836;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-google-plus:not(:disabled):not(.disabled):active, .bot-btn-google-plus:not(:disabled):not(.disabled).active,
.show > .bot-btn-google-plus.dropdown-toggle {
    color: var(--white);
    background-color: #b03626;
    border-color: #a53324;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-google-plus:not(:disabled):not(.disabled):active:focus, .bot-btn-google-plus:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-google-plus.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(211, 72, 54, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-flickr {
    color: var(--white);
    background-color: #ff0084;
    border-color: #ff0084;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-flickr:hover {
    color: var(--white);
    background-color: #d90070;
    border-color: #cc006a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-flickr:focus, .bot-btn-flickr.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 132, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-flickr.disabled, .bot-btn-flickr:disabled {
    color: var(--white);
    background-color: #ff0084;
    border-color: #ff0084;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-flickr:not(:disabled):not(.disabled):active, .bot-btn-flickr:not(:disabled):not(.disabled).active,
.show > .bot-btn-flickr.dropdown-toggle {
    color: var(--white);
    background-color: #cc006a;
    border-color: #bf0063;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-flickr:not(:disabled):not(.disabled):active:focus, .bot-btn-flickr:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-flickr.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 0, 132, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-tumblr {
    color: var(--white);
    background-color: #32506d;
    border-color: #32506d;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-tumblr:hover {
    color: var(--white);
    background-color: #263d53;
    border-color: #22364a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-tumblr:focus, .bot-btn-tumblr.focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 80, 109, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-tumblr.disabled, .bot-btn-tumblr:disabled {
    color: var(--white);
    background-color: #32506d;
    border-color: #32506d;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-tumblr:not(:disabled):not(.disabled):active, .bot-btn-tumblr:not(:disabled):not(.disabled).active,
.show > .bot-btn-tumblr.dropdown-toggle {
    color: var(--white);
    background-color: #22364a;
    border-color: #1e3041;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-tumblr:not(:disabled):not(.disabled):active:focus, .bot-btn-tumblr:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-tumblr.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(50, 80, 109, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-xing {
    color: var(--white);
    background-color: #026466;
    border-color: #026466;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-xing:hover {
    color: var(--white);
    background-color: #013f40;
    border-color: #013334;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-xing:focus, .bot-btn-xing.focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 100, 102, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-xing.disabled, .bot-btn-xing:disabled {
    color: var(--white);
    background-color: #026466;
    border-color: #026466;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-xing:not(:disabled):not(.disabled):active, .bot-btn-xing:not(:disabled):not(.disabled).active,
.show > .bot-btn-xing.dropdown-toggle {
    color: var(--white);
    background-color: #013334;
    border-color: #012727;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-xing:not(:disabled):not(.disabled):active:focus, .bot-btn-xing:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-xing.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(2, 100, 102, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-github {
    color: var(--white);
    background-color: #4183c4;
    border-color: #4183c4;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-github:hover {
    color: var(--white);
    background-color: #3570aa;
    border-color: #3269a0;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-github:focus, .bot-btn-github.focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 131, 196, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-github.disabled, .bot-btn-github:disabled {
    color: var(--white);
    background-color: #4183c4;
    border-color: #4183c4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-github:not(:disabled):not(.disabled):active, .bot-btn-github:not(:disabled):not(.disabled).active,
.show > .bot-btn-github.dropdown-toggle {
    color: var(--white);
    background-color: #3269a0;
    border-color: #2f6397;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-github:not(:disabled):not(.disabled):active:focus, .bot-btn-github:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(65, 131, 196, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-html5 {
    color: var(--white);
    background-color: #e34f26;
    border-color: #e34f26;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-html5:hover {
    color: var(--white);
    background-color: #c9401a;
    border-color: #be3c18;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-html5:focus, .bot-btn-html5.focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 79, 38, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-html5.disabled, .bot-btn-html5:disabled {
    color: var(--white);
    background-color: #e34f26;
    border-color: #e34f26;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-html5:not(:disabled):not(.disabled):active, .bot-btn-html5:not(:disabled):not(.disabled).active,
.show > .bot-btn-html5.dropdown-toggle {
    color: var(--white);
    background-color: #be3c18;
    border-color: #b23917;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-html5:not(:disabled):not(.disabled):active:focus, .bot-btn-html5:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-html5.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(227, 79, 38, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-openid {
    color: var(--gray-900);
    background-color: #f78c40;
    border-color: #f78c40;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-openid:hover {
    color: var(--white);
    background-color: #f5761b;
    border-color: #f56f0f;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-openid:focus, .bot-btn-openid.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 140, 64, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-openid.disabled, .bot-btn-openid:disabled {
    color: var(--gray-900);
    background-color: #f78c40;
    border-color: #f78c40;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-openid:not(:disabled):not(.disabled):active, .bot-btn-openid:not(:disabled):not(.disabled).active,
.show > .bot-btn-openid.dropdown-toggle {
    color: var(--white);
    background-color: #f56f0f;
    border-color: #ed680a;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-openid:not(:disabled):not(.disabled):active:focus, .bot-btn-openid:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-openid.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 140, 64, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-stack-overflow {
    color: var(--white);
    background-color: #fe7a15;
    border-color: #fe7a15;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-stack-overflow:hover {
    color: var(--white);
    background-color: #ec6701;
    border-color: #df6101;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-stack-overflow:focus, .bot-btn-stack-overflow.focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-stack-overflow.disabled, .bot-btn-stack-overflow:disabled {
    color: var(--white);
    background-color: #fe7a15;
    border-color: #fe7a15;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-stack-overflow:not(:disabled):not(.disabled):active, .bot-btn-stack-overflow:not(:disabled):not(.disabled).active,
.show > .bot-btn-stack-overflow.dropdown-toggle {
    color: var(--white);
    background-color: #df6101;
    border-color: #d25c01;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-stack-overflow:not(:disabled):not(.disabled):active:focus, .bot-btn-stack-overflow:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-stack-overflow.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(254, 122, 21, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-youtube {
    color: var(--white);
    background-color: #b00;
    border-color: #b00;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-youtube:hover {
    color: var(--white);
    background-color: #950000;
    border-color: #880000;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-youtube:focus, .bot-btn-youtube.focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 0, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-youtube.disabled, .bot-btn-youtube:disabled {
    color: var(--white);
    background-color: #b00;
    border-color: #b00;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-youtube:not(:disabled):not(.disabled):active, .bot-btn-youtube:not(:disabled):not(.disabled).active,
.show > .bot-btn-youtube.dropdown-toggle {
    color: var(--white);
    background-color: #880000;
    border-color: #7b0000;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-youtube:not(:disabled):not(.disabled):active:focus, .bot-btn-youtube:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-youtube.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(187, 0, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-css3 {
    color: var(--white);
    background-color: #0170ba;
    border-color: #0170ba;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-css3:hover {
    color: var(--white);
    background-color: #015994;
    border-color: #015187;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-css3:focus, .bot-btn-css3.focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 112, 186, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-css3.disabled, .bot-btn-css3:disabled {
    color: var(--white);
    background-color: #0170ba;
    border-color: #0170ba;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-css3:not(:disabled):not(.disabled):active, .bot-btn-css3:not(:disabled):not(.disabled).active,
.show > .bot-btn-css3.dropdown-toggle {
    color: var(--white);
    background-color: #015187;
    border-color: #014a7b;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-css3:not(:disabled):not(.disabled):active:focus, .bot-btn-css3:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-css3.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(1, 112, 186, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dribbble {
    color: var(--white);
    background-color: #ea4c89;
    border-color: #ea4c89;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dribbble:hover {
    color: var(--white);
    background-color: #e62a72;
    border-color: #e51e6b;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dribbble:focus, .bot-btn-dribbble.focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dribbble.disabled, .bot-btn-dribbble:disabled {
    color: var(--white);
    background-color: #ea4c89;
    border-color: #ea4c89;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dribbble:not(:disabled):not(.disabled):active, .bot-btn-dribbble:not(:disabled):not(.disabled).active,
.show > .bot-btn-dribbble.dropdown-toggle {
    color: var(--white);
    background-color: #e51e6b;
    border-color: #dc1a65;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dribbble:not(:disabled):not(.disabled):active:focus, .bot-btn-dribbble:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-dribbble.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 76, 137, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-instagram {
    color: var(--white);
    background-color: #517fa4;
    border-color: #517fa4;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-instagram:hover {
    color: var(--white);
    background-color: #446b8a;
    border-color: #406582;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-instagram:focus, .bot-btn-instagram.focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 127, 164, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-instagram.disabled, .bot-btn-instagram:disabled {
    color: var(--white);
    background-color: #517fa4;
    border-color: #517fa4;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-instagram:not(:disabled):not(.disabled):active, .bot-btn-instagram:not(:disabled):not(.disabled).active,
.show > .bot-btn-instagram.dropdown-toggle {
    color: var(--white);
    background-color: #406582;
    border-color: #3c5e79;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-instagram:not(:disabled):not(.disabled):active:focus, .bot-btn-instagram:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(81, 127, 164, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-pinterest {
    color: var(--white);
    background-color: #cb2027;
    border-color: #cb2027;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-pinterest:hover {
    color: var(--white);
    background-color: #aa1b21;
    border-color: #9f191f;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-pinterest:focus, .bot-btn-pinterest.focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 32, 39, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-pinterest.disabled, .bot-btn-pinterest:disabled {
    color: var(--white);
    background-color: #cb2027;
    border-color: #cb2027;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-pinterest:not(:disabled):not(.disabled):active, .bot-btn-pinterest:not(:disabled):not(.disabled).active,
.show > .bot-btn-pinterest.dropdown-toggle {
    color: var(--white);
    background-color: #9f191f;
    border-color: #94171c;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-pinterest:not(:disabled):not(.disabled):active:focus, .bot-btn-pinterest:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-pinterest.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(203, 32, 39, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vk {
    color: var(--white);
    background-color: #45668e;
    border-color: #45668e;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vk:hover {
    color: var(--white);
    background-color: #385474;
    border-color: #344d6c;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vk:focus, .bot-btn-vk.focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 102, 142, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vk.disabled, .bot-btn-vk:disabled {
    color: var(--white);
    background-color: #45668e;
    border-color: #45668e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vk:not(:disabled):not(.disabled):active, .bot-btn-vk:not(:disabled):not(.disabled).active,
.show > .bot-btn-vk.dropdown-toggle {
    color: var(--white);
    background-color: #344d6c;
    border-color: #304763;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vk:not(:disabled):not(.disabled):active:focus, .bot-btn-vk:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-vk.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(69, 102, 142, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-yahoo {
    color: var(--white);
    background-color: #400191;
    border-color: #400191;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-yahoo:hover {
    color: var(--white);
    background-color: #2f016b;
    border-color: #2a015e;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-yahoo:focus, .bot-btn-yahoo.focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 1, 145, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-yahoo.disabled, .bot-btn-yahoo:disabled {
    color: var(--white);
    background-color: #400191;
    border-color: #400191;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-yahoo:not(:disabled):not(.disabled):active, .bot-btn-yahoo:not(:disabled):not(.disabled).active,
.show > .bot-btn-yahoo.dropdown-toggle {
    color: var(--white);
    background-color: #2a015e;
    border-color: #240152;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-yahoo:not(:disabled):not(.disabled):active:focus, .bot-btn-yahoo:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-yahoo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(64, 1, 145, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-behance {
    color: var(--white);
    background-color: #1769ff;
    border-color: #1769ff;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-behance:hover {
    color: var(--white);
    background-color: #0055f0;
    border-color: #0050e3;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-behance:focus, .bot-btn-behance.focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-behance.disabled, .bot-btn-behance:disabled {
    color: var(--white);
    background-color: #1769ff;
    border-color: #1769ff;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-behance:not(:disabled):not(.disabled):active, .bot-btn-behance:not(:disabled):not(.disabled).active,
.show > .bot-btn-behance.dropdown-toggle {
    color: var(--white);
    background-color: #0050e3;
    border-color: #004cd6;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-behance:not(:disabled):not(.disabled):active:focus, .bot-btn-behance:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-behance.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 105, 255, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dropbox {
    color: var(--white);
    background-color: #007ee5;
    border-color: #007ee5;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dropbox:hover {
    color: var(--white);
    background-color: #0069bf;
    border-color: #0062b2;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dropbox:focus, .bot-btn-dropbox.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 229, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dropbox.disabled, .bot-btn-dropbox:disabled {
    color: var(--white);
    background-color: #007ee5;
    border-color: #007ee5;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-dropbox:not(:disabled):not(.disabled):active, .bot-btn-dropbox:not(:disabled):not(.disabled).active,
.show > .bot-btn-dropbox.dropdown-toggle {
    color: var(--white);
    background-color: #0062b2;
    border-color: #005ba5;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-dropbox:not(:disabled):not(.disabled):active:focus, .bot-btn-dropbox:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-dropbox.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 126, 229, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-reddit {
    color: var(--white);
    background-color: #ff4500;
    border-color: #ff4500;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-reddit:hover {
    color: var(--white);
    background-color: #d93b00;
    border-color: #cc3700;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-reddit:focus, .bot-btn-reddit.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-reddit.disabled, .bot-btn-reddit:disabled {
    color: var(--white);
    background-color: #ff4500;
    border-color: #ff4500;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-reddit:not(:disabled):not(.disabled):active, .bot-btn-reddit:not(:disabled):not(.disabled).active,
.show > .bot-btn-reddit.dropdown-toggle {
    color: var(--white);
    background-color: #cc3700;
    border-color: #bf3400;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-reddit:not(:disabled):not(.disabled):active:focus, .bot-btn-reddit:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-reddit.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 69, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify {
    color: var(--white);
    background-color: #7ab800;
    border-color: #7ab800;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify:hover {
    color: var(--white);
    background-color: #619200;
    border-color: #588500;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify:focus, .bot-btn-spotify.focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 184, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify.disabled, .bot-btn-spotify:disabled {
    color: var(--white);
    background-color: #7ab800;
    border-color: #7ab800;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify:not(:disabled):not(.disabled):active, .bot-btn-spotify:not(:disabled):not(.disabled).active,
.show > .bot-btn-spotify.dropdown-toggle {
    color: var(--white);
    background-color: #588500;
    border-color: #507800;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-spotify:not(:disabled):not(.disabled):active:focus, .bot-btn-spotify:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-spotify.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(122, 184, 0, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vine {
    color: var(--white);
    background-color: #00bf8f;
    border-color: #00bf8f;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vine:hover {
    color: var(--white);
    background-color: #009972;
    border-color: #008c69;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vine:focus, .bot-btn-vine.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 191, 143, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vine.disabled, .bot-btn-vine:disabled {
    color: var(--white);
    background-color: #00bf8f;
    border-color: #00bf8f;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vine:not(:disabled):not(.disabled):active, .bot-btn-vine:not(:disabled):not(.disabled).active,
.show > .bot-btn-vine.dropdown-toggle {
    color: var(--white);
    background-color: #008c69;
    border-color: #007f5f;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vine:not(:disabled):not(.disabled):active:focus, .bot-btn-vine:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-vine.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 191, 143, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-foursquare {
    color: var(--white);
    background-color: #1073af;
    border-color: #1073af;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-foursquare:hover {
    color: var(--white);
    background-color: #0d5c8c;
    border-color: #0c5480;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-foursquare:focus, .bot-btn-foursquare.focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 115, 175, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-foursquare.disabled, .bot-btn-foursquare:disabled {
    color: var(--white);
    background-color: #1073af;
    border-color: #1073af;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-foursquare:not(:disabled):not(.disabled):active, .bot-btn-foursquare:not(:disabled):not(.disabled).active,
.show > .bot-btn-foursquare.dropdown-toggle {
    color: var(--white);
    background-color: #0c5480;
    border-color: #0b4d75;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-foursquare:not(:disabled):not(.disabled):active:focus, .bot-btn-foursquare:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-foursquare.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(16, 115, 175, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vimeo {
    color: var(--gray-900);
    background-color: #aad450;
    border-color: #aad450;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-vimeo:hover {
    color: var(--gray-900);
    background-color: #9bcc32;
    border-color: #93c130;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vimeo:focus, .bot-btn-vimeo.focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 212, 80, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vimeo.disabled, .bot-btn-vimeo:disabled {
    color: var(--gray-900);
    background-color: #aad450;
    border-color: #aad450;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vimeo:not(:disabled):not(.disabled):active, .bot-btn-vimeo:not(:disabled):not(.disabled).active,
.show > .bot-btn-vimeo.dropdown-toggle {
    color: var(--gray-900);
    background-color: #93c130;
    border-color: #8bb72d;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-vimeo:not(:disabled):not(.disabled):active:focus, .bot-btn-vimeo:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-vimeo.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(170, 212, 80, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-transparent {
    color: var(--white);
    background-color: transparent;
    border-color: transparent;
}

.bot-btn [class^="icon-"],
.bot-btn [class*=" icon-"] {
    display: inline-block;
    margin-top: -2px;
    vertical-align: middle;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-pill {
    border-radius: 50em;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-square {
    border-radius: 0;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-primary {
    color: var(--primary);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-primary:hover {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-primary:focus, .bot-btn-ghost-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-primary.disabled, .bot-btn-ghost-primary:disabled {
    color: var(--primary);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-primary:not(:disabled):not(.disabled):active, .bot-btn-ghost-primary:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-primary.dropdown-toggle {
    color: var(--white);
    background-color: var(--primary);
    border-color: var(--primary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-primary:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-primary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(32, 168, 216, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-secondary {
    color: var(--secondary);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-secondary:hover {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-secondary:focus, .bot-btn-ghost-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-secondary.disabled, .bot-btn-ghost-secondary:disabled {
    color: var(--secondary);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-secondary:not(:disabled):not(.disabled):active, .bot-btn-ghost-secondary:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-secondary.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--secondary);
    border-color: var(--secondary);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-secondary:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-secondary:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(200, 206, 211, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-success {
    color: var(--success);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-success:hover {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-success:focus, .bot-btn-ghost-success.focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-success.disabled, .bot-btn-ghost-success:disabled {
    color: var(--success);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-success:not(:disabled):not(.disabled):active, .bot-btn-ghost-success:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-success.dropdown-toggle {
    color: var(--white);
    background-color: var(--success);
    border-color: var(--success);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-success:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-success:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(77, 189, 116, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-info {
    color: var(--info);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-info:hover {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-info:focus, .bot-btn-ghost-info.focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-info.disabled, .bot-btn-ghost-info:disabled {
    color: var(--info);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-info:not(:disabled):not(.disabled):active, .bot-btn-ghost-info:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-info.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--info);
    border-color: var(--info);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-info:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-info:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(99, 194, 222, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-warning {
    color: var(--warning);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-warning:hover {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-warning:focus, .bot-btn-ghost-warning.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-warning.disabled, .bot-btn-ghost-warning:disabled {
    color: var(--warning);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-warning:not(:disabled):not(.disabled):active, .bot-btn-ghost-warning:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-warning.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--warning);
    border-color: var(--warning);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-warning:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-warning:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 193, 7, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-danger {
    color: var(--danger);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-danger:hover {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-danger:focus, .bot-btn-ghost-danger.focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-danger.disabled, .bot-btn-ghost-danger:disabled {
    color: var(--danger);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-danger:not(:disabled):not(.disabled):active, .bot-btn-ghost-danger:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-danger.dropdown-toggle {
    color: var(--white);
    background-color: var(--danger);
    border-color: var(--danger);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-danger:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-danger:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(248, 108, 107, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-light {
    color: var(--gray-100);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-light:hover {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-light:focus, .bot-btn-ghost-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-light.disabled, .bot-btn-ghost-light:disabled {
    color: var(--gray-100);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-light:not(:disabled):not(.disabled):active, .bot-btn-ghost-light:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-light.dropdown-toggle {
    color: var(--gray-900);
    background-color: var(--gray-100);
    border-color: var(--gray-100);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-light:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-light:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(240, 243, 245, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-dark {
    color: var(--gray-800);
    background-color: transparent;
    background-image: none;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-dark:hover {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-dark:focus, .bot-btn-ghost-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-dark.disabled, .bot-btn-ghost-dark:disabled {
    color: var(--gray-800);
    background-color: transparent;
    border-color: transparent;
}

/*noinspection CssUnusedSymbol,CssUnusedSymbol,CssUnusedSymbol*/
.bot-btn-ghost-dark:not(:disabled):not(.disabled):active, .bot-btn-ghost-dark:not(:disabled):not(.disabled).active,
.show > .bot-btn-ghost-dark.dropdown-toggle {
    color: var(--white);
    background-color: var(--gray-800);
    border-color: var(--gray-800);
}

/*noinspection CssUnusedSymbol*/
.bot-btn-ghost-dark:not(:disabled):not(.disabled):active:focus, .bot-btn-ghost-dark:not(:disabled):not(.disabled).active:focus,
.show > .bot-btn-ghost-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(47, 53, 58, 0.5);
}

.dropdown-header .bot-btn {
    margin-top: -7px;
    color: var(--gray-600);
}

.dropdown-header .bot-btn:hover {
    color: var(--gray-900);
}

/*noinspection CssUnusedSymbol*/
.dropdown-header .bot-btn.pull-right {
    margin-right: -20px;
}
